import PropTypes from "prop-types";
import React from "react";
import NumberField from "../layout/Form/NumberField";

type Props = {
    value;
    onChange;
    name;
};
class MinMaxCloseYear extends React.Component<Props> {
    static propTypes = {
        value: PropTypes.any,
        name: PropTypes.string,
        onChange: PropTypes.func
    };
    constructor(props) {
        super(props);
    }

    onChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        let value = this.props.value
            ? this.props.value.length > 0
                ? this.props.value
                : [0, 0]
            : [0, 0];

        if (fieldName === "min") {
            value[0] = fieldValue !== "" ? parseInt(fieldValue, 10) : 0;
        }

        if (fieldName === "max") {
            value[1] = fieldValue !== "" ? parseInt(fieldValue, 10) : 0;
        }

        if (value[0] === 0 && value[1] === 0) {
            value = [];
        }

        this.props.onChange({
            target: {
                name: this.props.name,
                type: "range",
                value
            }
        });
    };

    render() {
        let { value } = this.props;

        return (
            <div className={"p-3"}>
                <strong>
                    Enter a minimum and/or maximum close year (inclusive):
                </strong>
                <div className="row">
                    <div className="col">
                        <NumberField
                            fieldName={"min"}
                            value={value[0] ? value[0] : ""}
                            onChange={this.onChange}
                            placeHolder={"Minimum close year (e.g. 2009)"}
                        />
                    </div>
                    <div className="col">
                        <NumberField
                            fieldName={"max"}
                            value={value[1] ? value[1] : ""}
                            onChange={this.onChange}
                            placeHolder={"Maximum close year (e.g. 2010)"}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default MinMaxCloseYear;
