/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import delve from "dlv";

/**
 * Validation Service
 */
export default class ValidationService {
    static validEmailRegex = new RegExp(/\S+@\S+\.\S+/);
    static nonNumericRegex = new RegExp("[0-9]+");
    static validURLRegex = new RegExp(
        /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i
    );
    static publicDomainRegex = new RegExp(
        /\S+@(gmail|hotmail|outlook|yahoo|inbox|icloud|Mail|aol|mx3.zoho|yandex|protonmail)+\.\S+/i
    );
    isValid: boolean;
    validations: {};
    data: {};
    rules: any;

    /**
     * Validation Service Constructor.
     * @param rules
     */
    constructor(rules) {
        this.isValid = true;
        this.validations = {};
        this.data = {};
        this.rules = rules;
    }

    /**
     * Returns a structured object for field validation.
     * @param validations
     * @param field
     * @returns {*}
     */
    static getFieldValidation(validations, field) {
        if (validations && typeof validations[field] !== "undefined") {
            return validations[field];
        }

        return {
            isValid: null,
            message: null
        };
    }

    /**
     * Allows a message to be a function with validation data passed to it.
     * @param message
     * @param validationData
     * @param checkValue
     * @param formData
     * @returns {*}
     */
    static returnMessage(message, validationData, checkValue, formData) {
        let vData = { ...validationData };

        if (vData.message) {
            delete vData.message;
        }

        return typeof message === "function"
            ? message(validationData, checkValue, formData)
            : message;
    }

    /**
     * Updates our form data.
     * @param data
     */
    setData(data) {
        this.data = data;
    }

    /**
     * Validates all fields.
     * @param data
     */
    validateFields(data) {
        this.setData(data);

        let hasErrors = false;
        let validated = false;

        for (const fieldName in data) {
            if (Object.prototype.hasOwnProperty.call(data, fieldName)) {
                validated = this.performValidation(fieldName, data[fieldName]);
                if (validated !== null) {
                    this.validations[fieldName] = {
                        message: validated,
                        isValid: !(validated !== false)
                    };

                    if (validated !== false && hasErrors === false) {
                        hasErrors = true;
                    }
                } else {
                    this.validations[fieldName] = {
                        message: null,
                        isValid: null
                    };
                }
            }
        }

        this.isValid = !hasErrors;
    }

    /**
     * Validates a given field and it's value against our rules.
     * @param checkField
     * @param checkValue
     */
    validateField(checkField, checkValue) {
        const validated = this.performValidation(checkField, checkValue);
        if (validated !== null) {
            this.isValid = !(validated !== false);
            this.validations[checkField] = {
                message: validated,
                isValid: this.isValid
            };
        } else {
            this.validations[checkField] = {
                message: null,
                isValid: null
            };
        }
    }

    /**
     * Checks a value against a rule and runs through the rule's checks...
     * @param checkField
     * @param checkValue
     * @returns {boolean|null}
     */
    performValidation(checkField, checkValue) {
        const fieldValidations = delve(this.rules, checkField);
        if (typeof fieldValidations === "undefined") {
            return false;
        }

        for (const validationType in fieldValidations) {
            if (
                Object.prototype.hasOwnProperty.call(
                    fieldValidations,
                    validationType
                )
            ) {
                const validationData = fieldValidations[validationType];

                // Enable validation type?
                if (typeof validationData.enabled === "function") {
                    const enabled = validationData.enabled(this.data);
                    if (!enabled) {
                        return null;
                    }
                }

                switch (validationType) {
                    case "hasWords": {
                        const maxWords =
                            typeof validationData.maxWords !== "undefined"
                                ? validationData.maxWords
                                : false;
                        const minWords =
                            typeof validationData.minWords !== "undefined"
                                ? validationData.minWords
                                : false;

                        let modifiedCheckValue = checkValue;
                        if (
                            typeof checkValue === "object" &&
                            checkValue !== null
                        ) {
                            modifiedCheckValue = Object.keys(checkValue).join(
                                " "
                            );
                        } else if (checkValue === null) {
                            modifiedCheckValue = "";
                        }

                        const wordCount = this.getWordsCount(
                            modifiedCheckValue
                        );

                        if (
                            maxWords &&
                            minWords &&
                            (wordCount < minWords || wordCount > maxWords)
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                modifiedCheckValue,
                                this.data
                            );
                        } else if (
                            maxWords &&
                            !minWords &&
                            wordCount > maxWords
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                modifiedCheckValue,
                                this.data
                            );
                        } else if (
                            minWords &&
                            !maxWords &&
                            wordCount < minWords
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                modifiedCheckValue,
                                this.data
                            );
                        }

                        break;
                    }

                    case "hasLength": {
                        const maxLength =
                            typeof validationData.maxLength !== "undefined"
                                ? validationData.maxLength
                                : false;
                        const minLength =
                            typeof validationData.minLength !== "undefined"
                                ? validationData.minLength
                                : false;

                        let modifiedCheckValue = checkValue;
                        if (
                            typeof checkValue === "object" &&
                            checkValue !== null
                        ) {
                            modifiedCheckValue = Object.keys(checkValue);
                        } else if (checkValue === null) {
                            modifiedCheckValue = "";
                        }

                        if (
                            maxLength &&
                            minLength &&
                            (modifiedCheckValue.length < minLength ||
                                modifiedCheckValue.length > maxLength)
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                modifiedCheckValue,
                                this.data
                            );
                        } else if (
                            maxLength &&
                            !minLength &&
                            modifiedCheckValue.length > maxLength
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                modifiedCheckValue,
                                this.data
                            );
                        } else if (
                            minLength &&
                            !maxLength &&
                            modifiedCheckValue.length < minLength
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                modifiedCheckValue,
                                this.data
                            );
                        }

                        break;
                    }

                    case "hasNumber": {
                        const maxValue =
                            typeof validationData.maxValue !== "undefined"
                                ? parseInt(validationData.maxValue)
                                : false;
                        const minValue =
                            typeof validationData.minValue !== "undefined"
                                ? parseInt(validationData.minValue)
                                : false;

                        let modifiedCheckValue = parseInt(checkValue);

                        if (
                            maxValue !== false &&
                            minValue !== false &&
                            (modifiedCheckValue < minValue ||
                                modifiedCheckValue > maxValue)
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                modifiedCheckValue,
                                this.data
                            );
                        } else if (
                            maxValue !== false &&
                            minValue === false &&
                            modifiedCheckValue > maxValue
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                modifiedCheckValue,
                                this.data
                            );
                        } else if (
                            minValue !== false &&
                            maxValue === false &&
                            modifiedCheckValue < minValue
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                modifiedCheckValue,
                                this.data
                            );
                        }

                        break;
                    }

                    case "equals": {
                        const equalsFieldValue =
                            typeof this.data[validationData.field] !==
                            "undefined"
                                ? this.data[validationData.field]
                                : false;
                        if (equalsFieldValue !== checkValue) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                checkValue,
                                this.data
                            );
                        }
                        break;
                    }

                    case "isFalse": {
                        if (checkValue !== false) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                checkValue,
                                this.data
                            );
                        }
                        break;
                    }

                    case "isTrue": {
                        if (checkValue !== true) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                checkValue,
                                this.data
                            );
                        }
                        break;
                    }

                    case "isURL": {
                        if (
                            checkValue !== null &&
                            checkValue.length !== 0 &&
                            !ValidationService.validURLRegex.test(checkValue)
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                checkValue,
                                this.data
                            );
                        }
                        break;
                    }

                    case "isEmail": {
                        if (
                            checkValue !== null &&
                            checkValue.length !== 0 &&
                            !ValidationService.validEmailRegex.test(checkValue)
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                checkValue,
                                this.data
                            );
                        }
                        break;
                    }

                    case "notPersonalEmail": {
                        if (
                            checkValue !== null &&
                            checkValue.length !== 0 &&
                            ValidationService.publicDomainRegex.test(checkValue)
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                checkValue,
                                this.data
                            );
                        }
                        break;
                    }

                    case "isNumber": {
                        if (
                            !ValidationService.nonNumericRegex.test(checkValue)
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                checkValue,
                                this.data
                            );
                        }
                        break;
                    }

                    case "notEmpty": {
                        let modifiedCheckValue = checkValue;
                        if (
                            typeof checkValue === "object" &&
                            checkValue !== null
                        ) {
                            modifiedCheckValue = Object.keys(checkValue);
                        } else if (
                            checkValue === null ||
                            typeof checkValue === "undefined"
                        ) {
                            modifiedCheckValue = "";
                        }

                        if (
                            modifiedCheckValue === null ||
                            modifiedCheckValue.length === 0
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                checkValue,
                                this.data
                            );
                        }
                        break;
                    }

                    case "nonNumeric": {
                        if (
                            ValidationService.nonNumericRegex.test(checkValue)
                        ) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                checkValue,
                                this.data
                            );
                        }
                        break;
                    }

                    case "custom": {
                        if (!validationData.isValid(checkValue, this.data)) {
                            return ValidationService.returnMessage(
                                validationData.message,
                                validationData,
                                checkValue,
                                this.data
                            );
                        }
                        break;
                    }
                }
            }
        }

        return false;
    }

    getWordsCount(paragraph) {
        return paragraph.split(" ").length;
    }

    /**
     * Get's all of our validation results.
     * @returns {{}}
     */
    getValidations() {
        return this.validations;
    }
}
