import React from "react";
import Authenticated from "../Authenticated";
// TS TODO This withApollo is meant for Pages. ChatSupport is not a Page
import { withApollo } from "../../utils/WithApollo";
import CrispChat from "./CrispChat";

const ChatSupport = () => (
    <Authenticated passNullUser>
        {(user) => {
            let chatUser = {};

            if (user) {
                chatUser = {
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email_address,
                    userId: user.id,
                    hash: user.intercom_hash
                };
            }

            return <CrispChat {...chatUser} />;
        }}
    </Authenticated>
);

export default withApollo(ChatSupport);
