/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import { ModalHeader as ModalHeaderBootstrap } from "reactstrap";

const ModalCardHeader = ({ title, onDismiss }) => (
    <ModalHeaderBootstrap toggle={onDismiss}>{title}</ModalHeaderBootstrap>
);

ModalCardHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onDismiss: PropTypes.func
};

export default ModalCardHeader;
