import React from "react";
import { User } from "../../types/mainTypes";
import { forceUserProfileUpdate } from "../../utils/CommonUtils";
import ForceUserUpdateModal from "./ForceUserUpdateModal";

type Props = {
    user: User;
};
class ForceUserProfileUpdate extends React.Component<Props> {
    render() {
        const user = this.props.user;
        if (forceUserProfileUpdate(user)) {
            return <ForceUserUpdateModal user={user} />;
        }

        return null;
    }
}

export default ForceUserProfileUpdate;
