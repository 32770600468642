/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import faStop from "@fortawesome/fontawesome-free-solid/faStopCircle";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import Card from "../../layout/Card/Card";
import CardContent from "../../layout/Card/CardContent";

const InstitutionReactivated = () => (
    <React.Fragment>
        <Card>
            <CardContent>
                <h2 className="card-title">
                    <span className="text-danger">
                        <FontAwesomeIcon icon={faStop as IconProp} />
                    </span>{" "}
                    Platform Access Notification
                </h2>
                <p>
                    Your institution has been marked to be re-activated. Please
                    reach out to{" "}
                    <a href="mailto:support@convergence.finance">
                        support@convergence.finance
                    </a>{" "}
                    to discuss pricing options with our team.
                </p>
            </CardContent>
        </Card>
    </React.Fragment>
);

InstitutionReactivated.propTypes = {
    institution: PropTypes.object.isRequired
};

export default InstitutionReactivated;
