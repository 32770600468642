/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";

const ModalCard = ({ children }) => (
    <div className="modal-card">{children}</div>
);

ModalCard.propTypes = {
    children: PropTypes.any.isRequired
};

export default ModalCard;
