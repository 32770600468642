import React from "react";
import { WithApolloClient, withApollo } from "react-apollo";
import { FormGroup } from "reactstrap";
import { USER_PURPOSE_FOR_USING_CONVERGENCE } from "../../../constants";
import {
    COMPLETE_PREFERENCES_MUTATION,
    UPDATE_USER_MUTATION
} from "../../../graphql/mutation/UserMutation";
import redirect from "../../../utils/Redirect";
import LocationAutoCompleteField from "../../general/LocationAutoCompleteField";
import Box from "../../layout/Box";
import CheckBoxField from "../../layout/Form/CheckBoxField";
import Form from "../../layout/Form/Form";
import SubmitButton from "../../layout/Form/SubmitButton";
import TextField from "../../layout/Form/TextField";
import MessageBox from "../../layout/MessageBox";
import Section from "../../layout/Section";

type Props = {
    user;
    updateUserPreferences;
    renderPreferences;
};

type State = {
    user;
    error: string[];
};
class UpdateUserPreferencesForm extends React.Component<
    WithApolloClient<Props>,
    State
> {
    constructor(props) {
        super(props);
        this.state = {
            error: [],
            user: {
                location: this.props.user.location
                    ? this.props.user.location
                    : "",
                title: this.props.user.title ? this.props.user.title : "",
                purpose_for_using_convergence_v2: this.props.user
                    .purpose_for_using_convergence_v2
                    ? this.props.user.purpose_for_using_convergence_v2
                    : []
            }
        };
    }

    onChange = (event) => {
        const fieldChecked = event.target.checked;
        const fieldName = event.target.name;
        let fieldValue = event.target.value;

        if (fieldName === "purpose_for_using_convergence_v2") {
            let purposeForUsingConvergenceV2 = Array.isArray(
                this.state.user.purpose_for_using_convergence_v2
            )
                ? this.state.user.purpose_for_using_convergence_v2
                : [];

            let currentFieldValue = new Map();
            purposeForUsingConvergenceV2.forEach((value) => {
                currentFieldValue.set(value, value);
            });

            if (fieldChecked) {
                currentFieldValue.set(fieldValue, fieldValue);
            } else {
                currentFieldValue.delete(fieldValue);
            }

            fieldValue = [];
            currentFieldValue.forEach((val) => {
                if (val) {
                    fieldValue.push(val);
                }
            });
        } else {
            event.preventDefault();
        }

        this.setState((prevState) => {
            let user = prevState.user;

            user[fieldName] = fieldValue;

            return {
                user
            };
        });
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.props
            .updateUserPreferences()
            .then(({ data: { updateSelfPreferences } }) => {
                const user = updateSelfPreferences;
                const { user: userState } = this.state;

                let error = [];

                if (userState.location === "" || userState.location === null) {
                    error.push(
                        "You must enter a valid city (selected from the drop down choices)."
                    );
                }

                if (userState.title === "" || userState.title === null) {
                    error.push("You must enter your job title.");
                }

                if (
                    userState.purpose_for_using_convergence_v2 === "" ||
                    userState.purpose_for_using_convergence_v2.length <= 0 ||
                    userState.purpose_for_using_convergence_v2 === null
                ) {
                    error.push(
                        "You must select your purpose for using Convergence."
                    );
                }

                if (
                    userState.purpose_for_using_convergence_v2 !== "" &&
                    userState.purpose_for_using_convergence_v2.length > 2
                ) {
                    error.push(
                        "You can only select a maximum 2 choices for your purpose for using Convergence."
                    );
                }

                if (
                    !user.preferences.deal_types ||
                    (user.preferences.deal_types &&
                        user.preferences.deal_types.length <= 0)
                ) {
                    error.push("You must select your deal type preference.");
                }

                if (
                    !user.preferences.regions_v2 ||
                    (user.preferences.regions_v2 &&
                        user.preferences.regions_v2.length <= 0)
                ) {
                    error.push("You must select your region preference.");
                }

                if (
                    !user.preferences.sectors ||
                    (user.preferences.sectors &&
                        user.preferences.sectors.length <= 0)
                ) {
                    error.push("You must select your sector preference.");
                }

                if (
                    !user.preferences.investment_instruments ||
                    (user.preferences.investment_instruments &&
                        user.preferences.investment_instruments.length <= 0)
                ) {
                    error.push(
                        "You must select your investment instrument preference."
                    );
                }

                if (error.length <= 0) {
                    const client = this.props.client;
                    client
                        .mutate({
                            mutation: UPDATE_USER_MUTATION,
                            variables: {
                                input: {
                                    title: userState.title,
                                    purpose_for_using_convergence_v2:
                                        userState.purpose_for_using_convergence_v2,
                                    location: userState.location
                                }
                            }
                        })
                        .then((data) => {
                            if (data) {
                                return client
                                    .mutate({
                                        mutation: COMPLETE_PREFERENCES_MUTATION
                                    })
                                    .then((user) => {
                                        if (user) {
                                            redirect({}, "/settings/profile");
                                        }
                                    });
                            }
                        });
                } else {
                    this.setState({ error });
                }
            });
    };

    render() {
        return (
            <div style={{ maxWidth: "800px", margin: "0 auto" }}>
                {this.state.error.length > 0 && (
                    <MessageBox type={"danger"}>
                        You have the following errors:
                        <br />
                        <ul className={"mb-0"}>
                            {this.state.error.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </MessageBox>
                )}
                <Box>
                    <Section>
                        <h2 className="title mb-2">
                            Your Personal Information
                        </h2>
                        <p className="mb-3">
                            Update some additional details regarding your
                            personal information. We will use this information
                            to improve your experience on the Convergence
                            platform.
                        </p>
                        <TextField
                            fieldName={"title"}
                            label={"Title:"}
                            value={this.state.user.title}
                            placeHolder={"e.g. Analyst"}
                            onChange={this.onChange}
                        />
                        <FormGroup tag="fieldset">
                            <legend className="col-form-label pt-0 font-weight-bold">
                                Purpose for using Convergence:
                            </legend>
                            <CheckBoxField
                                fieldName={"purpose_for_using_convergence_v2"}
                                value={
                                    this.state.user
                                        .purpose_for_using_convergence_v2
                                        ? this.state.user
                                              .purpose_for_using_convergence_v2
                                        : ""
                                }
                                options={USER_PURPOSE_FOR_USING_CONVERGENCE}
                                onChange={this.onChange}
                                multiple={true}
                            />
                        </FormGroup>
                        <LocationAutoCompleteField
                            fieldName={"location"}
                            label={"Location:"}
                            placeHolder={"e.g. Toronto, Ontario, Canada"}
                            value={this.state.user.location}
                            onChange={this.onChange}
                        />
                    </Section>
                </Box>
                <Box>
                    <Section>{this.props.renderPreferences()}</Section>
                </Box>
                <Form onSubmit={this.onSubmit}>
                    <FormGroup row className="pl-3 pr-3 mt-3 mb-0">
                        <SubmitButton
                            fieldName={"next"}
                            value="next"
                            label={"Save Interests"}
                            color={"success"}
                            block={true}
                            size={"lg"}
                            style={{ position: "sticky", top: 0 }}
                        />
                    </FormGroup>
                </Form>
            </div>
        );
    }
}
export default withApollo<Props>(UpdateUserPreferencesForm);
