/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import MillerColumnOption from "./MillerColumnOption";

type Props = {
    // TS TODO TYPE STRICTER
    title;
    options;
    value;
    activeOption;
    onClick;
    height;
};
class MillerColumnPanel extends React.PureComponent<Props> {
    container: any;
    // TS CODE CHANGE. MOVED TO STATIC PROP TYPES
    static propTypes = {
        value: PropTypes.array,
        activeOption: PropTypes.any,
        options: PropTypes.array,
        title: PropTypes.string,
        onClick: PropTypes.func
    };
    constructor(props) {
        super(props);
        this.container = null;
    }

    render() {
        const { title, options, value, activeOption, onClick } = this.props;
        const height =
            typeof this.props.height !== "undefined"
                ? this.props.height
                : "200px";

        return (
            <React.Fragment>
                <style jsx>
                    {`
                        .miller-column-panel {
                            position: relative;
                            display: inline-block;
                        }
                        .miller-column-container {
                            overflow: scroll;
                            height: ${height};
                        }
                        .miller-column-title {
                            position: relative;
                            font-size: 0.8rem;
                            text-transform: uppercase;
                            padding: 5px;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                        }
                        .miller-column-make-selection {
                            position: relative;
                            top: 50%;
                            text-align: center;
                            transform: perspective(1px) translateY(-50%);
                        }
                    `}
                </style>
                <div className="miller-column-panel card m-0 p-0">
                    {title && (
                        <div className="miller-column-title">{title}</div>
                    )}
                    <div
                        className={"miller-column-container"}
                        ref={(container) => {
                            this.container = container;
                        }}
                    >
                        {options &&
                            options.length > 0 &&
                            options.map((option) => (
                                <MillerColumnOption
                                    key={option}
                                    option={option}
                                    value={value}
                                    activeOption={activeOption}
                                    onClick={onClick}
                                />
                            ))}
                        {((options && options.length <= 0) || !options) && (
                            <p className="miller-column-make-selection">
                                No options available
                            </p>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MillerColumnPanel;
