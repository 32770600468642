/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";

const Section = ({ children }) => (
    <section className="section">{children}</section>
);

Section.propTypes = {
    children: PropTypes.any.isRequired
};

export default Section;
