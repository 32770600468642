/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import { CardBody } from "reactstrap";

const CardContent = ({ children }) => <CardBody>{children}</CardBody>;

CardContent.propTypes = {
    children: PropTypes.any.isRequired
};

export default CardContent;
