/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import { Col } from "reactstrap";

const Column = ({ children, ...props }) => <Col {...props}>{children}</Col>;

Column.propTypes = {
    children: PropTypes.any.isRequired
};

export default Column;
