import React from "react";
import Router from "../routes";

type Props = {
    routeKey?;
    children;
    params?;
    query?;
    href?;
    hash?;
    id?;
    shallow?;
    replace?;
};
const BaseLink = ({
    routeKey,
    children,
    params = {},
    query = {},
    ...props
}: Props) => (
    <Router.Link routeKey={routeKey} params={params} query={query} {...props}>
        {({ url }) => children({ url })}
    </Router.Link>
);

export default BaseLink;
