import React from "react";
import faStop from "@fortawesome/fontawesome-free-solid/faStopCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "../../../layout/Card/Card";
import CardContent from "../../../layout/Card/CardContent";
import Link from "../../../theme/3.0/Link";

const InstitutionInDraftOverride = () => (
    <React.Fragment>
        <Card>
            <CardContent>
                <h2 className="card-title">
                    <span className="text-warning">
                        <FontAwesomeIcon
                            // @ts-ignore
                            icon={faStop}
                        />
                    </span>{" "}
                    Complete your Institution Registration
                </h2>
                <p>
                    You currently have an institution registration application
                    in draft. You must complete your registration to gain access
                    to member-only features.
                    <br />
                    Please reach out to{" "}
                    <a href="mailto:support@convergence.finance">
                        {" "}
                        support@convergence.finance
                    </a>{" "}
                    with any questions.
                </p>
                <Link
                    routeKey={"institution-register"}
                    label={"Complete Institution Registration"}
                    colour={"success"}
                    size={"lg"}
                    isButton
                />
            </CardContent>
        </Card>
    </React.Fragment>
);

export default InstitutionInDraftOverride;
