import PropTypes from "prop-types";
import React from "react";
import { parseISO, differenceInHours } from "date-fns";
import UpgradeTrialAccountButton from "./UpgradeTrialAccountButton";

const TrialAccountExpiredNotification = ({ user }) => {
    const hours = differenceInHours(parseISO(user.expire_at), new Date());
    return (
        <div className="alert alert-warning" style={{ margin: "0" }}>
            <div className="container">
                {hours > 0 && (
                    <React.Fragment>
                        <strong>Your trial account is about to expire.</strong>
                        <br />
                        In less than 24 hours, your trial account will expire.
                        To continue using our Platform, please contact us at{" "}
                        <a href="mailto: support@convergence.finance">
                            support@convergence.finance
                        </a>
                        .
                    </React.Fragment>
                )}
                {hours <= 0 && (
                    <React.Fragment>
                        <strong>Your trial account has expired.</strong>
                        <br />
                        Your trial account has expired. To continue using our
                        Platform, please contact us at{" "}
                        <a href="mailto: support@convergence.finance">
                            support@convergence.finance
                        </a>
                        .
                    </React.Fragment>
                )}
                <br />
                <br />
                <UpgradeTrialAccountButton />
            </div>
        </div>
    );
};

TrialAccountExpiredNotification.propTypes = {
    user: PropTypes.object
};

export default TrialAccountExpiredNotification;
