import React from "react";
import theme from "../theme";

const Container = ({ children, ...props }) => (
    <div {...props}>
        <style jsx>{`
             {
                max-width: ${theme.container.xs};
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;
            }

            @media (min-width: ${theme.breakpoint.sm}) {
                 {
                    max-width: ${theme.container.sm};
                }
            }

            @media (min-width: ${theme.breakpoint.md}) {
                 {
                    max-width: ${theme.container.md};
                }
            }

            @media (min-width: ${theme.breakpoint.lg}) {
                 {
                    max-width: ${theme.container.lg};
                }
            }

            @media (min-width: ${theme.breakpoint.xl}) {
                 {
                    max-width: ${theme.container.xl};
                }
            }
            @media (min-width: ${theme.breakpoint.xxl}) {
                 {
                    max-width: ${theme.container.xxl};
                }
            }
        `}</style>
        {children}
    </div>
);

export default Container;
