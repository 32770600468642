/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";

const Form = ({ children, ...props }) => <form {...props}>{children}</form>;

Form.propTypes = {
    children: PropTypes.any.isRequired
};

export default Form;
