// Code from: https://malloc.fi/using-google-analytics-with-next-js

import { UaEventOptions } from "react-ga4/types/ga4";
import ReactGA from "react-ga4";
import config from "../config/config";

export const initGA = () => {
    console.debug("> GA init");
    const gaTrackingCode = config.ga_tracking_code;
    const ga4MeasurementCode = config.ga4_measurement_code;
    ReactGA.initialize([
        {
            trackingId: gaTrackingCode
        },
        {
            trackingId: ga4MeasurementCode
        }
    ]);
};

export const logPageView = (userId = null, institutionId = null) => {
    let setParams: {
        page: string;
        title: string;
        userId?: string;
        dimension1?: string;
        dimension2?: string;
    } = {
        page: window.location.pathname,
        title: document.title
    };
    let userProperties: any = {};
    if (userId) {
        setParams.userId = userId;
        setParams.dimension1 = userId;
        userProperties.userId = userId;
        userProperties.dimension1 = userId;
    }

    if (institutionId) {
        setParams.dimension2 = institutionId;
        userProperties.institutionId = institutionId;
        userProperties.dimension2 = institutionId;
    }

    ReactGA.set(setParams);
    ReactGA.set({ user_properties: userProperties });

    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: document.title
    });
};

export const setUser = (userId) => {
    ReactGA.set({ userId });
};

export const logEvent = (
    category = "",
    action = "",
    value = null,
    label = ""
) => {
    if (category && action) {
        let event: UaEventOptions = { category, action };
        if (value) {
            event.value = value;
        }
        if (label && label.length > 0) {
            event.label = label;
        }
        ReactGA.event(event);
    }
};
