/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import faCheckSquare from "@fortawesome/fontawesome-free-solid/faCheckSquare";
import faSquare from "@fortawesome/fontawesome-free-solid/faSquare";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { BRAND_COLOURS } from "../../../../constants";

type Props = {
    option;
    value;
    activeOption;
    onClick;
};
class MillerColumnOption extends React.PureComponent<Props> {
    static propType = {
        value: PropTypes.array.isRequired,
        option: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        activeOption: PropTypes.any
    };
    container: any;
    constructor(props) {
        super(props);
        this.container = null;
    }
    render() {
        const { option, value, activeOption, onClick } = this.props;

        const isActive = activeOption === option;
        const isSelected =
            value && Array.isArray(value) && value.includes(option);

        let optionClass = "miller-column-option";
        if (isSelected) {
            optionClass += " selected";
        }
        if (isActive) {
            optionClass += " active";
        }

        return (
            <React.Fragment>
                <style jsx>
                    {`
                    .miller-column-option {
                        display: block;
                        cursor: pointer;
                        -webkit-user-select: none
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        padding: 5px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    }
                    .miller-column-option > * {
                        pointer-events: none;
                    }
                    .miller-column-option-icon {
                        display: inline-block;
                    }
                    .miller-column-option-label  {
                        display: inline-block;
                    }
                    .miller-column-option-icon,
                    .miller-column-option-label {
                        vertical-align: middle;
                    }
                    .miller-column-option.active {
                        color: white;
                        border-top-color: ${BRAND_COLOURS.secondary};
                        border-bottom-color: ${BRAND_COLOURS.blue};
                        background-color: ${BRAND_COLOURS.blue};
                    }
                    .miller-column-option.selected .icon {
                        color: ${BRAND_COLOURS.blue};
                    }
                    .miller-column-option.active .icon {
                        color: white;
                    }
                    .miller-column-option-icon {
                        margin-right: 5px;
                        color: rgba(0, 0, 0, 0.125);
                    }
                `}
                </style>
                <div
                    className={optionClass}
                    data-option={option}
                    onClick={onClick}
                    onKeyDown={onClick}
                    tabIndex={0}
                >
                    <div className="miller-column-option-icon">
                        <div className="icon">
                            {isSelected ? (
                                <FontAwesomeIcon
                                    icon={faCheckSquare as IconProp}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faSquare as IconProp} />
                            )}
                        </div>
                    </div>
                    <div className="miller-column-option-label">{option}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default MillerColumnOption;
