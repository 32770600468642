/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import gql from "graphql-tag";
import { DOCUMENT_FRAGMENT } from "./DocumentQuery";

export const HISTORICAL_INSTITUTION_FRAGMENT = gql`
    fragment generalHistoricalInstitutionFields on HistoricalInstitution {
        id
        name
        logo
        description
        domicile_country
        is_impact_driven
        industry
        sectors
        type
        sub_type
        website
    }
`;

export const INSTITUTION_FRAGMENT = gql`
    fragment generalInstitutionFields on Institution {
        id
        is_active
        is_trial
        status
        sub_status
        name
        legal_name
        website
        logo
        overview
        street_address
        street_address_line_two
        city
        state
        postal_code
        country
        invests_or_provides_funding
        types_of_capital
        is_fundraising_for_bf_transaction
        domiciled_country
        domiciled_state
        institution_type
        institution_sub_type
        institution_type_other
        hear_about_us
        hear_about_us_convergence_member_name
        hear_about_us_convergence_member_email
        hear_about_us_convergence_staff_name
        hear_about_us_other
        completed_steps
        agreements
        agreement_choices
        approved_at
        tags
    }
`;

export const INSTITUTION_PREFERENCE_FRAGMENT = gql`
    fragment generalInstitutionPreferenceFields on InstitutionPreference {
        deal_types
        regions
        regions_v2
        countries
        sectors
        sub_sectors
        investment_instruments
        sub_investment_instruments
    }
`;

export const GET_INSTITUTION_DOCUMENTS = gql`
    query GetInstitutionDocuments($id: String!) {
        institutionDocuments(id: $id) {
            ...generalDocumentFields
        }
    }
    ${DOCUMENT_FRAGMENT}
`;

export const GET_MY_INSTITUTION_WITH_PREFERENCES = gql`
    query GetMyInstitutionWithPreferences {
        institution: myInstitution {
            ...generalInstitutionFields
            approval_method
            approval_domains
            created_at
            preferences {
                ...generalInstitutionPreferenceFields
            }
        }
    }
    ${INSTITUTION_FRAGMENT}
    ${INSTITUTION_PREFERENCE_FRAGMENT}
`;

export const GET_MY_INSTITUTION = gql`
    query GetMyInstitution {
        institution: myInstitution {
            ...generalInstitutionFields
            approval_method
            approval_domains
        }
    }
    ${INSTITUTION_FRAGMENT}
`;

export const GET_INSTITUTION_QUERY = gql`
    query GetInstitution($id: String!) {
        institution(id: $id) {
            ...generalInstitutionFields
            preferences {
                ...generalInstitutionPreferenceFields
            }
        }
    }
    ${INSTITUTION_FRAGMENT}
    ${INSTITUTION_PREFERENCE_FRAGMENT}
`;

export const GET_INVESTOR_INSTITUTION_QUERY = gql`
    query GetInvestorInstitution($id: String!) {
        institution: investorInstitution(id: $id) {
            ...generalHistoricalInstitutionFields
        }
    }
    ${HISTORICAL_INSTITUTION_FRAGMENT}
`;

export const GET_HISTORICAL_INSTITUTION_QUERY = gql`
    query GetHistoricalInstitution($id: String!) {
        institution: historicalInstitution(id: $id) {
            ...generalHistoricalInstitutionFields
        }
    }
    ${HISTORICAL_INSTITUTION_FRAGMENT}
`;

export const INSTITUTIONS_QUERY = gql`
    query Institutions(
        $after: String
        $first: Int
        $filters: SearchInstitutionsFiltersInput
        $sort: SearchSortInput
    ) {
        institutions(
            after: $after
            first: $first
            filters: $filters
            sort: $sort
        ) {
            total_count
            edges {
                cursor
                node {
                    id
                    status
                    name
                    website
                    logo
                    overview
                }
            }
            pageInfo {
                start_cursor
                end_cursor
                has_next_page
            }
        }
        stats {
            institutionSummaryAnalysis
        }
    }
`;

export const HISTORICAL_INSTITUTIONS_QUERY = gql`
    query HistoricalInstitutions(
        $after: String
        $first: Int
        $filters: SearchInstitutionsFiltersInput
        $sort: SearchSortInput
    ) {
        institutions: historicalInstitutions(
            after: $after
            first: $first
            filters: $filters
            sort: $sort
        ) {
            total_count
            edges {
                cursor
                node {
                    id
                    name
                    website
                    logo
                    description
                }
            }
            pageInfo {
                start_cursor
                end_cursor
                has_next_page
            }
        }
    }
`;

export const INVESTORS_QUERY = gql`
    query Investors(
        $first: Int
        $after: String
        $before: String
        $sort: SearchSortInput
        $filters: HistoricalInstitutionFilter
    ) {
        investors(
            first: $first
            after: $after
            before: $before
            sort: $sort
            filters: $filters
        ) {
            total_count
            pageInfo {
                start_cursor
                end_cursor
                has_next_page
            }
            edges {
                cursor
                relevance
                node {
                    id
                    name
                    description
                    logo
                    is_active
                }
            }
        }
    }
`;

export const CHECK_APPROVAL_DOMAIN = gql`
    query CheckApprovalDomainQuery {
        checkApprovalDomain {
            institution {
                ...generalInstitutionFields
                owner {
                    id
                    full_name
                    email_address
                }
                created_at
                last_modified_at
            }
            user_institution_approval {
                user_id
            }
        }
    }
    ${INSTITUTION_FRAGMENT}
`;

export const AUTOCOMPLETE_HISTORICAL_INSTITUTION_QUERY = gql`
    query AutoCompleteHistoricalInstitution($keyword: String!) {
        institutions: autoCompleteHistoricalInstitution(keyword: $keyword) {
            id
            name
        }
    }
`;

export const GET_MY_INSTITUTION_ADMINS_QUERY = gql`
    query GetMyInstitutionAdmins {
        users: getMyInstitutionAdmins {
            id
            first_name
            last_name
            full_name
            email_address
        }
    }
`;
