/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import { Row } from "reactstrap";

const Columns = ({ children, ...props }) => <Row {...props}>{children}</Row>;

Columns.propTypes = {
    children: PropTypes.any.isRequired
};

export default Columns;
