/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import Form from "../../../layout/Form/Form";
import { BRAND_COLOURS } from "../../../../constants";

const EditPreferenceRanges = ({
    fieldName,
    minFieldName,
    maxFieldName,
    minValue,
    maxValue,
    minPlaceholder,
    maxPlaceholder,
    onChange
}) => (
    <React.Fragment>
        <style jsx>
            {`
                .preference-form {
                    box-sizing: content-box;
                    margin-left: -5px;
                    padding: 5px;
                    border: 1px dashed rgba(0, 0, 0, 0.125);
                }
                .preference-options {
                    margin: 0
                    padding: 0;
                    list-style: none;
                }
                .preference-options li {
                    position: relative;
                    display: inline-block;
                    margin-bottom: 5px;
                    margin-right: 10px;
                    vertical-align: top;
                }
                .preference-options li label {
                    margin: 0;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
                .preference-options li label input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }
                .preference-options li label .selector {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    height: 150px;
                    width: 150px;
                    padding: 20px;
                    text-align: center;

                    border: 1px solid rgba(0, 0, 0, 0.100);
                    color: rgba(0, 0, 0, 0.500);
                    background-color: ${BRAND_COLOURS.light};
                }
                .preference-options li label .selector .option-name {
                    position: relative;
                    display: block;
                }
                .preference-options li label .selector .checkmark {
                    display: block;
                    height: 25px;
                    width: 25px;
                    margin: 10px auto 0 auto;
                    background-color: #eee;
                }
                .preference-options li label input:checked ~ .selector {
                    color: ${BRAND_COLOURS.success};
                    background-color: white;
                    border: 1px solid ${BRAND_COLOURS.success};
                }

                .preference-options li label input:checked ~ .selector .checkmark {
                    background-color: ${BRAND_COLOURS.teal};
                    border: 1px solid ${BRAND_COLOURS.success};
                }
            `}
        </style>
        <div className="preference-form">
            <Form data-preference-key-name={fieldName}>
                <div className="preference-options">
                    <div className="form-group">
                        <div className="row">
                            <div className="col">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            Min
                                        </div>
                                    </div>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name={minFieldName}
                                        value={minValue}
                                        placeholder={minPlaceholder}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            Max
                                        </div>
                                    </div>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name={maxFieldName}
                                        value={maxValue}
                                        placeholder={maxPlaceholder}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </React.Fragment>
);

EditPreferenceRanges.propTypes = {
    fieldName: PropTypes.string.isRequired,
    minFieldName: PropTypes.string.isRequired,
    maxFieldName: PropTypes.string.isRequired,
    minValue: PropTypes.any,
    maxValue: PropTypes.any,
    minPlaceholder: PropTypes.string.isRequired,
    maxPlaceholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default EditPreferenceRanges;
