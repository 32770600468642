import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { BRAND_COLOURS } from "../../../constants";

const FilterButtonActiveFilterBadge = ({
    value,
    filterName,
    filter,
    onClickClearFilter
}) => (
    <React.Fragment>
        <style jsx>{`
            .filter {
                display: inline-block;
                margin-right: 10px;
                font-weight: normal;
            }

            .filter.badge {
                padding: 0;
                font-weight: normal;
            }

            .filter button {
                float: none;
                color: white;
                font-size: 1rem;
                line-height: 0.5rem;
                font-weight: normal;
                padding: 0.5rem;
            }

            .filter-text {
                display: inline-block;
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 0 0.5rem;
            }

            .filter-label {
                display: inline-block;
                padding: 0.5rem;
                background-color: ${BRAND_COLOURS.gray};
            }

            .close > * {
                pointer-events: none;
            }
        `}</style>
        <span
            className="filter badge badge-secondary align-self-center"
            id={"filter-" + filterName}
        >
            <span className="filter-label">
                {filter.label} ({Array.isArray(value) ? value.length : 1})
            </span>
            <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onClickClearFilter}
                data-filter={filterName}
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </span>
        {filter.multiple === true && (
            <UncontrolledTooltip
                placement="bottom"
                target={"filter-" + filterName}
                delay={0}
            >
                {Array.isArray(value)
                    ? value.join(", ")
                    : Object.keys(value).join(", ")}
            </UncontrolledTooltip>
        )}
        {filter.multiple !== true && (
            <UncontrolledTooltip
                placement="bottom"
                target={"filter-" + filterName}
                delay={0}
            >
                {filterName === "investors" ? value.name : ""}
                {filterName === "keyword" ? value : ""}
                {filterName === "deploysCapital" ? value[0] : ""}
                {filterName === "min_max_deal_size" ? value.toString() : ""}
            </UncontrolledTooltip>
        )}
    </React.Fragment>
);

export default FilterButtonActiveFilterBadge;
