/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import { Button as ButtonBootstrap } from "reactstrap";

const Button = ({ fieldName, label, ...props }) => (
    <ButtonBootstrap type="button" name={fieldName} {...props}>
        {label}
    </ButtonBootstrap>
);

Button.propTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default Button;
