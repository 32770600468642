/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import cloneDeep from "lodash.clonedeep";
import PropTypes from "prop-types";
import React from "react";
import Form from "../../layout/Form/Form";
import MillerColumn from "../../layout/Form/MillerColumn/MillerColumn";
import SubmitButton from "../../layout/Form/SubmitButton";
import Column from "../../layout/Grid/Column";
import Columns from "../../layout/Grid/Columns";
import Modal from "../../layout/Modal/Modal";
import ModalCardFooter from "../../layout/Modal/ModalCardFooter";
import ModalCardHeader from "../../layout/Modal/ModalCardHeader";
import FilterOptions from "./FilterButtonModal/FilterOptions";
import theme from "../../theme/3.0/theme";

type Props = {
    defaultFilter;
    filters;
    onSubmit;
    onClickClose;
    availableFilters;
    modalTitle;
    renderColumnHeaders;
};

type State = {
    filters;
    activeFilter;
};
class FilterButtonModal extends React.Component<Props, State> {
    // TS CODE CHANGE. MOVED TO STATIC PROP TYPES
    static propTypes = {
        availableFilters: PropTypes.object.isRequired,
        filters: PropTypes.object.isRequired,
        onClickClose: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        defaultFilter: PropTypes.string,
        modalTitle: PropTypes.string,
        renderColumnHeaders: PropTypes.func
    };
    // TS TODO TYPE STRICTER
    defaultFilter: any;
    constructor(props) {
        super(props);
        this.defaultFilter = this.props.defaultFilter
            ? this.props.defaultFilter
            : null;
        this.state = {
            filters: cloneDeep(this.props.filters),
            activeFilter: this.props.defaultFilter
                ? this.props.defaultFilter
                : null
        };
    }

    onSubmit = (event) => {
        const { filters } = this.state;
        this.props.onSubmit(event, filters);
    };

    onChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        this.setState(({ filters }) => ({
            filters: {
                ...filters,
                [fieldName]: fieldValue
            }
        }));
    };

    onClickDismiss = () => {
        this.props.onClickClose();
    };

    setActiveFilter = (event) => {
        event.preventDefault();
        const activeFilter = event.target.getAttribute("data-filter");
        this.setState({ activeFilter });
    };

    onClickClearFilters = () => {
        const { filters } = this.state;

        let updatedFilters = {};
        Object.keys(filters).forEach((filterKey) => {
            if (typeof this.props.availableFilters[filterKey] !== "undefined") {
                updatedFilters[filterKey] = cloneDeep(
                    this.props.availableFilters[filterKey].default
                );
            }
        });

        this.setState({
            filters: updatedFilters,
            activeFilter: this.defaultFilter
        });
    };

    render() {
        let loadedFilter = null;
        const { activeFilter, filters } = this.state;
        let { modalTitle, availableFilters, renderColumnHeaders } = this.props;

        if (!modalTitle) {
            modalTitle = "Filters";
        }

        if (activeFilter) {
            loadedFilter = availableFilters[activeFilter];
        }

        const FilterComponent = loadedFilter.component;

        return (
            <Modal hasBackground={true} isActive={true} size="lg">
                <style jsx>
                    {`
                        .modal-column-title {
                            margin: 0;
                            padding: 0.5rem 1rem;
                            font-size: 0.9rem;
                            text-transform: uppercase;
                            color: white;
                            background-color: ${theme.colour.gray600};
                        }

                        .modal-column-title-options {
                            padding: 0.5rem 0;
                        }

                        .list-selections {
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            border-right: 1px solid ${theme.colour.gray400};
                            margin-right: -1px;
                        }
                    `}
                </style>
                <Form onSubmit={this.onSubmit}>
                    <ModalCardHeader
                        title={modalTitle}
                        onDismiss={this.onClickDismiss}
                    />
                    <Columns className={"no-gutters"}>
                        {!renderColumnHeaders && (
                            <React.Fragment>
                                <Column className="col-4">
                                    <p className="modal-column-title">
                                        Filters...
                                    </p>
                                </Column>
                                <Column>
                                    <p className="modal-column-title modal-column-title-options">
                                        Filter options...
                                    </p>
                                </Column>
                            </React.Fragment>
                        )}
                        {renderColumnHeaders && renderColumnHeaders()}
                    </Columns>
                    <Columns className={"no-gutters"}>
                        <Column className="col-4">
                            <ul className="list-selections">
                                {
                                    <FilterOptions
                                        availableFilters={availableFilters}
                                        activeFilter={activeFilter}
                                        filters={filters}
                                        setActiveFilter={this.setActiveFilter}
                                    />
                                }
                            </ul>
                        </Column>
                        <Column>
                            {!loadedFilter && (
                                <p className="p-3">
                                    <em>
                                        Select a filter on the left to get
                                        started.
                                    </em>
                                </p>
                            )}
                            {loadedFilter && (
                                <React.Fragment>
                                    {loadedFilter.isEnabled(filters) ===
                                        false && (
                                        <p className="p-3">
                                            <em>
                                                {loadedFilter.notEnabledMessage}
                                            </em>
                                        </p>
                                    )}
                                    {loadedFilter.isEnabled(filters) ===
                                        true && (
                                        <React.Fragment>
                                            {FilterComponent && (
                                                <FilterComponent
                                                    name={activeFilter}
                                                    value={
                                                        filters[activeFilter]
                                                    }
                                                    onChange={this.onChange}
                                                    multiple={
                                                        loadedFilter.multiple
                                                    }
                                                />
                                            )}
                                            {!FilterComponent && (
                                                <MillerColumn
                                                    name={activeFilter}
                                                    optionTitles={
                                                        loadedFilter.optionTitles
                                                    }
                                                    options={loadedFilter.options(
                                                        filters
                                                    )}
                                                    value={
                                                        filters[activeFilter]
                                                    }
                                                    onChange={this.onChange}
                                                    multiple={
                                                        loadedFilter.multiple
                                                    }
                                                    height={"325px"}
                                                />
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </Column>
                    </Columns>
                    <ModalCardFooter className="is-expanded">
                        <button
                            type="button"
                            name="clear"
                            className={"btn btn-link text-danger"}
                            onClick={this.onClickClearFilters}
                        >
                            Clear Filters
                        </button>
                        <SubmitButton fieldName="submit" label="Set Filters" />
                    </ModalCardFooter>
                </Form>
            </Modal>
        );
    }
}

export default FilterButtonModal;
