import React from "react";
import { INSTITUTION_SUB_STATUS_LEGACY } from "../../constants";

const LegacyAccountNotification = ({ user }) => {
    return user &&
        user.institution &&
        user.institution.sub_status === INSTITUTION_SUB_STATUS_LEGACY ? (
        <React.Fragment>
            <div className={"alert alert-warning"} style={{ margin: "0" }}>
                <div className="container">
                    <React.Fragment>
                        <strong>
                            Your institution is currently set to Legacy status.
                        </strong>
                        <br />
                        Legacy users have limited access to Convergence products
                        and services and are unable to access member-only
                        content, training opportunities, and data insights.
                        Please reach out to{" "}
                        <a href="mailto: support@convergence.finance">
                            support@convergence.finance
                        </a>{" "}
                        to learn more about subscription options and unlock
                        member-only products and services
                    </React.Fragment>
                </div>
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment />
    );
};
export default LegacyAccountNotification;
