/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import NewHeader from "../theme/3.0/Page/Header";
import Authenticated from "../Authenticated";

const Header = ({ currentRouteKey }) => (
    <Authenticated passNullUser>
        {(user, authorizationService) => (
            <NewHeader
                currentRouteKey={currentRouteKey}
                user={user}
                authorizationService={authorizationService}
            />
        )}
    </Authenticated>
);

export default Header;
