/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

export default {
    google_api_key: "AIzaSyA-XQxTp3dfREOJSokEU-fcRH1SMcK-bYQ",
    ga_tracking_code: "UA-74964127-2",
    ga4_measurement_code: "G-G2Y04LMES7",
    cookie_name: "token",

    graphql_api_url: "https://platform-dev.convergence.finance/api-service/public",
    graphql_api_ws_url: "wss://platform-dev.convergence.finance/api-service/public/subscriptions",
    graphql_service_api_url: "TODO",

    contentful_environment: "development",
    contentful_space_id: "4cgqlwde6qy0",
    contentful_access_token: "cbb0915c9b1ef8c1b8a49620aa0a5237c110fd2229fbe91126e50d3569746a82",
    contentful_file_path: "api/file",
    contentful_proxy: "https://platform-dev.convergence.finance/api-service/public/contentful-proxy"
};
