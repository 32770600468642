/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import faSlidersH from "@fortawesome/fontawesome-free-solid/faSlidersH";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import FilterButtonModal from "./FilterButtonModal";

type Props = {
    // TS TODO TYPE STRICTER
    showModal;
    onSubmit;
    onClickOpen;
    onClickClose;
    buttonClassName;
    modalTitle;
    buttonLabel;
    renderColumnHeaders;
    defaultFilter;
    filters;
    availableFilters;
    groupFilters?;
};

type State = {
    // TS TODO TYPE STRICTER
    showModal;
};
class FilterButton extends React.Component<Props, State> {
    // TS CODE CHANGE. MOVED TO STATIC PROP TYPES
    static propTypes = {
        availableFilters: PropTypes.object.isRequired,
        filters: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired,
        defaultFilter: PropTypes.string,
        modalTitle: PropTypes.string,
        showModal: PropTypes.bool,
        renderColumnHeaders: PropTypes.func,
        buttonLabel: PropTypes.string,
        buttonClassName: PropTypes.string,
        onClickOpen: PropTypes.func,
        onClickClose: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            showModal: this.props.showModal
        };
    }

    onSubmit = (event, filters) => {
        event.preventDefault();
        if (this.props.onSubmit(filters)) {
            this.onClickClose(event);
        }
    };

    onClickOpen = (event) => {
        this.setState({ showModal: true }, () => {
            if (this.props.onClickOpen) {
                this.props.onClickOpen(event);
            }
        });
    };

    onClickClose = (event) => {
        this.setState({ showModal: false }, () => {
            if (this.props.onClickClose) {
                this.props.onClickClose(event);
            }
        });
    };

    render() {
        const { showModal } = this.state;
        let {
            buttonClassName,
            modalTitle,
            buttonLabel,
            renderColumnHeaders,
            defaultFilter,
            filters,
            availableFilters,
            groupFilters
        } = this.props;

        if (!buttonLabel) {
            buttonLabel = "Filters";
        }

        if (!buttonClassName) {
            buttonClassName = "btn btn-primary filter-button";
        }

        return (
            <React.Fragment>
                {showModal && (
                    <FilterButtonModal
                        // @ts-ignore POSSIBLE ERROR FOUND no groupFilters
                        groupFilters={groupFilters}
                        defaultFilter={defaultFilter}
                        modalTitle={modalTitle}
                        availableFilters={availableFilters}
                        filters={filters}
                        onSubmit={this.onSubmit}
                        onClickClose={this.onClickClose}
                        renderColumnHeaders={renderColumnHeaders}
                    />
                )}
                <button className={buttonClassName} onClick={this.onClickOpen}>
                    <FontAwesomeIcon icon={faSlidersH as IconProp} />{" "}
                    {buttonLabel}
                </button>
            </React.Fragment>
        );
    }
}

export default FilterButton;
