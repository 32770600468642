import React from "react";
import { IMPERSONATE_QUERY } from "../../graphql/query/AuthQuery";
import { Query } from "react-apollo";

const ImpersonationNotification = ({ user }) => {
    return (
        <Query query={IMPERSONATE_QUERY} fetchPolicy={"cache-only"}>
            {({ data }) => {
                let classNames = [
                    "alert",
                    "alert-warning",
                    "alert-impersonate"
                ];
                if (data && data.impersonate === true) {
                    classNames.push("alert-impersonate-active");
                }
                return (
                    <React.Fragment>
                        <style jsx>
                            {`
                                .alert-impersonate {
                                    margin: 0;
                                    position: sticky;
                                    top: 0;
                                    z-index: 1040;
                                    display: none;
                                }
                                .alert-impersonate-active {
                                    display: block;
                                }
                            `}
                        </style>
                        <div className={classNames.join(" ")}>
                            <div className="container">
                                <React.Fragment>
                                    <strong>
                                        You are currently impersonating the
                                        user:
                                    </strong>
                                    <br />
                                    {user && user.first_name}{" "}
                                    {user && user.last_name} (
                                    {user && user.email_address}
                                    ).
                                </React.Fragment>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }}
        </Query>
    );
};
export default ImpersonationNotification;
