import React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import Notification from "../Notification";
import { FormGroup } from "reactstrap";
import { USER_PURPOSE_FOR_USING_CONVERGENCE } from "../../constants";
import { UPDATE_USER_MUTATION } from "../../graphql/mutation/UserMutation";
import ValidationService from "../../service/ValidationService";
import CheckBoxField from "../layout/Form/CheckBoxField";
import Form from "../layout/Form/Form";
import SubmitButton from "../layout/Form/SubmitButton";
import TextField from "../layout/Form/TextField";
import Modal from "../layout/Modal/Modal";
import ModalCard from "../layout/Modal/ModalCard";
import ModalCardBody from "../layout/Modal/ModalCardBody";
import ModalCardFooter from "../layout/Modal/ModalCardFooter";
import ModalCardHeader from "../layout/Modal/ModalCardHeader";
import LocationAutoCompleteField from "./LocationAutoCompleteField";
import { User } from "../../types/mainTypes";

type Props = {
    user: User;
};

type State = { form; isValid; validation };
class ForceUserUpdateModal extends React.Component<
    WithApolloClient<Props>,
    State
> {
    validationService: ValidationService;
    // TS conflict
    // static propTypes = {
    //     user: PropTypes.object.isRequired
    // };

    constructor(props) {
        super(props);

        this.validationService = new ValidationService(this.validationRules());

        this.state = {
            form: {
                title: this.props.user.title ? this.props.user.title : "",
                purpose_for_using_convergence_v2: this.props.user
                    .purpose_for_using_convergence_v2
                    ? this.props.user.purpose_for_using_convergence_v2
                    : [],
                location: this.props.user.location
                    ? this.props.user.location
                    : ""
            },
            isValid: true,
            validation: this.validationService.getValidations()
        };
    }

    validationRules = () => {
        return {
            title: {
                notEmpty: {
                    message: "You must provide us with your job title"
                }
            },
            purpose_for_using_convergence_v2: {
                hasLength: {
                    minLength: 1,
                    maxLength: 2,
                    message:
                        "You must provide your purpose for using the Convergence platform (max 2 selections)"
                }
            },
            location: {
                notEmpty: {
                    message: "You must provide your location address"
                }
            }
        };
    };

    onSubmit = (event) => {
        const { form } = this.state;

        event.preventDefault();

        this.validationService.validateFields(form);

        if (this.validationService.isValid) {
            const { client } = this.props;

            client
                .mutate({
                    mutation: UPDATE_USER_MUTATION,
                    variables: {
                        input: form
                    }
                })
                .then(() => {
                    Notification.success(
                        <div>Your profile has been successfully updated.</div>,
                        { duration: 6 }
                    );
                })
                .catch((error) => {
                    console.error(error);
                    Notification.error(
                        "Uh-oh! An error occurred and we were unable to save your changes. Try again."
                    );
                });
        }

        this.setState({
            validation: this.validationService.getValidations(),
            isValid: this.validationService.isValid
        });
    };

    onChange = (event) => {
        const fieldChecked = event.target.checked;
        const fieldName = event.target.name;
        let fieldValue = event.target.value;

        let form = this.state.form;

        if (event.target.type === "checkbox") {
            let optionsField = Array.isArray(form[fieldName])
                ? form[fieldName]
                : [];

            let currentFieldValue = new Map();
            optionsField.forEach((value) => {
                currentFieldValue.set(value, value);
            });

            if (fieldChecked) {
                currentFieldValue.set(fieldValue, fieldValue);
            } else {
                currentFieldValue.delete(fieldValue);
            }

            fieldValue = [];
            currentFieldValue.forEach((val) => {
                if (val) {
                    fieldValue.push(val);
                }
            });
        }

        this.validationService.setData(form);
        this.validationService.validateField(fieldName, fieldValue);

        form[fieldName] = fieldValue;

        this.setState({
            form,
            validation: this.validationService.getValidations(),
            isValid: this.validationService.isValid
        });
    };

    render() {
        const { validation, isValid, form } = this.state;

        return (
            <Modal hasBackground={true} isActive={true}>
                <Form onSubmit={this.onSubmit}>
                    <ModalCard>
                        <ModalCardHeader title="Update your Profile" />
                        <ModalCardBody>
                            <p>
                                We need some additional information to help us
                                improve your experience on our platform. Simply
                                fill out the information below and click "Update
                                Profile" button and this screen will disappear.
                            </p>
                            <div className="field">
                                <div className="field-body">
                                    <TextField
                                        fieldName="title"
                                        label="Title / Position"
                                        placeHolder="e.g. Analyst"
                                        validation={ValidationService.getFieldValidation(
                                            validation,
                                            "title"
                                        )}
                                        onChange={this.onChange}
                                        value={
                                            form.title !== null
                                                ? form.title
                                                : ""
                                        }
                                        autoComplete={"new-password"}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <div className="field-body">
                                    <LocationAutoCompleteField
                                        fieldName={"location"}
                                        label={"Location:"}
                                        placeHolder={
                                            "e.g. Toronto, Ontario, Canada"
                                        }
                                        value={form.location}
                                        onChange={this.onChange}
                                        validation={validation}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <div className="field-body">
                                    <FormGroup tag="fieldset">
                                        <legend className="col-form-label pt-0 font-weight-bold">
                                            Purpose for using Convergence:
                                        </legend>
                                        <CheckBoxField
                                            fieldName={
                                                "purpose_for_using_convergence_v2"
                                            }
                                            value={
                                                form.purpose_for_using_convergence_v2
                                                    ? form.purpose_for_using_convergence_v2
                                                    : ""
                                            }
                                            options={
                                                USER_PURPOSE_FOR_USING_CONVERGENCE
                                            }
                                            onChange={this.onChange}
                                            validation={ValidationService.getFieldValidation(
                                                validation,
                                                "purpose_for_using_convergence_v2"
                                            )}
                                            multiple={true}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </ModalCardBody>
                        <ModalCardFooter className="is-expanded">
                            <SubmitButton
                                fieldName="submit"
                                label="Update Profile"
                                disabled={!isValid}
                            />
                        </ModalCardFooter>
                    </ModalCard>
                </Form>
            </Modal>
        );
    }
}

export default withApollo<Props>(ForceUserUpdateModal);
