/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import faBan from "@fortawesome/fontawesome-free-solid/faBan";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import Card from "../../layout/Card/Card";
import CardContent from "../../layout/Card/CardContent";

const DeniedInstitutionApproval = ({ institution }) => (
    <React.Fragment>
        <Card>
            <CardContent>
                <h2 className="card-title">
                    <span className="text-danger">
                        <FontAwesomeIcon icon={faBan as IconProp} />
                    </span>{" "}
                    {institution.name}
                    's Convergence Membership Application Denied
                </h2>
                <p>
                    {institution.name}
                    's membership application has been denied. For more
                    information, please contact a Convergence staff member.
                </p>
            </CardContent>
        </Card>
    </React.Fragment>
);

DeniedInstitutionApproval.propTypes = {
    institution: PropTypes.object.isRequired
};

export default DeniedInstitutionApproval;
