/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";

const Box = ({ children }) => <div className="box">{children}</div>;

Box.propTypes = {
    children: PropTypes.any.isRequired
};

export default Box;
