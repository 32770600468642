/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import { FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";

type Props = {
    fieldName: string;
    value;
    options;
    validation?;
    helpText?: string;
    inline?;
    render?;
    onChange?;
    multiple?;
};

const CheckBoxField = ({
    fieldName,
    value,
    options,
    validation,
    helpText,
    inline,
    render,
    ...props
}: Props) => {
    let isValid = typeof validation !== "undefined" ? validation.isValid : null;
    let validationText =
        typeof validation !== "undefined" ? validation.message : null;

    return (
        <React.Fragment>
            {typeof render !== "undefined" &&
                render(value, options, fieldName, isValid, inline)}
            {typeof render === "undefined" &&
                options.map((option, i) => (
                    <FormGroup check={true} inline={inline} key={option.value}>
                        <Input
                            type="checkbox"
                            valid={isValid}
                            invalid={isValid === false ? true : null}
                            name={fieldName}
                            id={fieldName + i}
                            {...props}
                            value={option.value}
                            checked={
                                value ? value.includes(option.value) : null
                            }
                        />
                        <Label
                            check
                            for={fieldName + i}
                            className="font-weight-normal"
                        >
                            {option.label}
                        </Label>
                    </FormGroup>
                ))}
            {validationText && (
                <FormFeedback style={{ display: "block" }}>
                    {validationText}
                </FormFeedback>
            )}
            {helpText && <FormText>{helpText}</FormText>}
        </React.Fragment>
    );
};

export default CheckBoxField;
