/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import Head from "next/head";
import React, { PropsWithChildren } from "react";
import Container from "./Container";
import Footer from "./Footer";
import Header from "./Header";
// Layout
import Page from "./Page";
import { withRouter } from "next/router";
import Breadcrumb from "../theme/3.0/Page/Nav/Breadcrumb";
import theme from "../theme/3.0/theme";
import { WithRouterProps } from "next/dist/client/with-router";

interface Props extends WithRouterProps {
    // TS NOTE: no currentRouteKey. This is now taken off the router.query
    title?;
    pageTitle?: string;
    includePageTitleTag?;
    description?: string;
    banner?;
    breadcrumbs?;
}
class GeneralPage extends React.PureComponent<PropsWithChildren<Props>> {
    render() {
        let {
            title,
            pageTitle,
            includePageTitleTag,
            description,
            children,
            banner,
            router,
            breadcrumbs
        } = this.props;

        if (typeof pageTitle === "undefined") {
            pageTitle = title;
        }

        if (typeof includePageTitleTag === "undefined") {
            includePageTitleTag = true;
        }

        let finalPageTitle =
            pageTitle + (includePageTitleTag === true ? " | Convergence" : "");

        return (
            <Page>
                <Head>
                    <title>{finalPageTitle}</title>
                    <meta name="og:title" content={finalPageTitle} />
                    {description && (
                        <meta name="description" content={description} />
                    )}
                </Head>
                <Header currentRouteKey={router.query.routeKey} />
                <div id={"content"}>
                    {title && (
                        <div className={"page-title"}>
                            <Container>
                                <h2>{title}</h2>
                            </Container>
                        </div>
                    )}

                    {breadcrumbs && (
                        <div className={"page-breadcrumbs"}>
                            <Container>
                                <Breadcrumb
                                    breadcrumbs={breadcrumbs}
                                    currentRouteKey={router.query.routeKey}
                                />
                            </Container>
                        </div>
                    )}

                    {banner && banner()}

                    <Container className="is-content-container">
                        {children}
                    </Container>
                </div>
                <Footer
                    // @ts-ignore POSSIBLE ERROR FOUND: className does not exist
                    className="bottom"
                />
                <style jsx global>{`
                    .page-title {
                        background-color: ${theme.colour.gray100};
                    }
                    .page-title + .page-breadcrumbs {
                        border-top: 1px solid ${theme.colour.gray200};
                    }
                    .page-title h2 {
                        font-size: 1.5rem;
                        padding: 1rem 0;
                        margin-bottom: 0;
                        -webkit-font-smoothing: antialiased;
                    }
                    .page-breadcrumbs {
                        position: sticky;
                        top: 60px;
                        z-index: 50;
                        font-size: 0.8rem;
                        padding: 0.5rem 0;
                        border-bottom: 1px solid ${theme.colour.gray200};
                        background-color: ${theme.colour.white};
                        overflow-y: hidden;
                        white-space: nowrap;
                    }
                    #content {
                        margin-top: -90px;
                    }
                    #content > div:first-child {
                        padding-top: 90px;
                    }
                `}</style>
            </Page>
        );
    }
}

export default withRouter(GeneralPage);
