/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import flatten from "lodash.flatten";
import {
    DEAL_STATUS_ACTIVE,
    DEAL_STATUS_DECLINED,
    DEAL_STATUS_DRAFT,
    DEAL_STATUS_EXPIRED,
    DEAL_STATUS_HISTORICAL,
    DEAL_STATUS_PENDING_APPROVAL,
    DESIGN_FUNDING_STATUS_APPLICATION_WITHDRAWN,
    DESIGN_FUNDING_STATUS_LABELS,
    DESIGN_FUNDING_STATUS_NOT_SELECTED_FOR_FUNDING,
    DESIGN_FUNDING_STATUS_SELECTED_FOR_FUNDING,
    ELIGIBILITY,
    ELIGIBILITY_GROUP_0,
    ELIGIBLE_COUNTRIES_AND_STATES,
    ELIGIBLE_PACKAGES,
    IMPACT_OBJECTIVES,
    INSTRUMENTS_AND_SUB_INSTRUMENTS,
    PERMISSION_EDIT,
    REGION_COUNTRIES_STATES,
    SECTORS_AND_SUB_SECTORS,
    TERMS_AND_CONDITIONS,
    DEAL_STATUS_CANCELLED,
    USER_STATUS_ACTIVE,
    INSTITUTION_STATUS_APPROVED
} from "../constants";
import chroma from "chroma-js";

/**
 * Converts a single dimension array to a 2D array
 * @param {[]} data
 * @returns {[]}
 */
export const make2DArray = (data) => {
    return data.map((row) => [row, row]);
};

/**
 * Checks to see if a user has the appropriate permissions to edit a deal.
 * @param {Object} deal
 * @param {Object} user
 * @param {Object} authorizationService
 */
export const checkEditDealPermissions = (deal, user, authorizationService) => {
    // Ensure we have all of the following
    if (!user || !authorizationService) {
        console.debug("[checkEditDealPermissions] Permission check (1)");
        return false;
    }

    // Must have edit permission
    if (!authorizationService.hasPermission("deal", PERMISSION_EDIT)) {
        console.debug("[checkEditDealPermissions] Permission check (2)");
        return false;
    }

    // Must be associated with an institution
    if (!user.institution_id) {
        console.debug("[checkEditDealPermissions] Permission check (3)");
        return false;
    }

    // Must either be a deal owner or an institution admin
    if (deal && deal.id) {
        if (
            deal.owner_id !== user.id &&
            !user.roles.includes("ROLE_INSTITUTION_ADMIN")
        ) {
            console.debug("[checkEditDealPermissions] Permission check (5)");
            return false;
        }

        if (deal.institution.id !== user.institution_id) {
            console.debug("[checkEditDealPermissions] Permission check (6)");
            return false;
        }
    }

    return true;
};

/**
 * Gets the input for a matched deals GraphQL query.
 * @param user
 */
export const getMatchedDealsInput = (user) => {
    let preferences = user && user.preferences ? user.preferences : {};
    let input: {
        investment_instruments?: string[];
        deal_types?;
        regions?;
        sectors?;
        impact_themes?;
    } = {};

    if (
        preferences.investment_instruments &&
        preferences.investment_instruments.length > 0
    ) {
        input.investment_instruments = preferences.investment_instruments;
    }

    if (preferences.deal_types && preferences.deal_types.length > 0) {
        input.deal_types = preferences.deal_types;
    }

    if (preferences.regions_v2 && preferences.regions_v2.length > 0) {
        input.regions = preferences.regions_v2;
    }

    if (preferences.sectors && preferences.sectors.length > 0) {
        input.sectors = preferences.sectors;
    }

    if (preferences.impact_themes && preferences.impact_themes.length > 0) {
        input.impact_themes = preferences.impact_themes;
    }

    return input;
};

/**
 * Converts graphql filters to local filters
 * @param graphQLFilters
 */
export const fromGraphQLFilters = (graphQLFilters) => {
    let filters = {};

    for (let filterKey in graphQLFilters) {
        if (Object.prototype.hasOwnProperty.call(graphQLFilters, filterKey)) {
            let filterValue = graphQLFilters[filterKey];
            switch (filterKey) {
                case "sectors": {
                    filterValue.map((sector) => {
                        if (!filters["sectorsAndSubSectors"]) {
                            filters["sectorsAndSubSectors"] = {};
                        }

                        if (
                            !filters["sectorsAndSubSectors"] ||
                            (filters["sectorsAndSubSectors"] &&
                                !filters["sectorsAndSubSectors"][sector])
                        ) {
                            filters["sectorsAndSubSectors"][sector] = [];
                        }
                    });
                    break;
                }
                case "sub_sectors": {
                    filterValue.map((sub_sector) => {
                        if (!filters["sectorsAndSubSectors"]) {
                            filters["sectorsAndSubSectors"] = {};
                        }

                        let sector = SECTORS_AND_SUB_SECTORS[sub_sector];
                        if (sector) {
                            if (filters["sectorsAndSubSectors"][sector]) {
                                filters["sectorsAndSubSectors"][sector].push(
                                    sub_sector
                                );
                            } else {
                                filters["sectorsAndSubSectors"][sector] = [
                                    sub_sector
                                ];
                            }
                        }
                    });
                    break;
                }
                case "regions": {
                    filterValue.map((region) => {
                        if (!filters["regionsAndCountries"]) {
                            filters["regionsAndCountries"] = {};
                        }

                        if (
                            !filters["regionsAndCountries"] ||
                            (filters["regionsAndCountries"] &&
                                !filters["regionsAndCountries"][region])
                        ) {
                            filters["regionsAndCountries"][region] = [];
                        }
                    });
                    break;
                }
                case "countries": {
                    filterValue.map((country) => {
                        let findCountry = Object.values(
                            REGION_COUNTRIES_STATES
                        ).find((row) => row.country === country);
                        if (findCountry) {
                            if (!filters["regionsAndCountries"]) {
                                filters["regionsAndCountries"] = {};
                            }

                            if (
                                !filters["regionsAndCountries"][
                                    findCountry.region
                                ]
                            ) {
                                filters["regionsAndCountries"][
                                    findCountry.region
                                ] = [];
                            }

                            filters["regionsAndCountries"][
                                findCountry.region
                            ].push(findCountry.country);
                        }
                    });
                    break;
                }
                case "impactThemes": {
                    filterValue.map((theme) => {
                        if (!filters["impactThemesAndObjectives"]) {
                            filters["impactThemesAndObjectives"] = {};
                        }

                        if (
                            !filters["impactThemesAndObjectives"] ||
                            (filters["impactThemesAndObjectives"] &&
                                !filters["impactThemesAndObjectives"][theme])
                        ) {
                            filters["impactThemesAndObjectives"][theme] = [];
                        }
                    });
                    break;
                }
                case "impactObjectives": {
                    filterValue.map((objective) => {
                        if (!filters["impactThemesAndObjectives"]) {
                            filters["impactThemesAndObjectives"] = {};
                        }

                        let theme = IMPACT_OBJECTIVES[objective];
                        if (theme) {
                            if (filters["impactThemesAndObjectives"][theme]) {
                                filters["impactThemesAndObjectives"][
                                    theme
                                ].push(objective);
                            } else {
                                filters["impactThemesAndObjectives"][theme] = [
                                    objective
                                ];
                            }
                        }
                    });
                    break;
                }
                case "investmentInstruments": {
                    filterValue.map((instrument) => {
                        if (
                            !filters["investmentInstrumentsAndSubInstruments"]
                        ) {
                            filters[
                                "investmentInstrumentsAndSubInstruments"
                            ] = {};
                        }

                        if (
                            !filters[
                                "investmentInstrumentsAndSubInstruments"
                            ] ||
                            (filters[
                                "investmentInstrumentsAndSubInstruments"
                            ] &&
                                !filters[
                                    "investmentInstrumentsAndSubInstruments"
                                ][instrument])
                        ) {
                            filters["investmentInstrumentsAndSubInstruments"][
                                instrument
                            ] = [];
                        }
                    });
                    break;
                }
                case "investmentSubInstruments": {
                    filterValue.map((sub_instrument) => {
                        if (
                            !filters["investmentInstrumentsAndSubInstruments"]
                        ) {
                            filters[
                                "investmentInstrumentsAndSubInstruments"
                            ] = {};
                        }

                        let instrument =
                            INSTRUMENTS_AND_SUB_INSTRUMENTS[sub_instrument];
                        if (instrument) {
                            if (
                                filters[
                                    "investmentInstrumentsAndSubInstruments"
                                ][instrument]
                            ) {
                                filters[
                                    "investmentInstrumentsAndSubInstruments"
                                ][instrument].push(sub_instrument);
                            } else {
                                filters[
                                    "investmentInstrumentsAndSubInstruments"
                                ][instrument] = [sub_instrument];
                            }
                        }
                    });
                    break;
                }
                default: {
                    filters[filterKey] = filterValue;
                }
            }
        }
    }
    return filters;
};

/**
 * Converts local filters to GraphQL compatible filters.
 * @param filters
 */
export const toGraphQLFilters = (filters) => {
    let graphQLFilters = {};

    for (let filterKey in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, filterKey)) {
            let filterValue = filters[filterKey];
            switch (filterKey) {
                case "deploysCapital": {
                    if (filterValue.length > 0) {
                        graphQLFilters["deploysCapital"] = filterValue.includes(
                            "Yes"
                        )
                            ? true
                            : false;
                    } else {
                        delete graphQLFilters["deploysCapital"];
                    }
                    break;
                }
                case "sectorsAndSubSectors": {
                    let sectors = Object.keys(filterValue);
                    let subSectors = flatten(Object.values(filterValue));

                    if (subSectors.length > 0) {
                        graphQLFilters["subSectors"] = subSectors;
                    } else if (sectors.length > 0) {
                        graphQLFilters["sectors"] = sectors;
                    }
                    break;
                }
                case "institutionTypesAndSubTypes": {
                    let institution_types = Object.keys(filterValue);
                    let institution_sub_types = flatten(
                        Object.values(filterValue)
                    );

                    if (institution_sub_types.length > 0) {
                        graphQLFilters["subTypes"] = institution_sub_types;
                    } else if (institution_types.length > 0) {
                        graphQLFilters["types"] = institution_types;
                    }
                    break;
                }
                case "dealSponsorTypeAndSubTypes": {
                    let institution_types = Object.keys(filterValue);
                    let institution_sub_types = flatten(
                        Object.values(filterValue)
                    );

                    if (institution_sub_types.length > 0) {
                        graphQLFilters[
                            "dealSponsorSubTypes"
                        ] = institution_sub_types;
                    } else if (institution_types.length > 0) {
                        graphQLFilters["dealSponsorTypes"] = institution_types;
                    }
                    break;
                }
                case "historicalDealSponsorTypeAndSubTypes": {
                    let institution_types = Object.keys(filterValue);
                    let institution_sub_types = flatten(
                        Object.values(filterValue)
                    );

                    if (institution_sub_types.length > 0) {
                        graphQLFilters[
                            "historicalDealSponsorSubTypes"
                        ] = institution_sub_types;
                    } else if (institution_types.length > 0) {
                        graphQLFilters[
                            "historicalDealSponsorTypes"
                        ] = institution_types;
                    }
                    break;
                }
                case "historicalInvestorTypeAndSubTypes": {
                    let institution_types = Object.keys(filterValue);
                    let institution_sub_types = flatten(
                        Object.values(filterValue)
                    );

                    if (institution_sub_types.length > 0) {
                        graphQLFilters[
                            "historicalInvestorSubTypes"
                        ] = institution_sub_types;
                    } else if (institution_types.length > 0) {
                        graphQLFilters[
                            "historicalInvestorTypes"
                        ] = institution_types;
                    }
                    break;
                }
                case "historicalFundManagerTypeAndSubTypes": {
                    let institution_types = Object.keys(filterValue);
                    let institution_sub_types = flatten(
                        Object.values(filterValue)
                    );

                    if (institution_sub_types.length > 0) {
                        graphQLFilters[
                            "historicalFundManagerSubTypes"
                        ] = institution_sub_types;
                    } else if (institution_types.length > 0) {
                        graphQLFilters[
                            "historicalFundManagerTypes"
                        ] = institution_types;
                    }
                    break;
                }
                case "domiciledCountries": {
                    let domiciled_countries = Object.keys(filterValue);
                    let domiciled_states = flatten(Object.values(filterValue));

                    if (domiciled_countries.length > 0) {
                        graphQLFilters[
                            "domiciledCountries"
                        ] = domiciled_countries;
                    }
                    if (domiciled_states.length > 0) {
                        graphQLFilters["domiciledStates"] = domiciled_states;
                    }
                    break;
                }
                case "regionsAndCountries": {
                    let regions = Object.keys(filterValue);
                    let countries = flatten(Object.values(filterValue));

                    if (countries.length > 0) {
                        graphQLFilters["countries"] = countries;
                    } else if (regions.length > 0) {
                        graphQLFilters["regions"] = regions;
                    }
                    break;
                }
                case "impactThemesAndObjectives": {
                    let impactThemes = Object.keys(filterValue);
                    let impactObjectives = flatten(Object.values(filterValue));

                    if (impactObjectives.length > 0) {
                        graphQLFilters["impactObjectives"] = impactObjectives;
                    } else if (impactThemes.length > 0) {
                        graphQLFilters["impactThemes"] = impactThemes;
                    }
                    break;
                }
                case "investmentInstrumentsAndSubInstruments": {
                    let investmentInstruments = Object.keys(filterValue);
                    let investmentSubInstruments = flatten(
                        Object.values(filterValue)
                    );
                    let investmentInstrumentsToFilter = investmentSubInstruments.map(
                        (sub_instrument) => {
                            return INSTRUMENTS_AND_SUB_INSTRUMENTS[
                                sub_instrument
                            ];
                        }
                    );

                    if (investmentSubInstruments.length > 0) {
                        graphQLFilters[
                            "investmentSubInstruments"
                        ] = investmentSubInstruments;
                        const filteredInstruments = investmentInstruments.filter(
                            (instrument) => {
                                return !investmentInstrumentsToFilter.includes(
                                    instrument
                                );
                            }
                        );
                        graphQLFilters[
                            "investmentInstruments"
                        ] = filteredInstruments;
                    } else if (investmentInstruments.length > 0) {
                        graphQLFilters[
                            "investmentInstruments"
                        ] = investmentInstruments;
                    }
                    break;
                }
                case "historicalInvestors": {
                    if (typeof filterValue === "object") {
                        if (filterValue.id) {
                            graphQLFilters[filterKey] = [filterValue.id];
                        }
                    } else if (Array.isArray(filterValue)) {
                        graphQLFilters[filterKey] = filterValue.map(
                            (row) => row.id
                        );
                    }
                    break;
                }
                case "minMaxDealSize": {
                    if (Array.isArray(filterValue)) {
                        if (filterValue[0]) {
                            graphQLFilters["minDealSize"] = parseInt(
                                filterValue[0],
                                10
                            );
                        }
                        if (filterValue[1]) {
                            graphQLFilters["maxDealSize"] = parseInt(
                                filterValue[1],
                                10
                            );
                        }
                    }
                    if (
                        graphQLFilters["minMaxDealSize"] &&
                        graphQLFilters["minMaxDealSize"].length > 1 &&
                        graphQLFilters["minMaxDealSize"][0] === "" &&
                        graphQLFilters["minMaxDealSize"][1] === ""
                    ) {
                        graphQLFilters["minMaxDealSize"] = [];
                    }
                    break;
                }
                case "minMaxLaunchYear": {
                    if (Array.isArray(filterValue)) {
                        if (filterValue[0]) {
                            graphQLFilters["minLaunchYear"] = parseInt(
                                filterValue[0],
                                10
                            );
                        }
                        if (filterValue[1]) {
                            graphQLFilters["maxLaunchYear"] = parseInt(
                                filterValue[1],
                                10
                            );
                        }
                    }
                    if (
                        graphQLFilters["minMaxLaunchYear"] &&
                        graphQLFilters["minMaxLaunchYear"].length > 1 &&
                        graphQLFilters["minMaxLaunchYear"][0] === "" &&
                        graphQLFilters["minMaxLaunchYear"][1] === ""
                    ) {
                        graphQLFilters["minMaxLaunchYear"] = [];
                    }
                    break;
                }
                case "minMaxCloseYear": {
                    if (Array.isArray(filterValue)) {
                        if (filterValue[0]) {
                            graphQLFilters["minCloseYear"] = parseInt(
                                filterValue[0],
                                10
                            );
                        }
                        if (filterValue[1]) {
                            graphQLFilters["maxCloseYear"] = parseInt(
                                filterValue[1],
                                10
                            );
                        }
                    }
                    if (
                        graphQLFilters["minMaxCloseYear"] &&
                        graphQLFilters["minMaxCloseYear"].length > 1 &&
                        graphQLFilters["minMaxCloseYear"][0] === "" &&
                        graphQLFilters["minMaxCloseYear"][1] === ""
                    ) {
                        graphQLFilters["minMaxCloseYear"] = [];
                    }
                    break;
                }
                default: {
                    graphQLFilters[filterKey] = filterValue;
                }
            }
        }
    }

    return graphQLFilters;
};

/**
 * Converts a local archetype string to a Salesforce archetype.
 * @param {String[]} archeTypes A local archetype string
 */
export const toSalesforceArchetypes = (archeTypes) => {
    const mapping = {
        "Public or philanthropic investors are concessional within the capital structure":
            "Concessional Capital",
        "Public or philanthropic investors provide guarantees or insurance":
            "Guarantee / Risk Insurance",
        "Transaction is associated with a grant-funded technical assistance facility":
            "Technical Assistance Funds",
        "Transaction design or preparation is grant funded":
            "Design-Stage Grant"
    };

    return typeof mapping[archeTypes] !== "undefined"
        ? mapping[archeTypes]
        : archeTypes;
};

/**
 * Converts a data type from DB to Contentful values if applicable.
 * @param {string} type
 * @param {any} data
 */
export const convertToContentful = (type, data) => {
    if (type === "blending_approach" && Array.isArray(data)) {
        const mapping = {
            "Public or philanthropic investors are concessional within the capital structure":
                "Concessional Capital",
            "Public or philanthropic investors provide guarantees or insurance":
                "Guarantee / Risk Insurance",
            "Transaction is associated with a grant-funded technical assistance facility":
                "Technical Assistance Funds",
            "Transaction design or preparation is grant funded":
                "Design-Stage Grant"
        };
        return data.map((approach) =>
            typeof mapping[approach] !== "undefined"
                ? mapping[approach]
                : approach
        );
    }
    if (type === "regions" && Array.isArray(data)) {
        return data.map((region) =>
            region.replace(
                "Latin America and the Caribbean",
                "Latin America & the Caribbean"
            )
        );
    }
    return data;
};

/**
 * Converts local filters to contentful filters
 * @param filters
 */
export const toContentfulFilters = (filters) => {
    let options = {};

    for (let filterKey in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, filterKey)) {
            let filterValue = filters[filterKey];

            if (Array.isArray(filterValue) && filterValue.length <= 0) {
                continue;
            } else if (
                !Array.isArray(filterValue) &&
                Object.keys(filterValue).length <= 0
            ) {
                continue;
            }

            switch (filterKey) {
                case "impact_themes": {
                    options[
                        "fields.themeFocus[match]"
                    ] = filterValue
                        .map((value) => value.replace(/,/g, ";"))
                        .join(",");
                    break;
                }
                case "sectors": {
                    options["fields.sectorFocus[in]"] = filterValue.join(",");
                    break;
                }
                case "knowledge_type": {
                    options["fields.type[in]"] = filterValue.join(",");
                    break;
                }
                case "sectors_and_sub_sectors": {
                    let sectors = [
                        ...new Set([
                            ...Object.values(
                                SECTORS_AND_SUB_SECTORS
                            ).filter((sector) => filterValue.includes(sector))
                        ])
                    ];
                    let subSectors = Object.keys(
                        SECTORS_AND_SUB_SECTORS
                    ).filter((subSector) => filterValue.includes(subSector));
                    if (subSectors.length > 0) {
                        options["fields.subSectorFocus[in]"] = subSectors.join(
                            ","
                        );
                    } else if (sectors.length > 0) {
                        options["fields.sectorFocus[in]"] = sectors.join(",");
                    }

                    break;
                }
                case "regions": {
                    options[
                        "fields.regionFocus[in]"
                    ] = convertToContentful("regions", [
                        ...getSubRegionsByRegions(filterValue),
                        ...filterValue
                    ]).join(",");
                    break;
                }
                case "designFundingWindow": {
                    options[
                        "fields.designFundingWindow.sys.contentType.sys.id"
                    ] = "designFundingWindow";
                    options[
                        "fields.designFundingWindow.fields.windowName[in]"
                    ] = convertToContentful("windowName", filterValue).join(
                        ","
                    );
                    break;
                }
                default: {
                    if (
                        filterKey.startsWith("fields.") ||
                        filterKey.startsWith("metadata.")
                    ) {
                        options[filterKey] = Array.isArray(filterValue)
                            ? filterValue.join(",")
                            : filterValue;
                    } else {
                        options[`fields.${filterKey}[in]`] = filterValue.join(
                            ","
                        );
                    }
                    break;
                }
            }
        }
    }

    return options;
};

/**
 * Combine separated investment instruments and sub-instruments into a single object.
 * @param instruments
 * @param sub_instruments
 * @returns {{}}
 */
export const createInvestmentInstrumentsAndSubInstrumentsObject = (
    instruments,
    sub_instruments
) => {
    let instrumentsAndSubInstruments = {};

    if (!instruments) {
        return {};
    }

    for (let instrument of instruments) {
        instrumentsAndSubInstruments[instrument] = [];
    }

    if (sub_instruments) {
        for (let subInstrument of sub_instruments) {
            let findInstrument = INSTRUMENTS_AND_SUB_INSTRUMENTS[subInstrument]
                ? INSTRUMENTS_AND_SUB_INSTRUMENTS[subInstrument]
                : null;
            if (
                findInstrument &&
                typeof instrumentsAndSubInstruments[
                    INSTRUMENTS_AND_SUB_INSTRUMENTS[subInstrument]
                ] !== "undefined"
            ) {
                instrumentsAndSubInstruments[
                    INSTRUMENTS_AND_SUB_INSTRUMENTS[subInstrument]
                ].push(subInstrument);
            }
        }
    }

    return instrumentsAndSubInstruments;
};

/**
 * Combine separated impact themes and objectives into a single object.
 * @param themes
 * @param objectives
 * @returns {{}}
 */
export const createImpactThemesAndObjectivesObject = (themes, objectives) => {
    let impactThemesAndObjectives = {};

    if (!themes) {
        return {};
    }

    for (let impactTheme of themes) {
        impactThemesAndObjectives[impactTheme] = [];
    }

    if (objectives) {
        for (let impactObjective of objectives) {
            let findImpactTheme = IMPACT_OBJECTIVES[impactObjective]
                ? IMPACT_OBJECTIVES[impactObjective]
                : null;
            if (
                findImpactTheme &&
                typeof impactThemesAndObjectives[
                    IMPACT_OBJECTIVES[impactObjective]
                ] !== "undefined"
            ) {
                impactThemesAndObjectives[
                    IMPACT_OBJECTIVES[impactObjective]
                ].push(impactObjective);
            }
        }
    }

    return impactThemesAndObjectives;
};

/**
 * Combine separated regions and countries into a single object.
 * @param regions
 * @param countries
 * @returns {{}}
 */
export const createRegionsAndCountriesObject = (regions, countries) => {
    let regionsAndCountries = {};

    if (!regions) {
        return {};
    }

    for (let region of regions) {
        // Skip Global as we don't want it as a selectable region.
        if (region === "Global") {
            continue;
        }
        regionsAndCountries[region] = [];
    }

    if (countries) {
        for (let country of countries) {
            let findCountry = Object.values(REGION_COUNTRIES_STATES).find(
                (row) => row.country === country
            );
            if (
                findCountry &&
                typeof regionsAndCountries[findCountry.region] !== "undefined"
            ) {
                regionsAndCountries[findCountry.region].push(
                    findCountry.country
                );
            }
        }
    }

    return regionsAndCountries;
};

/**
 * Combine separated sectors and sub-sectors into a single object.
 * @param sectors
 * @param subSectors
 * @returns {{}}
 */
export const createSectorsAndSubSectorsObject = (sectors, subSectors) => {
    let sectorsAndSubSectors = {};

    if (!sectors) {
        return {};
    }

    for (let sector of sectors) {
        sectorsAndSubSectors[sector] = [];
    }

    if (subSectors) {
        for (let subSector of subSectors) {
            let findSector = SECTORS_AND_SUB_SECTORS[subSector]
                ? SECTORS_AND_SUB_SECTORS[subSector]
                : null;
            if (
                findSector &&
                typeof sectorsAndSubSectors[
                    SECTORS_AND_SUB_SECTORS[subSector]
                ] !== "undefined"
            ) {
                sectorsAndSubSectors[SECTORS_AND_SUB_SECTORS[subSector]].push(
                    subSector
                );
                sectorsAndSubSectors[SECTORS_AND_SUB_SECTORS[subSector]].sort();
            }
        }
    }

    return sectorsAndSubSectors;
};

/**
 * Human fileSize
 * @param size
 * @returns {string}
 */
export const humanFileSize = (size) => {
    let i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (
        // TS CODE CHANGE remove "* 1". can't do string * 1
        (size / Math.pow(1024, i)).toFixed(2) +
        " " +
        ["B", "kB", "MB", "GB", "TB"][i]
    );
};

/**
 * Strips out any commas from a string rep. number and converts to integer.
 * @param number
 * @returns {number}
 */
export const convertStringNumberToInteger = (number) => {
    return typeof number === "string"
        ? parseInt(number.replace(/,/g, ""))
        : parseInt(number);
};

/**
 * Strips out any commans from a string rep. number and converts to float.
 * @param number
 * @returns {number}
 */
export const convertStringNumberToFloat = (number) => {
    return typeof number === "string"
        ? parseFloat(number.replace(/,/, ""))
        : parseFloat(number);
};

/**
 * Converts a numeric design funding application status to a string (label).
 * @param designFundingApplicationStatus
 * @returns {*}
 */
export const convertDesignFundingApplicationStatusToLabel = (
    designFundingApplicationStatus
) => {
    return DESIGN_FUNDING_STATUS_LABELS[designFundingApplicationStatus]
        ? DESIGN_FUNDING_STATUS_LABELS[designFundingApplicationStatus]
        : designFundingApplicationStatus.toString();
};

/**
 * Gets a colour for the badge
 * @param designFundingApplicationStatus
 * @returns {string}
 */
export const convertDesignFundingApplicationStatusToBadge = (
    designFundingApplicationStatus
) => {
    if (
        designFundingApplicationStatus ===
        DESIGN_FUNDING_STATUS_SELECTED_FOR_FUNDING
    ) {
        return "success";
    }

    if (
        designFundingApplicationStatus ===
        DESIGN_FUNDING_STATUS_NOT_SELECTED_FOR_FUNDING
    ) {
        return "danger";
    }

    if (
        designFundingApplicationStatus ===
        DESIGN_FUNDING_STATUS_APPLICATION_WITHDRAWN
    ) {
        return "light";
    }

    return "warning";
};

/**
 * Gets a colour for the badge
 * @param windowName
 * @returns string
 */
export const getWindowBadgeColour = (windowName: string) => {
    switch (windowName) {
        case "Global Emerging Markets Design Funding Window":
            return "badge-primary";
        case "Asia Natural Capital Design Funding Window":
            return "badge-warning";
        case "Indo-Pacific Design Funding Window":
            return "badge-info";
        case "Gender-Responsive Climate Finance Window":
            return "badge-success";
        default:
            return "badge-primary";
    }
};

/**
 * Convert deal status
 * @param dealStatus
 * @returns {*}
 */
export const convertDealStatusToLabel = (dealStatus) => {
    switch (dealStatus) {
        case DEAL_STATUS_DRAFT: {
            return "Draft";
        }
        case DEAL_STATUS_DECLINED: {
            return "Declined";
        }
        case DEAL_STATUS_HISTORICAL: {
            return "Historical";
        }
        case DEAL_STATUS_PENDING_APPROVAL: {
            return "Pending Approval";
        }
        case DEAL_STATUS_ACTIVE: {
            return "Fundraising";
        }
        case DEAL_STATUS_EXPIRED: {
            return "Expired";
        }
        case DEAL_STATUS_CANCELLED: {
            return "Cancelled";
        }
        default:
            return dealStatus.toString();
    }
};

/**
 * Converts a word/sentence to a class acceptable calss
 * @param word
 */
export const convertToCSSClass = (word) => {
    return word.replace(/[^\w]+/, "-");
};

/**
 * Convert array to object
 * @param myArray
 * @returns {{}}
 */
export const convertArrayToObject = (myArray) => {
    let myObject = {};
    for (let key of myArray) {
        myObject[key] = {};
    }
    return myObject;
};

/**
 * Converts a number from the millions of units.
 * @param number
 * @returns {number}
 */
export const convertToMillions = (number) => Math.round(number / 1000000);

/**
 * Converts a number to a human readable string.
 * @param {integer} number
 */
export const convertToHumanReadableString = (number) => {
    const absValueNumber = Math.abs(Number(number));
    const trillions = 1.0e12;
    const billions = 1.0e9;
    const millions = 1.0e6;
    const thousands = 1.0e3;
    if (absValueNumber >= trillions) {
        return Math.round(absValueNumber / trillions).toString() + "T";
    } else if (absValueNumber >= billions) {
        return Math.round(absValueNumber / billions).toString() + "B";
    } else if (absValueNumber >= millions) {
        return Math.round(absValueNumber / millions).toString() + "M";
    } else if (absValueNumber >= thousands) {
        return Math.round(absValueNumber / thousands).toString() + "K";
    } else {
        return absValueNumber.toString();
    }
};

/**
 * Converts a terms of condition key to label
 * @param toc
 */
export const convertToCToLabel = (toc) => {
    return TERMS_AND_CONDITIONS[toc] ? TERMS_AND_CONDITIONS[toc] : "";
};

/**
 * Assumes valid emails and returns the domain of the email.
 * @param email_address
 * @returns {null}
 */
export const getDomainFromEmail = (email_address) => {
    return email_address.indexOf("@") !== -1
        ? email_address.split("@")[1]
        : null;
};

/**
 * Gets an eligibility package by ID
 * @param id
 * @returns {*}
 */
export const getEligiblePackageById = (id) => {
    const pkg = ELIGIBLE_PACKAGES.find(
        (singlePackage) => singlePackage.id === id
    );
    if (pkg) {
        return pkg;
    }

    return null;
};

/**
 * Gets the eligible package.
 * @param institution
 * @param roles
 * @returns {*}
 */
export const getEligiblePackage = (institution, roles) => {
    if (!institution) {
        console.debug("Invalid institution provided.");
        return getEligiblePackageById("e3043ab7-493f-4ef2-93f5-74d5a0a8e5a8");
    }

    let eligiblePackage = null;
    const institution_type = institution.institution_type;
    const institution_sub_type = institution.institution_sub_type;

    for (let pkg of ELIGIBLE_PACKAGES) {
        let passesRequirements = false;
        let requirements = pkg.requirements;

        if (requirements.roles.every((role) => roles.includes(role))) {
            passesRequirements = true;
        }

        if (
            passesRequirements === true &&
            requirements.institution_type !== null
        ) {
            passesRequirements = requirements.institution_type(
                institution_type
            );
        }

        if (
            passesRequirements === true &&
            requirements.institution_sub_type !== null
        ) {
            passesRequirements = requirements.institution_sub_type(
                institution_sub_type
            );
        }

        if (passesRequirements === true) {
            eligiblePackage = pkg;
            break;
        }
    }

    return eligiblePackage;
};

/**
 * Returns the eligibility of the institution.
 * @param institution
 * @returns {*[]}
 */
export const getEligibility = (institution) => {
    if (!institution) {
        console.debug("Group 0 Start");
        return ELIGIBILITY[ELIGIBILITY_GROUP_0];
    }

    const invests_or_provides_funding = institution.invests_or_provides_funding;
    const types_of_capital = institution.types_of_capital;
    const domiciled_country = institution.domiciled_country;
    const domiciled_state = institution.domiciled_state;
    const institution_type = institution.institution_type;
    const is_fundraising = institution.is_fundraising_for_bf_transaction;

    let selectedGroup = ELIGIBILITY_GROUP_0;

    for (const groupKey in ELIGIBILITY) {
        if (Object.prototype.hasOwnProperty.call(ELIGIBILITY, groupKey)) {
            let passesRequirements = false;
            let group = ELIGIBILITY[groupKey];
            let requirements = group.requirements;

            // Invests or provides funding?
            if (requirements.invests_or_provides_funding !== null) {
                passesRequirements =
                    requirements.invests_or_provides_funding ===
                    invests_or_provides_funding;
            }

            // Capital types
            if (
                passesRequirements === true &&
                requirements.capital_types !== null
            ) {
                passesRequirements = requirements.capital_types(
                    types_of_capital
                );
            }

            // Eligible country
            if (
                passesRequirements === true &&
                requirements.eligible_country !== null
            ) {
                passesRequirements =
                    requirements.eligible_country ===
                    isEligibleCountry(domiciled_country, domiciled_state);
            }

            // Institution types
            if (
                passesRequirements === true &&
                requirements.institution_type !== null
            ) {
                passesRequirements = requirements.institution_type(
                    institution_type
                );
            }
            // Fundraising
            if (
                passesRequirements === true &&
                requirements.is_fundraising !== null
            ) {
                passesRequirements =
                    requirements.is_fundraising === is_fundraising;
            }

            if (passesRequirements === true) {
                selectedGroup = groupKey;
                break;
            }
        }
    }

    console.debug("> Debug: selected group - " + selectedGroup);

    return ELIGIBILITY[selectedGroup];
};

/**
 * Get's an array of all the impact themes.
 * @returns {any[]}
 */
export const getAllImpactThemes = () => {
    return Object.values(IMPACT_OBJECTIVES).filter(
        (v, i, a) => a.indexOf(v) === i && v !== null
    );
};

/**
 * Returns a list of impact objectives based on the provided theme
 * @param themes
 * @returns {{}}
 */
export const getImpactObjectivesByTheme = (themes) => {
    let listOfImpactObjectives = {};
    for (let impactObjective in IMPACT_OBJECTIVES) {
        if (
            Object.prototype.hasOwnProperty.call(
                IMPACT_OBJECTIVES,
                impactObjective
            )
        ) {
            const theme = IMPACT_OBJECTIVES[impactObjective];
            if (themes.indexOf(theme) === -1) {
                continue;
            }
            if (!listOfImpactObjectives[theme]) {
                listOfImpactObjectives[theme] = [];
            }
            listOfImpactObjectives[theme].push({
                value: impactObjective,
                label: impactObjective
            });
        }
    }

    return listOfImpactObjectives;
};

/**
 * Returns a list of sectors -> sub-sectors relationship object
 * @returns {{}}
 */
export const getImpactThemesAndObjectives = () => {
    let listOfImpactThemesAndObjectives = {};
    for (let impactObjective in IMPACT_OBJECTIVES) {
        if (
            Object.prototype.hasOwnProperty.call(
                IMPACT_OBJECTIVES,
                impactObjective
            )
        ) {
            const impactTheme = IMPACT_OBJECTIVES[impactObjective];
            if (!listOfImpactThemesAndObjectives[impactTheme]) {
                listOfImpactThemesAndObjectives[impactTheme] = [];
            }
            listOfImpactThemesAndObjectives[impactTheme].push(impactObjective);
        }
    }

    return listOfImpactThemesAndObjectives;
};

/**
 * Get's an array of all the sectors.
 * @returns {any[]}
 */
export const getAllSectors = () => {
    return Object.values(SECTORS_AND_SUB_SECTORS)
        .filter((v, i, a) => a.indexOf(v) === i && v !== null)
        .sort();
};

/**
 * Gets an array of all the sub sectors.
 * @returns {any[]}
 */
export const getAllSubSectors = () => {
    return Object.keys(SECTORS_AND_SUB_SECTORS)
        .filter((v, i, a) => a.indexOf(v) === i && v !== null)
        .sort();
};

/**
 * Returns a list of sub-sectors based on the provided sectors
 * @param sectors
 * @returns {{}}
 */
export const getSubSectorsBySectors = (sectors) => {
    let listOfSubSectors = {};
    for (let subSector in SECTORS_AND_SUB_SECTORS) {
        if (
            Object.prototype.hasOwnProperty.call(
                SECTORS_AND_SUB_SECTORS,
                subSector
            )
        ) {
            const sector = SECTORS_AND_SUB_SECTORS[subSector];
            if (sectors.indexOf(sector) === -1) {
                continue;
            }
            if (!listOfSubSectors[sector]) {
                listOfSubSectors[sector] = [];
            }
            listOfSubSectors[sector].push({
                value: subSector,
                label: subSector
            });

            listOfSubSectors[sector].sort();
        }
    }

    return listOfSubSectors;
};

/**
 * Returns a list of sectors -> sub-sectors relationship object
 * @returns {{}}
 */
export const getSectorsAndSubSectors = () => {
    let listOfSubSectors = {};
    for (let subSector in SECTORS_AND_SUB_SECTORS) {
        if (
            Object.prototype.hasOwnProperty.call(
                SECTORS_AND_SUB_SECTORS,
                subSector
            )
        ) {
            const sector = SECTORS_AND_SUB_SECTORS[subSector];
            if (!listOfSubSectors[sector]) {
                listOfSubSectors[sector] = [];
            }
            listOfSubSectors[sector].push(subSector);
            listOfSubSectors[sector].sort();
        }
    }

    return listOfSubSectors;
};

/**
 * Get all regions
 * @returns {any[]}
 */
export const getAllInstruments = () => {
    return Object.values(INSTRUMENTS_AND_SUB_INSTRUMENTS).filter(
        (v, i, a) => a.indexOf(v) === i && v !== null
    );
};

/**
 * Get all regions
 * @returns {any[]}
 */
export const getAllSubInstruments = (selectedInstrument) => {
    let subInstruments = [];
    for (let subInstrument in INSTRUMENTS_AND_SUB_INSTRUMENTS) {
        if (
            Object.prototype.hasOwnProperty.call(
                INSTRUMENTS_AND_SUB_INSTRUMENTS,
                subInstrument
            )
        ) {
            let instrument = INSTRUMENTS_AND_SUB_INSTRUMENTS[subInstrument];
            if (instrument === selectedInstrument) {
                subInstruments.push(subInstrument);
            }
        }
    }

    return subInstruments;
};

export const getSubInstruments = (instruments) => {
    let result = {};
    for (let subInstrument in INSTRUMENTS_AND_SUB_INSTRUMENTS) {
        if (
            Object.prototype.hasOwnProperty.call(
                INSTRUMENTS_AND_SUB_INSTRUMENTS,
                subInstrument
            )
        ) {
            const instrument = INSTRUMENTS_AND_SUB_INSTRUMENTS[subInstrument];
            if (instruments.indexOf(instrument) === -1) {
                continue;
            }
            if (!result[instrument]) {
                result[instrument] = [];
            }
            result[instrument].push({
                value: subInstrument,
                label: subInstrument
            });
        }
    }

    return result;
};

/**
 * Get all investment instruments and sub instruments.
 * @returns {any[]}
 */
export const getInvestmentInstrumentsAndSubInstruments = () => {
    let listOfInstrumentsAndSubInstruments = {};
    for (let subInstrument in INSTRUMENTS_AND_SUB_INSTRUMENTS) {
        if (
            Object.prototype.hasOwnProperty.call(
                INSTRUMENTS_AND_SUB_INSTRUMENTS,
                subInstrument
            )
        ) {
            const instrument = INSTRUMENTS_AND_SUB_INSTRUMENTS[subInstrument];
            if (!listOfInstrumentsAndSubInstruments[instrument]) {
                listOfInstrumentsAndSubInstruments[instrument] = [];
            }
            listOfInstrumentsAndSubInstruments[instrument].push(subInstrument);
        }
    }

    return listOfInstrumentsAndSubInstruments;
};
export const getDomiciledCountries = () => {
    return getAllCountries().map((country) => country.label);
};

/**
 * Get all regions
 * @returns {any[]}
 */
export const getAllRegions = () => {
    return Object.values(REGION_COUNTRIES_STATES)
        .map((regions) => regions.region)
        .filter((v, i, a) => a.indexOf(v) === i && v !== null);
};

/**
 * Retrieves all unique subregions associated with a given list of regions.
 *
 * @param regions - An array of regions to search for.
 * @returns An array of unique subregion names sorted alphabetically.
 */
export function getSubRegionsByRegions(regions: string[]): string[] {
    const subregions = new Set<string>();

    Object.values(REGION_COUNTRIES_STATES).forEach((country) => {
        if (regions.includes(country.region)) {
            country.subregions.forEach((subregion) =>
                subregions.add(subregion)
            );
        }
    });

    return Array.from(subregions).sort();
}

/**
 * Gets a list of countries by regions.
 * @param regions
 * @returns {{}}
 */
export const getAllCountriesByRegions = (regions) => {
    let litOfCountries = {};
    for (let countryCode in REGION_COUNTRIES_STATES) {
        if (
            Object.prototype.hasOwnProperty.call(
                REGION_COUNTRIES_STATES,
                countryCode
            )
        ) {
            const data = REGION_COUNTRIES_STATES[countryCode];
            if (regions.indexOf(data.region) === -1) {
                continue;
            }
            if (!litOfCountries[data.region]) {
                litOfCountries[data.region] = [];
            }
            litOfCountries[data.region].push({
                value: data.country,
                label: data.country
            });
        }
    }

    return litOfCountries;
};

/**
 * Gets a list of countries (array)
 * @returns {Array}
 */
export const getAllCountriesArray = (regions?) => {
    let countries = [];

    for (const countryCode in REGION_COUNTRIES_STATES) {
        if (
            Object.prototype.hasOwnProperty.call(
                REGION_COUNTRIES_STATES,
                countryCode
            )
        ) {
            const data = REGION_COUNTRIES_STATES[countryCode];
            if (
                typeof regions !== "undefined" &&
                regions.indexOf(data.region) === -1
            ) {
                continue;
            }

            countries.push(data.country);
        }
    }

    countries.sort((a, b) => (a < b ? -1 : 1));

    return countries;
};

/**
 * Gets a list of countries.
 * @returns {Array}
 */
export const getAllCountries = (regions?) => {
    let countries = [];

    for (const countryCode in REGION_COUNTRIES_STATES) {
        if (
            Object.prototype.hasOwnProperty.call(
                REGION_COUNTRIES_STATES,
                countryCode
            )
        ) {
            const data = REGION_COUNTRIES_STATES[countryCode];
            if (
                typeof regions !== "undefined" &&
                regions.indexOf(data.region) === -1
            ) {
                continue;
            }

            countries.push({
                label: data.country,
                value: data.country
            });
        }
    }

    countries.sort((a, b) => (a.label < b.label ? -1 : 1));

    return countries;
};
export const getStatesByCountryName = (countryName) => {
    const country = Object.values(REGION_COUNTRIES_STATES).find(
        ({ country }) => country == countryName
    );
    if (!country || !country.states) {
        return null;
    }
    return country.states;
};
/**
 * Get the states for a particular country.
 * @param country
 * @returns {Array}
 */
export const getStates = (country) => {
    let countryCode = Object.keys(REGION_COUNTRIES_STATES).find(
        (code) => REGION_COUNTRIES_STATES[code].country === country
    );
    // @ts-ignore POSSIBLE ERROR FOUND: String and number have no overlap
    if (countryCode === -1) {
        return [];
    }

    if (
        Object.prototype.hasOwnProperty.call(
            REGION_COUNTRIES_STATES,
            countryCode
        )
    ) {
        const country = REGION_COUNTRIES_STATES[countryCode];
        if (country && country.states !== null) {
            let states = [];
            for (const stateCode in country.states) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        country.states,
                        stateCode
                    )
                ) {
                    states.push({
                        label: country.states[stateCode],
                        value: country.states[stateCode]
                    });
                }
            }

            return states;
        }
    }
    return [];
};

/**
 * Returns a list of sectors -> sub-sectors relationship object
 * @returns {{}}
 */
export const getRegionsAndCountries = () => {
    let litOfCountries = {};
    for (let countryCode in REGION_COUNTRIES_STATES) {
        if (
            Object.prototype.hasOwnProperty.call(
                REGION_COUNTRIES_STATES,
                countryCode
            )
        ) {
            const data = REGION_COUNTRIES_STATES[countryCode];

            if (!litOfCountries[data.region]) {
                litOfCountries[data.region] = [];
            }
            litOfCountries[data.region].push(data.country);
            litOfCountries[data.region].sort();
        }
    }

    return litOfCountries;
};

/**
 * Determines if a provided country and/or state is Eligible for deal sponsoring...
 * @param country
 * @param state
 * @returns {boolean}
 */
export const isEligibleCountry = (country, state) => {
    return !!ELIGIBLE_COUNTRIES_AND_STATES.find((eligibleCountry) => {
        if (eligibleCountry.states && eligibleCountry.states !== null) {
            return (
                eligibleCountry.country === country &&
                eligibleCountry.states.includes(state)
            );
        }
        return eligibleCountry.country === country;
    });
};

/**
 * Process general GraphQL errors and give us a message!
 * @param error
 * @returns {string}
 */
export const processError = (error) => {
    if (error.message.includes("GraphQL error")) {
        return error.message.replace(/GraphQL error:/, "");
    } else {
        console.error(error);
        return "An unknown error has occurred. Please try again.";
    }
};

/**
 * Takes the list of user's pending approval and returns a list of user IDs only
 * @param usersPendingApproval
 * @returns {Array}
 */
export const getUserIdsFromUsersPendingApproval = (usersPendingApproval) => {
    let userIds = [];
    for (const userData of usersPendingApproval) {
        userIds.push(userData.user_id);
    }
    return userIds;
};
export const compare = (a, b) => {
    if (a[Object.keys(a)[0]] > b[Object.keys(b)[0]]) {
        return -1;
    } else if (a[Object.keys(a)[0]] < b[Object.keys(b)[0]]) {
        return 1;
    }
    return 0;
};
/**
 * Converts a large number to human-readable text.
 * @param num
 * @returns {string}
 */
export const formatLargeNumber = (num, suffix = true) => {
    return num >= 1.0e9
        ? Math.round(num / 1.0e9) + (suffix ? "B" : "")
        : num >= 1.0e6
        ? Math.round(num / 1.0e6) + (suffix ? "M" : "")
        : num >= 1.0e3
        ? Math.round(num / 1.0e3) + (suffix ? "K" : "")
        : num;
};

/**
 * Converts an array list (no ID) to an options array (proper structure)
 * @param options
 * @returns {Array}
 */

export const convertArrayToOptions = (options) => {
    let reactSelectOptions = [];

    for (const item of options) {
        reactSelectOptions.push({
            value: item,
            label: item
        });
    }

    return reactSelectOptions;
};

/**
 * Converts an array list of options to a plain array
 * @param options
 * @returns {Array}
 */
export const convertOptionsToArray = (options) => {
    let list = [];

    for (const item of options) {
        list.push(item.value);
    }

    return list;
};

/**
 * Generates a mock "event.target" object.
 * @param name
 * @param type
 * @param value
 * @returns {{target: {name, type: *, value: *}}}
 */
export const createEvent = (name, type, value) => {
    return {
        target: {
            name,
            type,
            value
        },
        preventDefault: function() {
            return true;
        }
    };
};

/**
 * Get a country name by country code
 * @param countryCode
 * @returns {*}
 */
export const getCountryName = (countryCode) => {
    if (countryCode in REGION_COUNTRIES_STATES) {
        return REGION_COUNTRIES_STATES[countryCode].country;
    }
    return countryCode;
};

/**
 * Serializes an object for a URL.
 * @param object
 * @param url
 * @returns {string}
 * @constructor
 */
export const URISerializeObject = (object, url = "") => {
    for (let key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            let value = object[key];
            if (url.indexOf("?") === -1) {
                url += "?";
            }
            if (url.length > 0) {
                url += "&";
            }
            url += `${key}=${encodeURIComponent(value)}`;
        }
    }

    return url;
};

/**
 * Encodes a unicode string to a base64 string.
 * @param str
 * @returns {string}
 */
export const b64EncodeUnicode = (str) => {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.

    if (typeof window !== "undefined") {
        return btoa(
            encodeURIComponent(str).replace(
                /%([0-9A-F]{2})/g,
                function toSolidBytes(match, p1) {
                    // @ts-ignore POSSIBLE ERROR FOUND arg needs to be number[]
                    return String.fromCharCode("0x" + p1);
                }
            )
        );
    } else {
        return Buffer.from(str).toString("base64");
    }
};

/**
 * Decodes a base64 encoded string (unicode support)
 * @param str
 * @returns {string}
 */
export const b64DecodeUnicode = (str) => {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    if (typeof window !== "undefined") {
        return decodeURIComponent(
            atob(str)
                .split("")
                .map(function(c) {
                    return (
                        "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join("")
        );
    } else {
        // TS CODE CHANGE fixed UTF-8 to utf8
        return Buffer.from(str, "base64").toString("utf8");
    }
};

/**
 * Get's a parameter from the current URL (browser only)
 * @copy https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
 * @param name
 * @param url
 * @returns {*}
 */
export const getParameterByName = (name, url?) => {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * Gets the current full valid URL for the current website.
 * @returns {string}
 */
export const getClientHostname = () => {
    return (
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "")
    );
};

/**
 * Get's a chart colour variation
 * Code from: https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
 * @param increment
 * @param alpha
 * @param baseColour
 */
export const getChartColour = (
    increment = 0,
    alpha = false,
    baseColour = "#337ab7"
) => {
    let r = parseInt(baseColour.slice(1, 3), 16),
        g = parseInt(baseColour.slice(3, 5), 16),
        b = parseInt(baseColour.slice(5, 7), 16);

    if (increment > 0) {
        r = r + 10 * increment;
        g = g + 10 * increment;
        b = b + 10 * increment;
    } else {
        let random = Math.floor(Math.random() * (15 - 1 + 1)) + 1;
        r = r + 10 * random;
        g = g + 10 * random;
        b = b + 10 * random;
    }

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        // @ts-ignore POSSIBLE ERROR FOUND. can't parseInt a number
        const rHex = parseInt(r, 10).toString(16);
        // @ts-ignore POSSIBLE ERROR FOUND. can't parseInt a number
        const gHex = parseInt(g, 10).toString(16);
        // @ts-ignore POSSIBLE ERROR FOUND. can't parseInt a number
        const bHex = parseInt(b, 10).toString(16);
        return `#${rHex}${gHex}${bHex}`;
    }
};

/**
 * Cleans up a website URL in case it doesn't have a protocol
 * @param {string} url
 * @returns {string}
 */
export const cleanWebsiteURL = (url) => {
    if (!/^(?:f|ht)tps?:\/\//.test(url)) {
        url = "http://" + url;
    }
    return url;
};

/**
 * Gets the percentage of deals that have >1 values of any stat data. Keys need to be: 1, 2, 3, 5+ etc.
 */
export const percentageOfDealsWithMoreThanOneX = (obj, round = "floor") => {
    if (!obj || Object.keys(obj).length <= 0) {
        return 0;
    }

    const totalDeals = Object.keys(obj)
        .map((key) => obj[key])
        .reduce((a, b) => a + b);
    const totalDealsOver2Regions = Object.keys(obj)
        .filter((item) => item !== "1")
        .map((key) => obj[key])
        .reduce((a, b) => a + b);

    return (
        Math[round](
            Math.round((totalDealsOver2Regions / totalDeals) * 100) / 10
        ) * 10
    );
};

/**
 * Converts an object to a comma separated list.
 */
export const convertToCommandSeparatedList = (
    data,
    top = 3,
    regex = null,
    replace = null,
    skip = 0
) => {
    let keys = [];

    if (Array.isArray(data)) {
        keys = [...data]
            .sort((a, b) => b.value - a.value)
            .splice(skip, top)
            .map((value) => value.key);
    } else {
        keys = Object.keys(data)
            .sort((a, b) => data[b] - data[a])
            .splice(skip, top);
    }

    if (keys.length < top) {
        top = keys.length;
    }

    return keys.map((key, index) => {
        if (regex && replace) {
            key = key.replace(regex, replace);
        }

        if (index == top - 1) {
            return top === 1 ? key : "and " + key;
        } else {
            return top === 2 ? key + " " : key + ", ";
        }
    });
};

/**
 * Gets the SDG number from it's name (e.g. 01: SDG => 01)
 * @param {string} sdg
 */
export const getSDGNumberFromText = (sdg) => {
    return sdg.replace(/^([0-9]{2}):(.+)$/g, "$1");
};

/**
 * Gets an array of instruments applicable for a deal.
 */
export const dealStructureInstruments = (deal) => {
    let instruments = [];
    if (deal.total_debt_target > 0) {
        instruments.push("Debt");
    }
    if (deal.total_equity_target > 0) {
        instruments.push("Equity");
    }
    if (deal.total_grant_target > 0) {
        instruments.push("Grant");
    }

    return instruments;
};

export const generateDealFilterQuery = (keyword, filters, sort) => {
    let serializedFilters =
        typeof filters !== "undefined" && filters
            ? b64EncodeUnicode(JSON.stringify(filters))
            : null;
    let serializedSort =
        typeof sort !== "undefined" && sort
            ? b64EncodeUnicode(JSON.stringify(sort))
            : null;
    let serializedKeyword =
        typeof keyword !== "undefined" && keyword.length > 0
            ? b64EncodeUnicode(keyword)
            : null;

    let query: {
        keyword?: string;
        sort?;
        filters?;
    } = {};
    if (serializedKeyword) {
        query.keyword = encodeURIComponent(serializedKeyword);
    }

    if (serializedSort) {
        query.sort = encodeURIComponent(serializedSort);
    }

    if (serializedFilters) {
        query.filters = encodeURIComponent(serializedFilters);
    }

    return query;
};

export const defaultValue = (value, defaultValue) => {
    return typeof value !== "undefined" && value !== null
        ? [...value]
        : [...defaultValue];
};

export const generateRandomColourArray = (startingColour, numberOfColours) => {
    // Throw and error if an invalid colour is provided
    if (startingColour.length < 6 || startingColour.length > 7) {
        throw Error(
            "Invalid starting colour provided. Ensure it is a proper hex-decimal colour."
        );
    }
    const hexStartingColour =
        startingColour[0] === "#" ? startingColour.substr(1) : startingColour;
    const hexStarting = {
        R: hexStartingColour.substr(0, 2),
        G: hexStartingColour.substr(2, 2),
        B: hexStartingColour.substr(4, 2)
    };
    const decStarting = {
        R: parseInt(hexStarting.R, 16),
        G: parseInt(hexStarting.G, 16),
        B: parseInt(hexStarting.B, 16)
    };

    let newColours = {};

    for (let i = 0; i <= numberOfColours; i++) {
        const rLower = Math.round(decStarting.R * 0.5);
        const gLower = Math.round(decStarting.G * 0.5);

        const R = (rLower + Math.round(Math.random() * decStarting.R)) % 255;
        const G = (gLower + Math.round(Math.random() * decStarting.G)) % 255;
        const B = decStarting.B;

        const hex = `#${R.toString(16).padStart(2, "0")}${G.toString(
            16
        ).padStart(2, "0")}${B.toString(16).padStart(2, "0")}`;

        if (typeof newColours[hex] !== "undefined") {
            i--;
        } else {
            newColours[hex] = 1;
        }
    }

    return Object.keys(newColours);
};

export const generateColourGradientArray = (
    startingColour,
    endingColour,
    numberOfColours
) => {
    return chroma
        .bezier([startingColour, "#17a2b8", "#6610f2", endingColour])
        .scale()
        .mode("lab")
        .colors(numberOfColours);
    // Throw and error if an invalid colour is provided
    // if (startingColour.length < 6 || startingColour.length > 7) {
    //     throw Error(
    //         "Invalid starting colour provided. Ensure it is a proper hex-decimal colour."
    //     );
    // }
    // if (endingColour.length < 6 || endingColour.length > 7) {
    //     throw Error(
    //         "Invalid ending colour provided. Ensure it is a proper hex-decimal colour."
    //     );
    // }

    // const hexStartingColour =
    //     startingColour[0] === "#" ? startingColour.substr(1) : startingColour;
    // const hexStarting = {
    //     R: hexStartingColour.substr(0, 2),
    //     G: hexStartingColour.substr(2, 2),
    //     B: hexStartingColour.substr(4, 2)
    // };
    // const decStarting = {
    //     R: parseInt(hexStarting.R, 16),
    //     G: parseInt(hexStarting.G, 16),
    //     B: parseInt(hexStarting.B, 16)
    // };

    // const hexEndingColour =
    //     endingColour[0] === "#" ? endingColour.substr(1) : endingColour;
    // const hexEnding = {
    //     R: hexEndingColour.substr(0, 2),
    //     G: hexEndingColour.substr(2, 2),
    //     B: hexEndingColour.substr(4, 2)
    // };
    // const decEnding = {
    //     R: parseInt(hexEnding.R, 16),
    //     G: parseInt(hexEnding.G, 16),
    //     B: parseInt(hexEnding.B, 16)
    // };

    // const startEndDifferenceR = Math.round(
    //     (decEnding.R - decStarting.R) / numberOfColours
    // );
    // const startEndDifferenceG = Math.round(
    //     (decEnding.G - decStarting.G) / numberOfColours
    // );
    // const startEndDifferenceB = Math.round(
    //     (decEnding.B - decStarting.B) / numberOfColours
    // );

    // let newColours = [{ R: decStarting.R, G: decStarting.G, B: decStarting.B }];

    // let R = decStarting.R;
    // let G = decStarting.G;
    // let B = decStarting.B;

    // for (let i = 0; i <= numberOfColours; i++) {
    //     if (R + startEndDifferenceR < 0) {
    //         R = 256 + (R + startEndDifferenceR);
    //     } else if (R + startEndDifferenceR > 255) {
    //         R = 0 + (R + startEndDifferenceR);
    //     } else {
    //         R += startEndDifferenceR;
    //     }

    //     if (G + startEndDifferenceG < 0) {
    //         G = 256 + (G + startEndDifferenceG);
    //     } else if (G + startEndDifferenceG > 256) {
    //         G = 0 + (G + startEndDifferenceG);
    //     } else {
    //         G += startEndDifferenceG;
    //     }

    //     if (B + startEndDifferenceB < 0) {
    //         B = 256 + (B + startEndDifferenceB);
    //     } else if (B + startEndDifferenceB > 256) {
    //         B = 0 + (B + startEndDifferenceB);
    //     } else {
    //         B += startEndDifferenceB;
    //     }

    //     newColours.push({ R, G, B });
    // }

    // const colours = [];
    // for (const colourObject of newColours) {
    //     colours.push(
    //         `#${colourObject.R.toString(16).padStart(
    //             2,
    //             "0"
    //         )}${colourObject.G.toString(16).padStart(
    //             2,
    //             "0"
    //         )}${colourObject.B.toString(16).padStart(2, "0")}`
    //     );
    // }

    // return colours;
};

export const isArrayInArray = (needleArray, haystackArray) => {
    if (
        typeof needleArray === "undefined" ||
        Array.isArray(needleArray) === false
    ) {
        return false;
    }

    for (let hey of haystackArray) {
        if (needleArray.includes(hey)) {
            return true;
        }
    }

    return false;
};

export const toggleIntercom = (event) => {
    event.preventDefault();
    // @ts-ignore POSSIBLE ERROR FOUND Cannot find name '$crisp'
    if (typeof document !== "undefined" && typeof $crisp !== "undefined") {
        // @ts-ignore POSSIBLE ERROR FOUND Cannot find name '$crisp'
        $crisp.push(["do", "chat:open"]);
    }
};

export const forceUserProfileUpdate = (user) => {
    return (
        typeof user !== "undefined" &&
        user !== null &&
        user.institution_id !== null &&
        typeof user.institution !== "undefined" &&
        user.institution !== null &&
        user.institution.status === INSTITUTION_STATUS_APPROVED &&
        typeof user.status !== "undefined" &&
        user.status === USER_STATUS_ACTIVE &&
        (user.purpose_for_using_convergence_v2 === null ||
            user.location === null) &&
        process.env.NODE_ENV !== "development"
    );
};

export const getCookiesFromCtx = (ctx) => {
    return ctx?.req?.cookies || null;
};
