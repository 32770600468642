import React from "react";
import Card from "../../../layout/Card/Card";
import CardContent from "../../../layout/Card/CardContent";
import Link from "../../../theme/3.0/Link";
import { USER_SUB_STATUS_TRIAL_ENDED } from "../../../../constants";

const UserNotJoinedInstitutionOverride = ({ user }) => (
    <React.Fragment>
        <Card>
            <CardContent>
                <h2 className="card-title">
                    {user.sub_status === USER_SUB_STATUS_TRIAL_ENDED
                        ? "Upgrade to a Full Membership"
                        : "Thank you for registering your account!"}
                </h2>
                {user.sub_status === USER_SUB_STATUS_TRIAL_ENDED ? (
                    <React.Fragment>
                        <p>
                            In order to upgrade to a full membership, you must
                            register your institution by clicking the button
                            below.
                        </p>
                        <p>
                            After you have completed your institution
                            registration, you application will be submitted for
                            review by a member of the Convergence team. This
                            approval process takes between 3 - 7 days. During
                            this period a member of our staff will be in contact
                            with you.
                        </p>
                        <p>
                            If you have any questions or require any assistance,
                            please contact us at{" "}
                            <a href="mailto: support@convergence.finance">
                                support@convergence.finance
                            </a>
                            .
                        </p>
                    </React.Fragment>
                ) : (
                    <p>
                        You must now register or join an institution in order to
                        gain access to our member-only features.
                    </p>
                )}
                <Link
                    routeKey={"institution-register"}
                    label={
                        user.sub_status === USER_SUB_STATUS_TRIAL_ENDED
                            ? "Register Institution"
                            : "Register or Join Institution"
                    }
                    colour={"success"}
                    size={"lg"}
                    isButton
                />
            </CardContent>
        </Card>
    </React.Fragment>
);

export default UserNotJoinedInstitutionOverride;
