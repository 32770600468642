/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React, { PropsWithChildren } from "react";
import { Card as BootstrapCard } from "reactstrap";

const Card = ({ children }: PropsWithChildren<{}>) => (
    <BootstrapCard>{children}</BootstrapCard>
);

export default Card;
