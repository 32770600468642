/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import { WithRouterProps } from "next/dist/client/with-router";
import Head from "next/head";
import { withRouter } from "next/router";
import PropTypes from "prop-types";
import React, { PropsWithChildren } from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { LOGGED_IN_USER_QUERY } from "../../graphql/query/UserQuery";
import { logPageView } from "../../utils/Analytics";
import SkipToMainContent from "../theme/3.0/Page/SkipToMainContent";

interface Props extends PropsWithChildren<WithRouterProps> {
    className?;
}

class Page extends React.Component<WithApolloClient<Props>> {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any
    };

    constructor(props) {
        super(props);
        this.state = {
            asPath: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.router.asPath !== state.asPath) {
            if (process.browser) {
                props.client
                    .query({
                        query: LOGGED_IN_USER_QUERY,
                        fetchPolicy: "cache-only"
                    })
                    .then(({ data }) => {
                        const user = data && data.user ? data.user : null;
                        const userId = user ? user.id : null;
                        const institutionId =
                            user && user.institution_id
                                ? user.institution_id
                                : null;

                        logPageView(userId, institutionId);
                    });
            }

            return {
                asPath: props.router.asPath
            };
        }

        return null;
    }

    render() {
        return (
            <div className={this.props.className}>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1"
                    />
                </Head>

                <SkipToMainContent />

                {this.props.children}
            </div>
        );
    }
}

const PageWithHOCs = withRouter<Props>(withApollo<Props>(Page));

export default PageWithHOCs;
