/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import FilterButtonActiveFilterBadge from "./FilterButtonActiveFilterBadge";

type Props = {
    filters;
    availableFilters;
    onClickClearFilter;
};
class FilterButtonActiveFilters extends React.Component<Props> {
    // TS CODE CHANGE. MOVED TO STATIC PROP TYPES
    static propTypes = {
        filters: PropTypes.object.isRequired,
        availableFilters: PropTypes.object.isRequired,
        onClickClearFilter: PropTypes.func.isRequired
    };
    render() {
        const { filters, availableFilters, onClickClearFilter } = this.props;

        return Object.keys(filters).map((filterKey) => {
            let filterValue = filters[filterKey];

            if (Array.isArray(filterValue) && filterValue.length <= 0) {
                return null;
            } else if (
                filterValue &&
                !Array.isArray(filterValue) &&
                Object.keys(filterValue).length <= 0
            ) {
                return null;
            }

            let filter = availableFilters[filterKey];

            if (filter) {
                return (
                    <FilterButtonActiveFilterBadge
                        key={filterKey}
                        filterName={filterKey}
                        value={filterValue}
                        filter={filter}
                        onClickClearFilter={onClickClearFilter}
                    />
                );
            } else {
                return null;
            }
        });
    }
}

export default FilterButtonActiveFilters;
