export const fakeSearchResults = {
    items: [
        {
            cursor:
                "eyJzb3J0Q29sdW1uIjoicHVibGljYXRpb25EYXRlIiwic29ydERpcmVjdGlvbiI6ImRlc2MifQ==",
            relevance: 4.018132,
            node: {
                id: "bb150180-e774-4f74-8ec7-e3c02a2550ff",
                title: "Unbranded Wooden Computer",
                status: "Approved",
                searchIndex: "deal-v2",
                __typename: "SearchResult"
            },
            __typename: "PagedEdgeSearchResult"
        },
        {
            cursor:
                "eyJzb3J0Q29sdW1uIjoicHVibGljYXRpb25EYXRlIiwic29ydERpcmVjdGlvbiI6ImRlc2MifQ==",
            relevance: 4.018132,
            node: {
                id: "b8e2b91b-ffdc-4fa4-bfda-bb62fd07c348",
                title: "Fantastic Plastic Chair",
                status: "Approved",
                searchIndex: "deal-v2",
                __typename: "SearchResult"
            },
            __typename: "PagedEdgeSearchResult"
        },
        {
            cursor:
                "eyJzb3J0Q29sdW1uIjoicHVibGljYXRpb25EYXRlIiwic29ydERpcmVjdGlvbiI6ImRlc2MifQ==",
            relevance: 4.018132,
            node: {
                id: "b70ad058-0016-4af0-b928-63413ff6a0ed",
                title: "Practical Granite Hat",
                status: "Approved",
                searchIndex: "deal-v2",
                __typename: "SearchResult"
            },
            __typename: "PagedEdgeSearchResult"
        },
        {
            cursor:
                "eyJzb3J0Q29sdW1uIjoicHVibGljYXRpb25EYXRlIiwic29ydERpcmVjdGlvbiI6ImRlc2MifQ==",
            relevance: 4.018132,
            node: {
                id: "b49b9b0a-7e5b-4020-ae91-2fe89328420d",
                title: "Tasty Rubber Mouse",
                status: "Approved",
                searchIndex: "deal-v2",
                __typename: "SearchResult"
            },
            __typename: "PagedEdgeSearchResult"
        },
        {
            cursor:
                "eyJzb3J0Q29sdW1uIjoicHVibGljYXRpb25EYXRlIiwic29ydERpcmVjdGlvbiI6ImRlc2MifQ==",
            relevance: 4.018132,
            node: {
                id: "a25b17ab-47ea-4522-b473-4be08483211f",
                title: "Refined Plastic Salad",
                status: "Approved",
                searchIndex: "deal-v2",
                __typename: "SearchResult"
            },
            __typename: "PagedEdgeSearchResult"
        },
        {
            cursor:
                "eyJzb3J0Q29sdW1uIjoicHVibGljYXRpb25EYXRlIiwic29ydERpcmVjdGlvbiI6ImRlc2MifQ==",
            relevance: 4.018132,
            node: {
                id: "a2156604-00bf-437b-9c54-5c48cd451215",
                title: "Gorgeous Wooden Chicken",
                status: "Approved",
                searchIndex: "deal-v2",
                __typename: "SearchResult"
            },
            __typename: "PagedEdgeSearchResult"
        }
    ]
};
