/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import NewFooter from "../theme/3.0/Page/Footer";

const Footer = () => <NewFooter />;

export default Footer;
