/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import { ModalBody as ModalBodyBootstrap } from "reactstrap";

const ModalCardBody = ({ children }) => (
    <ModalBodyBootstrap>{children}</ModalBodyBootstrap>
);

ModalCardBody.propTypes = {
    children: PropTypes.any.isRequired
};

export default ModalCardBody;
