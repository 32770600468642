/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import { Input } from "reactstrap";
import BaseField, { fieldPropTypes } from "./BaseField";

type Props = {
    fieldName?;
    label?;
    placeHolder?;
    validation?;
    helpText?;
    disableFormGroup?;
    value?;
    disabled?;
    onChange?;
    autoComplete?;
    innerRef?;
    defaultValue?: string;
};

const TextField = ({
    fieldName,
    label,
    placeHolder,
    validation,
    helpText,
    disableFormGroup,
    ...props
}: Props) => (
    <BaseField
        {...props}
        fieldName={fieldName}
        label={label}
        validation={validation}
        helpText={helpText}
        disableFormGroup={disableFormGroup}
        render={(isValid) => (
            <Input
                type="text"
                valid={isValid}
                invalid={isValid === false ? true : null}
                name={fieldName}
                id={fieldName}
                placeholder={placeHolder}
                {...props}
            />
        )}
    />
);

TextField.propTypes = fieldPropTypes;

export default TextField;
