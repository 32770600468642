import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { from, fromPromise, split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import https from "https";
import fetch from "isomorphic-unfetch";
import { NextPage } from "next";
import PropTypes from "prop-types";
import React from "react";
import { SubscriptionClient } from "subscriptions-transport-ws";
import Notification from "../components/Notification";
import config from "../config/config";
import {
    parseCookies,
    refreshToken,
    removeCookie
} from "./AuthenticationUtility";
import Redirect from "./Redirect";

let globalApolloClient = null;
export let globalWSClient = null;

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 * @param {Boolean} [config.ssr=true]
 */
export function withApollo(PageComponent: NextPage, { ssr = true } = {}) {
    const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
        const client =
            apolloClient || initApolloClient(apolloState, { getToken });
        return (
            <ApolloProvider client={client}>
                <PageComponent {...pageProps} />
            </ApolloProvider>
        );
    };

    if (process.env.NODE_ENV !== "production") {
        // Find correct display name
        const displayName =
            PageComponent.displayName || PageComponent.name || "Component";

        // Warn if old way of installing apollo is used
        if (displayName === "App") {
            console.warn("This withApollo HOC only works with PageComponents.");
        }

        // Set correct display name for devtools
        WithApollo.displayName = `withApollo(${displayName})`;

        // Add some prop types
        WithApollo.propTypes = {
            // Used for getDataFromTree rendering
            apolloClient: PropTypes.object,
            // Used for client/server rendering
            apolloState: PropTypes.object
        };
    }

    if (ssr || PageComponent.getInitialProps) {
        WithApollo.getInitialProps = async (ctx) => {
            const { AppTree } = ctx;

            // Run all GraphQL queries in the component tree
            // and extract the resulting data
            const apolloClient = (ctx.apolloClient = initApolloClient(
                {},
                {
                    context: ctx,
                    getToken: () => getToken(ctx.req)
                }
            ));

            const pageProps = PageComponent.getInitialProps
                ? await PageComponent.getInitialProps(ctx)
                : {};

            // Only on the server
            if (typeof window === "undefined") {
                // When redirecting, the response is finished.
                // No point in continuing to render
                if (ctx.res && ctx.res.finished) {
                    return {};
                }

                if (ssr) {
                    try {
                        // Run all GraphQL queries
                        const { getDataFromTree } = await import(
                            "@apollo/react-ssr"
                        );
                        await getDataFromTree(
                            <AppTree
                                pageProps={{
                                    ...pageProps,
                                    apolloClient
                                }}
                            />
                        );
                    } catch (error) {
                        // Prevent Apollo Client GraphQL errors from crashing SSR.
                        // Handle them in components via the data.error prop:
                        // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
                        console.error(
                            "Error while running `getDataFromTree`",
                            error
                        );
                    }
                }
            }

            // Extract query data from the Apollo store
            const apolloState = apolloClient.cache.extract();

            return {
                ...pageProps,
                apolloState
            };
        };
    }

    return WithApollo;
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 */
function initApolloClient(...args) {
    // Make sure to create a new client for every server-side request so that data
    // isn't shared between connections (which would be bad)
    if (typeof window === "undefined") {
        // @ts-ignore POSSIBLE ERROR FOUND. 2 args expected. CAN'T USE spread here
        globalApolloClient = createApolloClient(...args);
        return globalApolloClient;
    }

    // Reuse client on the client-side
    if (!globalApolloClient) {
        // @ts-ignore POSSIBLE ERROR FOUND. 2 args expected. CAN'T USE spread here
        globalApolloClient = createApolloClient(...args);
    }

    return globalApolloClient;
}

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 * @param  {Object} config
 */
function createApolloClient(initialState = {}, { getToken, context }) {
    const fetchOptions: { agent? } = {};

    // If you are using a https_proxy, add fetchOptions with 'https-proxy-agent' agent instance
    // 'https-proxy-agent' is required here because it's a sever-side only module
    if (typeof window === "undefined") {
        if (process.env.https_proxy) {
            fetchOptions.agent = new (require("https-proxy-agent"))(
                process.env.https_proxy
            );
        } else {
            fetchOptions.agent = new https.Agent({ rejectUnauthorized: false });
        }
    }

    const httpLink = new HttpLink({
        uri: config.graphql_api_url + "?" + Math.random(),
        credentials: "same-origin",
        fetch,
        fetchOptions
    });

    let combinedLink = httpLink;

    // We cannot utilize web sockets during SSR
    if (process.browser) {
        const supportsWebSockets =
            "WebSocket" in window || "MozWebSocket" in window;

        if (supportsWebSockets) {
            let wsParams: {
                reconnect: boolean;
                lazy: boolean;
                connectionParams?;
            } = {
                reconnect: true,
                lazy: true
            };
            wsParams.connectionParams = {
                get authToken() {
                    return getToken();
                }
            };
            globalWSClient = new SubscriptionClient(
                config.graphql_api_ws_url,
                wsParams
            );
            const wsLink = new WebSocketLink(globalWSClient);

            // TS FOUND ERROR:
            // Property 'requester' is missing in type 'ApolloLink' but required in type 'HttpLink'.ts(2741)
            // @ts-ignore
            combinedLink = split(
                // split based on operation type
                ({ query }) => {
                    // TS FOUND ERROR:
                    // @ts-ignore Property 'operation' does not exist on type 'OperationDefinitionNode | FragmentDefinitionNode'
                    const { kind, operation } = getMainDefinition(query);
                    return (
                        kind === "OperationDefinition" &&
                        operation === "subscription"
                    );
                },
                wsLink,
                httpLink
            );
        }
    }

    const authLink = setContext((request, { headers }) => {
        const token = getToken();
        return {
            headers: {
                ...headers,
                ...(token && { Authorization: `Bearer ${token}` })
            }
        };
    });

    const errorHandlerMiddleware = onError(
        ({ response, graphQLErrors, networkError, forward, operation }) => {
            const requestContext =
                typeof context !== "undefined" &&
                Object.keys(context).length > 0
                    ? context
                    : // @ts-ignore POSSIBLE ERROR FOUND context does not exist on type Operation
                    operation.context
                    ? // @ts-ignore POSSIBLE ERROR FOUND context does not exist on type Operation
                      operation.context
                    : {};
            // @ts-ignore POSSIBLE ERROR FOUND Property 'statusCode' does not exist on type 'Error | ServerError | ServerParseError'.
            if (networkError && networkError.statusCode === 401) {
                if (typeof response !== "undefined") {
                    response.data = {};
                }
                // 401 errors, we'll force log out the user and redirect them to the login page
                removeCookie(requestContext);
                Redirect(requestContext, "/login");
            }
            if (graphQLErrors) {
                const { extensions, message } = graphQLErrors[0];

                switch (extensions.code) {
                    case "BAD_USER_INPUT": {
                        break;
                    }
                    case "UNAUTHENTICATED": {
                        if (globalApolloClient) {
                            removeCookie(requestContext);
                            globalApolloClient.resetStore().then(() => {
                                if (globalWSClient) {
                                    globalWSClient.unsubscribeAll();
                                    globalWSClient.close();
                                }
                                if (process.browser) {
                                    Notification.error(`Error: ${message}`);
                                }
                                Redirect(requestContext, "/login");
                            });
                        }
                        break;
                    }
                    case "AUTH_TOKEN_EXPIRED_ERROR": {
                        console.debug("> [AUTH_TOKEN_EXPIRED_ERROR] Start.");
                        const refreshTokenPromise = refreshToken(
                            requestContext,
                            globalApolloClient
                        ).catch((error) => {
                            console.debug(
                                "> [AUTH_TOKEN_EXPIRED_ERROR] Reached an error trying to refresh the Auth Token",
                                error
                            );
                            removeCookie(requestContext);
                            Redirect(requestContext, "/login");
                        });
                        return fromPromise(refreshTokenPromise).flatMap(
                            (token) => {
                                console.debug(
                                    "> [AUTH_TOKEN_EXPIRED_ERROR] Successfully used a refresh token, updating existing token."
                                );
                                operation.setContext(({ headers = {} }) => ({
                                    headers: {
                                        // Re-add old headers
                                        ...headers,
                                        // Switch out old access token for new one
                                        Authorization: `Bearer ${token}` || null
                                    }
                                }));

                                console.debug(
                                    "> [AUTH_TOKEN_EXPIRED_ERROR] Retrying last failed GraphQL operation for Auth Token refresh."
                                );
                                // Retry last failed request
                                return forward(operation);
                            }
                        );
                    }
                    default: {
                        if (process.browser) {
                            Notification.error(`Error: ${message}`);
                        }
                        console.debug(`[GraphQL error]: ${message}`);
                    }
                }
            }

            if (networkError) {
                if (process.browser) {
                    Notification.error(
                        "Uh-oh! An error occurred trying to connect to our servers."
                    );
                }
                if (typeof response !== "undefined") {
                    response.data = {};
                }
                console.debug(`[Network error]: ${networkError}`);
            }
        }
    );

    // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
    return new ApolloClient({
        ssrMode: typeof window === "undefined", // Disables forceFetch on the server (so queries are only run once)
        link: from([authLink, errorHandlerMiddleware, combinedLink]),
        cache: new InMemoryCache().restore(initialState),
        resolvers: {
            Mutation: {
                updateImpersonateStatus: (_, { impersonate }, { cache }) => {
                    const data = {
                        impersonate: impersonate
                    };

                    cache.writeData({
                        data
                    });
                    return data;
                }
            }
        }
    });
}

/**
 * Get the user token from cookie
 * @param {Object} req
 */
function getToken(req) {
    return parseCookies({ req }).token;
}
