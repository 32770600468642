/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import faClock from "@fortawesome/fontawesome-free-solid/faClock";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import Card from "../../layout/Card/Card";
import CardContent from "../../layout/Card/CardContent";

const AwaitingInstitutionApproval = ({ institution }) => (
    <React.Fragment>
        <Card>
            <CardContent>
                <h2 className="card-title">
                    <span className="text-warning">
                        <FontAwesomeIcon icon={faClock as IconProp} />
                    </span>{" "}
                    Membership Approval Pending
                </h2>
                <p>
                    Thank you for submitting your application for Convergence
                    Membership.
                </p>
                <p>
                    Your institution, {institution.name}, is currently awaiting
                    membership approval. A member from the team may contact you
                    with questions, comments, and to discuss subscription
                    options.
                </p>
                <p>
                    Please note that while awaiting approval, your access to the
                    Convergence platform will be limited.
                </p>
                <p>
                    {" "}
                    For more information, please contact us at{" "}
                    <a href="mailto:support@convergence.finance">
                        support@convergence.finance
                    </a>
                    .
                </p>
            </CardContent>
        </Card>
    </React.Fragment>
);

AwaitingInstitutionApproval.propTypes = {
    institution: PropTypes.object.isRequired
};

export default AwaitingInstitutionApproval;
