import React from "react";
import faChevronRight from "@fortawesome/fontawesome-free-solid/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "../../../theme/3.0/theme";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const FilterOptionRow = ({
    availableFilter,
    filterKey,
    filters,
    activeFilter,
    setActiveFilter
}) => {
    const label = availableFilter.label;
    // check if this filter has been selected
    const hasSelections =
        typeof filters[filterKey] !== "undefined"
            ? Array.isArray(filters[filterKey])
                ? filters[filterKey].length > 0
                : Object.keys(filters[filterKey]).length > 0
            : false;
    let counter = 0;

    if (
        typeof filters[filterKey] !== "undefined" &&
        availableFilter.multiple === true
    ) {
        counter =
            hasSelections && Array.isArray(filters[filterKey])
                ? filters[filterKey].length
                : Object.keys(filters[filterKey]).length;
    } else if (availableFilter.multiple !== true) {
        counter = filters[filterKey] ? 1 : 0;
    }

    return (
        <li
            key={filterKey}
            className={activeFilter === filterKey ? "active" : ""}
        >
            <a
                href={"#" + filterKey}
                data-filter={filterKey}
                onClick={setActiveFilter}
            >
                <span className={"float-right"}>
                    {hasSelections ? (
                        <div className="selection-counter">{counter}</div>
                    ) : (
                        ""
                    )}
                    <FontAwesomeIcon icon={faChevronRight as IconProp} />
                </span>
                <span
                    style={{
                        display: "block",
                        overflow: "hidden"
                    }}
                >
                    {label}
                </span>
            </a>
            <style jsx>{`
                li {
                    border-bottom: 1px solid #ebebeb;
                }

                li a {
                    display: block;
                    padding: 0.5rem 1rem;
                }

                li a > * {
                    pointer-events: none;
                }

                li.active a {
                    color: white;
                    background-color: ${theme.colour.blue};
                }

                li:first-of-type {
                    border-top: 0;
                }
                li .selection-counter {
                    display: inline;
                    font-size: 0.6rem;
                    vertical-align: middle;
                    padding: 2px 5px;
                    margin-right: 10px;
                    position: relative;
                    margin-top: -5px;
                    background-color: ${theme.colour.primary};
                    color: white;
                }
                li.active .selection-counter {
                    background-color: white;
                    color: ${theme.colour.blue};
                }
            `}</style>
        </li>
    );
};

export default FilterOptionRow;
