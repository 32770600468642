const React = require("react");
const NextRouter = require("next/router").default;
const NextLink = require("next/link").default;

class Router {
    redirects: any[];
    routes: {};
    constructor() {
        this.redirects = [];
        this.routes = {};
        this.handleRequests = this.handleRequests.bind(this);
        this.getRoutes = this.getRoutes.bind(this);
        this.redirect = this.redirect.bind(this);
        this.add = this.add.bind(this);
        this.Link = this.Link.bind(this);
    }

    redirect(match, redirectTo, status = 301) {
        this.redirects.push({
            match,
            redirectTo,
            status
        });
        return this;
    }

    add(key, path, url, sitemap = false, args = {}) {
        this.routes[key] = {
            routeKey: key,
            path,
            url,
            sitemap,
            ...args
        };
        return this;
    }

    getRoutes() {
        return Object.values(this.routes);
    }

    Link({ routeKey, children, params = {}, query = {}, hash = "", ...props }) {
        const route =
            typeof this.routes[routeKey] !== "undefined"
                ? this.routes[routeKey]
                : null;
        if (!route && !props.url) {
            return React.createElement(
                "div",
                null,
                `[Route Key "${routeKey}" Not Found]`
            );
        }

        if (!props.url) {
            const { asPath, pathname, routeQuery } = this._createURL(
                route,
                params,
                query,
                hash
            );

            const linkProps = {
                href: { pathname, query: { ...routeQuery } },
                as: props.url ? props.url : asPath,
                ...props
            };

            return React.createElement(
                NextLink,
                linkProps,
                children({ url: props.url ? props.url : asPath })
            );
        } else {
            const linkProps = {
                href: props.url,
                ...props
            };

            return React.createElement(
                NextLink,
                linkProps,
                children({ url: props.url })
            );
        }
    }

    push(routeKey, params = {}, query = {}, hash = "", options = {}) {
        const route =
            typeof this.routes[routeKey] !== "undefined"
                ? this.routes[routeKey]
                : null;
        if (!route) {
            throw new Error(
                `Unable to find a route for the Route Key "${routeKey}"."`
            );
        }

        const { asPath, pathname, routeQuery } = this._createURL(
            route,
            params,
            query,
            hash
        );

        return NextRouter.push(
            {
                pathname,
                query: routeQuery
            },
            asPath,
            options
        );
    }

    handleRequests(server, app) {
        const handle = app.getRequestHandler();
        this.redirects.forEach(({ match, redirectTo, status }) => {
            server.get(match, (req, res) => {
                const url = req.originalUrl.replace(match, redirectTo);
                return res.redirect(status, url);
            });
        });

        Object.values(this.routes).forEach(({ url, path, routeKey }) => {
            server.get(url, (req, res) => {
                const params = { ...req.params, routeKey };
                return app.render(req, res, path, params || {});
            });
        });

        server.all("*", handle);
    }

    _createURL(route, params, query, hash) {
        const regex = /:[a-zA-Z]+/gi;
        const paramKeys = Object.keys(params);
        let pathname = route.path;
        let asPath = route.url;
        let result = regex.exec(asPath);

        params = { ...params, routeKey: route.routeKey };

        while (result) {
            const param = result[0].substr(1);
            if (paramKeys.includes(param)) {
                asPath = asPath.replace(`:${param}`, params[param]);
            }

            result = regex.exec(asPath);
        }

        // Append query to our asPath
        if (Object.keys(query).length > 0) {
            let queryPath = "";
            let count = 0;
            for (const key in query) {
                if (!query[key]) {
                    continue;
                }
                if (count != 0) {
                    queryPath += "&";
                }
                queryPath += key + "=" + encodeURIComponent(query[key]);
                count++;
            }

            if (queryPath.length > 0) {
                asPath += `?${queryPath}`;
            }
        }

        // Finally, append a hash if applicable
        if (hash.length > 0) {
            asPath += `#${hash}`;
        }

        return {
            pathname,
            asPath,
            routeParams: params,
            routeQuery: { ...query, ...params }
        };
    }
}

export default Router;
