/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React, { ReactNode } from "react";
import { Alert } from "reactstrap";

type Props = {
    children: ReactNode;
    title?: string;
    type?: string;
    onDismiss?;
    className?;
    color?: string;
};

const MessageBox = ({ children, title, type, onDismiss, ...props }: Props) => (
    <Alert toggle={onDismiss} isOpen={true} color={type} {...props}>
        {title && <h4 className={"alert-heading"}>{title}</h4>}
        <div className={"message-body"}>{children}</div>
    </Alert>
);

export default MessageBox;
