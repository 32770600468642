/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React, { PropsWithChildren, ReactNode } from "react";
import { Query } from "react-apollo";
import UpdateUserPreferences from "../components/page/institution/UpdateUserPreferences";
import {
    INSTITUTION_STATUS_PENDING_APPROVAL,
    USER_STATUS_NOT_COMPLETED_PREFERENCES,
    USER_STATUS_NOT_JOINED_INSTITUTION,
    INSTITUTION_STATUS_COMPLIANCE_REVIEW,
    INSTITUTION_STATUS_SUSPENDED,
    INSTITUTION_STATUS_REACTIVATED,
    INSTITUTION_STATUS_DENIED_APPROVAL,
    INSTITUTION_STATUS_DRAFT
} from "../constants";
import { LOGGED_IN_USER_QUERY } from "../graphql/query/UserQuery";
import { AuthorizationService } from "../service/AuthorizationService";
import GeneralPage from "./layout/GeneralPage";
import UserNotJoinedInstitutionOverride from "./page/general/user-check-override/UserNotJoinedInstitutionOverride";
import AwaitingInstitutionApproval from "./page/institution/AwaitingInstitutionApproval";
import ComplianceReview from "./page/institution/ComplianceReview";
import InstitutionSuspended from "./page/institution/InstitutionSuspended";
import InstitutionReactivated from "./page/institution/InstitutionReactivated";
import DeniedInstitutionApproval from "./page/institution/DeniedInstitutionApproval";
import InstitutionInDraftOverride from "./page/general/user-check-override/InstitutionInDraftOverride";
import { User } from "../types/mainTypes";

type Props = {
    enableUserChecks?;
    passNullUser?: boolean;
    renderLoggedIn?: any;
    renderNotLoggedIn?: any;
    children: any;
};
class Authenticated extends React.Component<Props> {
    static propTypes = {
        enableUserChecks: PropTypes.bool,
        passNullUser: PropTypes.bool,
        renderLoggedIn: PropTypes.func,
        renderNotLoggedIn: PropTypes.func,
        children: PropTypes.any
    };

    constructor(props) {
        super(props);
    }

    renderLoggedInUser = (user, authorizationService) => {
        const renderLoggedIn = this.props.renderLoggedIn;

        if (renderLoggedIn) {
            return renderLoggedIn(user, authorizationService);
        }

        if (typeof this.props.children === "function") {
            return this.props.children(user, authorizationService);
        } else {
            return this.props.children;
        }
    };

    renderNotLoggedInUser = () => {
        const renderNotLoggedIn = this.props.renderNotLoggedIn;

        if (renderNotLoggedIn) {
            return renderNotLoggedIn();
        }

        return (
            <GeneralPage title="Access Denied">
                <p>You do not have permission to access this page.</p>
            </GeneralPage>
        );
    };

    getUserStatusOverrides(user) {
        const passNullUser = this.props.passNullUser;

        if (
            typeof user === "undefined" ||
            user === null ||
            typeof user.status === "undefined" ||
            user.status === null
        ) {
            return [false, null];
        }

        switch (user.status) {
            case USER_STATUS_NOT_JOINED_INSTITUTION: {
                return [
                    true,
                    <UserNotJoinedInstitutionOverride
                        key={"register-institution"}
                        user={user}
                    />,
                    "Register Institution"
                ];
            }
            case USER_STATUS_NOT_COMPLETED_PREFERENCES: {
                return [
                    true,
                    <UpdateUserPreferences
                        key={"user-preferences"}
                        user={user}
                    />,
                    "Complete Preferences"
                ];
            }
            default:
                break;
        }

        if (
            typeof user.institution === "undefined" ||
            user.institution === null ||
            typeof user.institution.status === "undefined" ||
            user.institution.status === null
        ) {
            return [false, null];
        }

        switch (user.institution.status) {
            case INSTITUTION_STATUS_DRAFT: {
                return [
                    !passNullUser,
                    <InstitutionInDraftOverride
                        key={"institution-draft"}
                        // TS FOUND ERROR: no prop user for sure (no props at all actually)
                        // TS CODE CHANGE
                        // user={user}
                    />,
                    "Complete Institution Registration"
                ];
            }
            case INSTITUTION_STATUS_PENDING_APPROVAL: {
                return [
                    !passNullUser,
                    <AwaitingInstitutionApproval
                        key={"institution-pending-approval"}
                        institution={user.institution}
                    />,
                    "Pending Approval"
                ];
            }
            case INSTITUTION_STATUS_COMPLIANCE_REVIEW: {
                return [
                    !passNullUser,
                    <ComplianceReview
                        key={"institution-compliance-review"}
                        institution={user.institution}
                    />,
                    "Awaiting Compliance Review"
                ];
            }
            case INSTITUTION_STATUS_SUSPENDED: {
                return [
                    !passNullUser,
                    <InstitutionSuspended
                        key={"institution-suspended"}
                        institution={user.institution}
                    />,
                    "Awaiting Compliance Review"
                ];
            }
            case INSTITUTION_STATUS_REACTIVATED: {
                return [
                    !passNullUser,
                    <InstitutionReactivated
                        key={"institution-reactivated"}
                        institution={user.institution}
                    />,
                    "Pending Reactivation"
                ];
            }
            case INSTITUTION_STATUS_DENIED_APPROVAL: {
                return [
                    !passNullUser,
                    <DeniedInstitutionApproval
                        key={"institution-denied-approval"}
                        institution={user.institution}
                    />,
                    "Denied Approval"
                ];
            }
            default:
                break;
        }

        return [false, null, null];
    }

    render() {
        const enableUserChecks = this.props.enableUserChecks;
        const passNullUser = this.props.passNullUser;

        return (
            <Query
                query={LOGGED_IN_USER_QUERY}
                errorPolicy="ignore"
                fetchPolicy={"cache-only"}
            >
                {({ data }) => {
                    const user = data && data.user ? data.user : null;
                    const roles = data && data.roles ? data.roles : null;
                    const authorizationService = new AuthorizationService(
                        user,
                        roles
                    );

                    if (user && enableUserChecks) {
                        const [
                            hasOverride,
                            overrideComponent,
                            title
                        ] = this.getUserStatusOverrides(user);
                        if (hasOverride) {
                            return (
                                <GeneralPage pageTitle={title as string}>
                                    {overrideComponent}
                                </GeneralPage>
                            );
                        }
                    }

                    if (passNullUser) {
                        return this.renderLoggedInUser(
                            user,
                            authorizationService
                        );
                    }

                    if (user) {
                        return this.props.children(user, authorizationService);
                    } else {
                        return this.renderNotLoggedInUser();
                    }
                }}
            </Query>
        );
    }
}

export default Authenticated;
