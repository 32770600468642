import React from "react";
import AccountStatusNotification from "../../../layout/AccountStatusNotification";
import ImpersonationNotification from "../../../layout/ImpersonationNotification";
import LegacyAccountNotification from "../../../layout/LegacyAccountNotification";
import TrialAccountExpiredNotification from "../../../layout/TrialAccountExpiredNotification";
import {
    USER_STATUS_NOT_JOINED_INSTITUTION,
    INSTITUTION_STATUS_PENDING_APPROVAL,
    INSTITUTION_STATUS_COMPLIANCE_REVIEW,
    INSTITUTION_STATUS_SUSPENDED,
    INSTITUTION_STATUS_REACTIVATED,
    INSTITUTION_STATUS_DRAFT
} from "../../../../constants";
import { differenceInHours, parseISO } from "date-fns";

let TopHeaderNotifications = ({ user }) => (
    <React.Fragment>
        {user &&
            ((user.status === USER_STATUS_NOT_JOINED_INSTITUTION &&
                user.institution) ||
                (user.institution &&
                    (user.institution.status === INSTITUTION_STATUS_DRAFT ||
                        user.institution.status ===
                            INSTITUTION_STATUS_PENDING_APPROVAL ||
                        user.institution.status ===
                            INSTITUTION_STATUS_COMPLIANCE_REVIEW ||
                        user.institution.status ===
                            INSTITUTION_STATUS_SUSPENDED ||
                        user.institution.status ===
                            INSTITUTION_STATUS_REACTIVATED))) && (
                <AccountStatusNotification user={user} />
            )}
        <ImpersonationNotification user={user} />
        <LegacyAccountNotification user={user} />
        {user &&
            user.is_trial &&
            user.expire_at &&
            differenceInHours(parseISO(user.expire_at), new Date()) <= 24 && (
                <TrialAccountExpiredNotification user={user} />
            )}
    </React.Fragment>
);

export default TopHeaderNotifications;
