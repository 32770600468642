/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import gql from "graphql-tag";
import { USER_FRAGMENT } from "./UserQuery";

// TS CODE CHANGE: queryname
export const INITIALIZE_USER_QUERY = gql`
    query InitializeUserQuery {
        self {
            ...generalUserFields
        }
        roles {
            name
            inherit_roles
            permissions {
                id
                resource
                permission
            }
        }
    }
    ${USER_FRAGMENT}
`;

export const IMPERSONATE_QUERY = gql`
    query ImpersonateQuery {
        impersonate @client
    }
`;
