const colour = {
    blue: "#007bff",
    indigo: "#6610f2",
    purple: "#6f42c1",
    pink: "#e83e8c",
    red: "#dc3545",
    orange: "#fd7e14",
    yellow: "#ffc107",
    green: "#28a745",
    teal: "#20c997",
    cyan: "#17a2b8",
    white: "#ffffff",
    gray: "#575757",
    grayDark: "#333333",
    primary: "#337ab7",
    secondary: "#26a4d9",
    success: "#28a745",
    info: "#17a2b8",
    warning: "#ffc107",
    danger: "#dc3545",
    light: "#f8f9fa",
    dark: "#333333",
    primaryHover: "#2b6699",
    primaryDark: "#14588C",
    gray100: "#f8f9fa",
    gray200: "#e9ecef",
    gray300: "#dee2e6",
    gray400: "#d6d6d6",
    gray500: "#969696",
    gray600: "#575757",
    gray700: "#4a4a4a",
    // gray700: "rgb(64,64,64)",
    gray800: "#333333",
    gray900: "#212529",
    transparent: "transparent"
};

const chartColours = [
    "#337ab7",
    "#26a4d9",
    "#17a2b8",
    "#28a745",
    "#20c997",
    "#007bff",
    "#6610f2",
    "#6f42c1",
    "#e83e8c",
    "#dc3545",
    "#fd7e14",
    "#ffc107"
];

const sdgColours = {
    "1": "#E5233D",
    "2": "#DDA73A",
    "3": "#4CA146",
    "4": "#C5192D",
    "5": "#EF402C",
    "6": "#27BFE6",
    "7": "#FBC412",
    "8": "#A31C44",
    "9": "#F26A2D",
    "10": "#E01483",
    "11": "#F89D2A",
    "12": "#BF8D2C",
    "13": "#407F46",
    "14": "#1F97D4",
    "15": "#59BA48",
    "16": "#126A9F",
    "17": "#13496B"
};

const border = {
    width: "1px",
    style: "solid",
    color: colour.gray200
};

const fontSize = {
    sm: "0.875rem",
    md: "1rem",
    lg: "1.5rem"
};

const fontFamily = {
    sansSerif: `"Open Sans", sans-serif`,
    monospace: `SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`
};

const breakpoint = {
    xs: "0",
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1440px"
};

const container = {
    xs: "100%",
    sm: "480px",
    md: "720px",
    lg: "960px",
    xl: "1200px",
    // xxl: "1400px"
    // xxl: "1336px"
    xxl: "83%"
};

const body = {
    fontSize: fontSize.md,
    fontFamily: fontFamily.sansSerif
};

const grid = {
    columns: 12
};

const button = {
    padding: ".375rem 0.75rem",
    fontSize: fontSize.md,

    md: {
        padding: ".375rem 0.75rem",
        fontSize: fontSize.md
    },
    sm: {
        padding: "0.25rem 0.5rem",
        fontSize: fontSize.sm
    },
    lg: {
        padding: "0.5rem 1rem",
        fontSize: fontSize.lg
    }
};

export default {
    colour,
    chartColours,
    sdgColours,
    fontFamily,
    fontSize,
    body,
    border,
    breakpoint,
    grid,
    container,
    button
};
