import React from "react";
import BaseLink from "../../../../BaseLink";
import theme from "../../theme";

type Props = {
    breadcrumbs;
    currentRouteKey?;
};

let Breadcrumb = ({ breadcrumbs, currentRouteKey }: Props) => {
    return (
        <nav aria-label="Breadcrumb">
            <ul className={"breadcrumbs"}>
                {breadcrumbs.map((link, index) => (
                    <li key={index}>
                        <BaseLink
                            routeKey={link.routeKey}
                            params={link.params}
                            query={link.query}
                        >
                            {({ url }) => (
                                <a
                                    href={url}
                                    title={link.label}
                                    className={`breadcrumbs-link${
                                        currentRouteKey === link.routeKey
                                            ? " active"
                                            : ""
                                    }`}
                                >
                                    {link.label ? link.label : ""}
                                </a>
                            )}
                        </BaseLink>
                    </li>
                ))}
            </ul>
            <style jsx>{`
                .breadcrumbs {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }
                .breadcrumbs li {
                    display: inline;
                }
                .breadcrumbs li:after {
                    content: "›";
                    margin: 0 0.8rem;
                    font-size: 1.2em;
                    vertical-align: middle;
                    font-weight: bold;
                }
                .breadcrumbs li:last-of-type:after {
                    content: "";
                    margin: 0;
                }
                .breadcrumbs li a {
                    font-weight: bold;
                    max-width: 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    position: relative;
                    vertical-align: middle;
                    display: inline-block;
                }
                .breadcrumbs li a.active {
                    color: ${theme.colour.gray800};
                }
            `}</style>
        </nav>
    );
};

Breadcrumb = React.memo(Breadcrumb);

export default Breadcrumb;
