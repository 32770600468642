import React from "react";
import theme from "../../theme";

const ToggleSwitch = ({ name, label, checked, onChange }) => {
    return (
        <React.Fragment>
            <style jsx>{`
                .switch-wrapper {
                    display: flex;
                    align-items: center;
                }
                .switch {
                    width: 38px;
                    height: 23px;
                    position: relative;
                    display: inline-block;
                    margin: 0;
                    margin-right: 5px;
                }

                .switch input {
                    position: absolute;
                    opacity: 0;
                    pointer-events: none;
                }

                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: ${theme.colour.gray};
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                }

                .slider:before {
                    position: absolute;
                    content: "";
                    height: 15px;
                    width: 15px;
                    left: 4px;
                    bottom: 4px;
                    background-color: white;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                }

                input[type="checkbox"]:checked + .slider {
                    background-color: ${theme.colour.primary};
                }

                input[type="checkbox"]:focus + .slider {
                    outline: ${theme.colour.primaryHover} auto 5px;
                    box-shadow: 0 0 1px ${theme.colour.primaryHover};
                }

                input[type="checkbox"]:checked + .slider:before {
                    -webkit-transform: translateX(15px);
                    -ms-transform: translateX(15px);
                    transform: translateX(15px);
                }
                /* Rounded sliders */
                .slider.round {
                    border-radius: 34px;
                }

                .slider.round:before {
                    border-radius: 50%;
                }
            `}</style>
            <span className="switch-wrapper">
                <label className={"switch"}>
                    <input
                        name={name}
                        type={"checkbox"}
                        onChange={onChange}
                        checked={checked}
                    />
                    <span className={"slider round"} />
                </label>
                {label}
            </span>
        </React.Fragment>
    );
};

export default ToggleSwitch;
