import React from "react";
import classnames from "classnames";
import css from "styled-jsx/css";
import BaseLink from "../../../../BaseLink";
import theme from "../../theme";

type Props = {
    currentRouteKey?: string;
    routeKey?: string;
    href?: string;
    label: string;
    title?: string;
    darkTheme: string;
    linkColour?: string;
    hoverColour?: string;
    hoverBGColour?: string;
    isActive?;
    role?;
    onClick?;
};

const NavLink: React.FC<Props> = ({
    currentRouteKey,
    routeKey,
    href,
    label,
    title,
    darkTheme,
    linkColour,
    hoverColour,
    hoverBGColour,
    isActive,
    ...props
}) => {
    const aHover = hoverColour ? hoverColour : theme.colour.white;
    const aHoverBG = hoverBGColour
        ? hoverBGColour
        : darkTheme
        ? theme.colour.gray600
        : "#51b6e1";

    const { className, styles } = css.resolve`
        a {
            padding: 0.5rem 0.35rem;
            font-size: 0.833333rem;
        }

        a.active {
            font-weight: bold;
        }

        a:hover {
            text-decoration: none;
        }
    `;

    if (typeof href !== "undefined") {
        let active = false;
        if (typeof isActive === "function") {
            active = isActive({ url: href, currentRouteKey, routeKey });
        } else {
            const regex = new RegExp(`^${routeKey}`);
            active = regex.test(currentRouteKey);
        }
        return (
            <a
                href={href}
                title={title ? title : label}
                className={classnames({ active }) + ` ${className}`}
                {...props}
            >
                {label}
                {styles}
                <style jsx>{`
                    a {
                        color: ${linkColour ? linkColour : theme.colour.white};
                    }

                    a:hover {
                        color: ${aHover} !important;
                        background-color: ${aHoverBG};
                    }
                `}</style>
            </a>
        );
    }

    return (
        <BaseLink routeKey={routeKey}>
            {({ url }) => {
                let active = false;
                if (typeof isActive === "function") {
                    active = isActive({ url, currentRouteKey, routeKey });
                } else {
                    const regex = new RegExp(`^${routeKey}`);
                    active = regex.test(currentRouteKey);
                }
                return (
                    <a
                        href={url}
                        title={title ? title : label}
                        className={classnames({ active }) + ` ${className}`}
                        {...props}
                    >
                        {label}
                        {styles}
                        <style jsx>{`
                            a {
                                color: ${linkColour
                                    ? linkColour
                                    : theme.colour.white};
                            }

                            a:hover {
                                color: ${aHover} !important;
                                background-color: ${aHoverBG};
                            }
                        `}</style>
                    </a>
                );
            }}
        </BaseLink>
    );
};

export default React.memo(NavLink);
