import React from "react";
import BaseLink from "../../BaseLink";
import theme from "./theme";

let Link: React.FC<any> = ({
    routeKey,
    label,
    isButton = false,
    size = "md",
    colour = "primary",
    title = null,
    params = {},
    query = {},
    id = "",
    className = null,
    target = null,
    rel = null,
    ref = null,
    onClick = null,
    LabelComponent = null,
    ...props
}) => {
    const definedClasses = isButton
        ? `btn btn-${size} btn-${colour}` + (className ? ` ${className}` : "")
        : className;
    return (
        <React.Fragment>
            <BaseLink
                routeKey={routeKey}
                params={params}
                query={query}
                {...props}
            >
                {({ url }) => (
                    <a
                        href={url}
                        title={
                            title
                                ? title
                                : typeof label === "string"
                                ? label
                                : ""
                        }
                        className={definedClasses}
                        id={id.length > 0 ? id : null}
                        target={target}
                        rel={rel}
                        ref={ref}
                        onClick={onClick}
                    >
                        {LabelComponent ? <LabelComponent /> : label}
                    </a>
                )}
            </BaseLink>
            <style jsx>
                {`
                    .btn {
                        display: inline-block;
                        font-weight: 400;
                        color: #212529;
                        text-align: center;
                        vertical-align: middle;
                        user-select: none;
                        background-color: transparent;
                        border: 1px solid transparent;
                        padding: 0.375rem 0.375rem;
                        font-size: 1rem;
                        line-height: 1.5;
                        border-radius: 2px;
                        transition: color 0.15s ease-in-out,
                            background-color 0.15s ease-in-out,
                            border-color 0.15s ease-in-out,
                            box-shadow 0.15s ease-in-out;
                    }

                    .btn.group-left {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    .btn.group-right {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }

                    .btn:hover,
                    .btn.hover {
                        color: ${theme.colour.gray900};
                        text-decoration: none;
                    }

                    .btn:focus,
                    .btn.focus {
                        outline: 0;
                        box-shadow: 0 0 0 0.2rem ${theme.colour.primary};
                    }

                    .btn.btn-sm {
                        padding: ${theme.button.sm.padding};
                        font-size: ${theme.button.sm.fontSize};
                    }

                    .btn.btn-md {
                        padding: ${theme.button.md.padding};
                        font-size: ${theme.button.md.fontSize};
                    }

                    .btn.btn-lg {
                        padding: ${theme.button.lg.padding};
                        font-size: ${theme.button.lg.fontSize};
                    }

                    .btn.btn-primary {
                        color: ${theme.colour.white};
                        background-color: ${theme.colour.primary};
                        border-color: ${theme.colour.primary};
                    }

                    .btn.btn-primary:hover {
                        color: ${theme.colour.white};
                        background-color: #2b6699;
                        border-color: #285f8f;
                    }

                    .btn.btn-secondary {
                        color: ${theme.colour.white};
                        background-color: ${theme.colour.secondary};
                        border-color: ${theme.colour.secondary};
                    }

                    .btn.btn-secondary:hover {
                        color: ${theme.colour.white};
                        background-color: #2b6699;
                        border-color: #285f8f;
                    }

                    .btn.btn-success {
                        color: ${theme.colour.white};
                        background-color: ${theme.colour.success};
                        border-color: ${theme.colour.success};
                    }

                    .btn.btn-success:hover {
                        color: ${theme.colour.white};
                        background-color: #218838;
                        border-color: #1e7e34;
                    }

                    .btn.btn-border-info {
                        color: ${theme.colour.info};
                        border-color: ${theme.colour.info};
                    }

                    .btn.btn-border-info:hover {
                        color: ${theme.colour.white};
                        background-color: ${theme.colour.info};
                        border-color: ${theme.colour.info};
                    }

                    .btn.btn-light {
                        color: ${theme.colour.gray};
                        background-color: ${theme.colour.light};
                        border-color: ${theme.colour.light};
                    }

                    .btn.btn-light:hover {
                        color: ${theme.colour.gray};
                        background-color: ${theme.colour.gray400};
                        border-color: ${theme.colour.gray400};
                    }
                `}
            </style>
        </React.Fragment>
    );
};

export default Link = React.memo(Link);
