import React from "react";
import theme from "../../theme";
import NavLink from "./NavLink";
import { SearchSheet } from "./SearchSheet";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger
} from "../../../../ui/collapsible";
import { ChevronRight } from "lucide-react";

type CollapsibleRouteConfig = {
    activeItems: string[];
    parentRoute: { key: string; label: string };
    childRoutes: { key: string; href?: string; label: string }[];
};

let MobileNav = ({
    darkTheme,
    currentRouteKey,
    user,
    authorizationService
}) => {
    const isLoggedIn = !!user;

    const about: CollapsibleRouteConfig = {
        activeItems: [
            "about",
            "about-what-we-do",
            "about-people",
            "about-partners-funders"
        ],
        parentRoute: { key: "about", label: "About" },
        childRoutes: [
            { key: "about", label: "Who We Are" },
            { key: "about-what-we-do", label: "What We Do" },
            { key: "about-people", label: "People" },
            { key: "about-partners-funders", label: "Funders & Partners" },
            { key: "about-themes", label: "Themes" }
        ]
    };
    const members: CollapsibleRouteConfig = {
        activeItems: [
            "membership",
            "members",
            "capacity-building",
            "historical-deals",
            "historical-deals-summary-analysis",
            "investor-data",
            "investor-data-summary-analysis",
            "deals",
            "deals-summary-analysis"
        ],
        parentRoute: { key: "membership", label: "Members" },
        childRoutes: [
            { key: "membership", label: "Membership" },
            { key: "member", label: "Member Directory" },
            { key: "capacity-building", label: "Capacity Building" },
            { key: "historical-deals", label: "Market Data" },
            { key: "deals", label: "Fundraising Deals" }
        ]
    };
    const resources: CollapsibleRouteConfig = {
        activeItems: [
            "blended-finance",
            "online-learning",
            "knowledge",
            "knowledge-view",
            "sobf-2024"
        ],
        parentRoute: { key: "knowledge", label: "Resources" },
        childRoutes: [
            { key: "blended-finance", label: "Blended Finance 101" },
            {
                key: "online-learning",
                href: "/capacity-building#online-learning",
                label: "Online Learning"
            },
            { key: "knowledge", label: "Publications" },
            { key: "sobf-2024", label: "State of Blended Finance" }
        ]
    };
    const grants: CollapsibleRouteConfig = {
        activeItems: [
            "design-funding",
            "design-funding-grant-portfolio",
            "design-funding-market-acceleration",
            "ccfacility"
        ],
        parentRoute: { key: "design-funding", label: "Grants" },
        childRoutes: [
            { key: "design-funding", label: "Design Funding Programs" },
            { key: "design-funding-grant-portfolio", label: "Portfolio" },
            {
                key: "design-funding-market-acceleration",
                label: "Market Acceleration"
            },
            { key: "ccfacility", label: "CC Facility" }
        ]
    };
    const news = {
        activeItems: [
            "news-and-events",
            "news-and-events-view-news",
            "member-spotlight",
            "member-spotlight-view-spotlight"
        ],
        parentRoute: { key: "news-and-events", label: "News" },
        childRoutes: [
            { key: "news-and-events", label: "News" },
            { key: "member-spotlight", label: "Member Spotlight" }
        ]
    };
    const events = {
        activeItems: [
            "event-calendar",
            "event-calendar-library",
            "news-and-events-view-events"
        ],
        parentRoute: { key: "event-calendar", label: "Events" },
        childRoutes: [
            { key: "event-calendar", label: "Calendar" },
            { key: "event-calendar-library", label: "Past Events" }
        ]
    };

    return (
        <div className={"mobile-nav"}>
            <div className={"logo-column"}>
                <span className="tw-ml-auto tw-flex">
                    <SearchSheet
                        user={user}
                        authorizationService={authorizationService}
                        iconOnly
                    />
                </span>
            </div>
            <React.Fragment>
                <div className={"main-menu"}>
                    <div className={"spacer"}>MAIN NAVIGATION</div>
                    <NavLink
                        routeKey={"home"}
                        label="Home"
                        currentRouteKey={currentRouteKey}
                        darkTheme={darkTheme}
                    />
                    <CollapsibleGroup
                        routeConfig={about}
                        currentRouteKey={currentRouteKey}
                        darkTheme={darkTheme}
                        user={user}
                    />
                    <CollapsibleGroup
                        routeConfig={members}
                        currentRouteKey={currentRouteKey}
                        darkTheme={darkTheme}
                        user={user}
                    />
                    <CollapsibleGroup
                        routeConfig={resources}
                        currentRouteKey={currentRouteKey}
                        darkTheme={darkTheme}
                        user={user}
                    />
                    <CollapsibleGroup
                        routeConfig={grants}
                        currentRouteKey={currentRouteKey}
                        darkTheme={darkTheme}
                        user={user}
                    />
                    <CollapsibleGroup
                        routeConfig={news}
                        currentRouteKey={currentRouteKey}
                        darkTheme={darkTheme}
                        user={user}
                    />
                    <CollapsibleGroup
                        routeConfig={events}
                        currentRouteKey={currentRouteKey}
                        darkTheme={darkTheme}
                        user={user}
                    />

                    {user ? (
                        <>
                            <div className={"spacer"}>
                                WELCOME{" "}
                                {user.first_name
                                    ? user.first_name.toUpperCase()
                                    : "USER"}
                                !
                            </div>
                            <NavLink
                                routeKey={"settings-profile"}
                                label="Profile"
                                currentRouteKey={currentRouteKey}
                                darkTheme={darkTheme}
                            />
                            {user?.institution?.tags?.includes(
                                "hide_my_institution"
                            ) &&
                            !user.roles.includes(
                                "ROLE_INSTITUTION_ADMIN"
                            ) ? null : (
                                <NavLink
                                    routeKey={"settings-institution"}
                                    label="My Institution"
                                    currentRouteKey={currentRouteKey}
                                    darkTheme={darkTheme}
                                />
                            )}
                            <div className={"spacer"}>SIGN OUT</div>
                            <NavLink
                                routeKey={"logout"}
                                label="Logout"
                                currentRouteKey={currentRouteKey}
                                darkTheme={darkTheme}
                            />
                        </>
                    ) : (
                        <div className={"main-menu"}>
                            <div className={"spacer"}>CONVERGENCE ACCOUNT</div>
                            <NavLink
                                routeKey={"login"}
                                label="Login"
                                currentRouteKey={currentRouteKey}
                                darkTheme={darkTheme}
                            />
                            <NavLink
                                routeKey={"sign-up"}
                                label="Register"
                                currentRouteKey={currentRouteKey}
                                darkTheme={darkTheme}
                            />
                        </div>
                    )}
                </div>
            </React.Fragment>
            <style jsx global>{`
                .mobile-nav {
                    background-color: ${
                        darkTheme ? theme.colour.gray : theme.colour.white
                    };
                }

                .logo-column {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    height: 60px;
                    width: 100%;
                    padding-left: 15px;
                    padding-right: 15px;
                    background-color: ${
                        darkTheme
                            ? theme.colour.gray700
                            : theme.colour.secondary
                    };
                }

                .main-menu {
                    display: flex;
                    padding: 0 2rem;
                    flex-direction: column;
                }

                .main-menu > :global(div),.main-menu > :global(a) {
                    padding: 0.8rem 0;
                    color: ${
                        darkTheme ? theme.colour.gray300 : theme.colour.gray
                    };
                    border-bottom: 1px solid
                        ${
                            darkTheme
                                ? theme.colour.gray700
                                : theme.colour.gray300
                        };
                }

                .main-menu > :global(a:last-of-type) {
                    border-bottom: 0;
                }
                .collapsible-content a:hover {
                    margin-left: -1rem !important;
                    padding-left: 1.35rem !important;
                }

                .spacer {
                    margin-top: 1.5rem;
                    font-size: 12px;
                    color: ${
                        darkTheme ? theme.colour.gray500 : theme.colour.gray500
                    };
                }

                // @media (min-width: ${theme.breakpoint.md}) {
                //     .mobile-nav {
                //         display: none;
                //     }
                // }

                @media (min-width: ${theme.breakpoint.lg}) {
                    .mobile-nav {
                        display: none;
                    }
                }

                @media (min-width: ${theme.breakpoint.xl}) {
                    .mobile-nav {
                        display: none;
                    }
                }
            `}</style>
        </div>
    );
};

const StyledCollapsibleContent = function({ user, children }) {
    return (
        <CollapsibleContent className="collapsible-content tw-transition-all tw-overflow-hidden data-[state=open]:tw-animate-collapsible-down data-[state=closed]:tw-animate-collapsible-up tw-rounded">
            <div
                className={`tw-grid tw-gap-2 tw-pl-4 ${
                    !user ? "tw-bg-secondary" : "tw-bg-white/40"
                } tw-transition-all`}
            >
                {children}
            </div>
        </CollapsibleContent>
    );
};

const CollapsibleGroup = function({
    routeConfig,
    currentRouteKey,
    darkTheme,
    user
}: {
    routeConfig: CollapsibleRouteConfig;
    currentRouteKey: any;
    darkTheme: any;
    user: any;
}) {
    return (
        <Collapsible
            defaultOpen={routeConfig.activeItems.includes(currentRouteKey)}
            className="tw-grid tw-gap-4 tw-relative"
        >
            <CollapsibleTrigger className="tw-flex tw-items-center tw-gap-2 [&[data-state=open]>svg]:tw-rotate-90">
                <NavLink
                    routeKey={routeConfig.parentRoute.key}
                    label={routeConfig.parentRoute.label}
                    currentRouteKey={currentRouteKey}
                    darkTheme={darkTheme}
                    linkColour={
                        darkTheme ? theme.colour.gray300 : theme.colour.gray
                    }
                    onClick={() => ({})}
                    isActive={() =>
                        routeConfig.activeItems.includes(currentRouteKey)
                    }
                />
                <ChevronRight
                    color={user ? "white" : theme.colour.gray}
                    className="tw-ml-auto tw-transition-all"
                />
            </CollapsibleTrigger>
            <StyledCollapsibleContent user={user}>
                {routeConfig.childRoutes.map((route) => (
                    <NavLink
                        routeKey={route.key}
                        label={route.label}
                        {...(route.href ? { href: route.href } : {})}
                        currentRouteKey={currentRouteKey}
                        darkTheme={darkTheme}
                    />
                ))}
            </StyledCollapsibleContent>
        </Collapsible>
    );
};

// @ts-ignore
export default MobileNav = React.memo(MobileNav);
