/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import EditPreferenceRanges from "./EditPreferenceRanges";
import ViewPreferenceListOptions from "./ViewPreferenceListOptions";
import { BRAND_COLOURS } from "../../../../constants";

type Props = {
    // TS HACKs no types given
    options?;
    value;
    fieldName;
    onSave;
    title;
    formatValues;
    isEditing;
    className?;
    placeHolder;
};

type State = {
    value;
    options;
};
class PreferenceRange extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            options: this.props.options,
            value: Array.from(new Set(this.props.value))
        };
    }
    onChange = (event) => {
        const fieldName = event.target.name;
        let fieldValue = event.target.value;

        let value = this.state.value;

        if (fieldName === "min") {
            value[0] = fieldValue;
        }
        if (fieldName === "max") {
            value[1] = fieldValue;
        }
        this.setState({ value });

        let target = {
            name: this.props.fieldName,
            value: this.state.value
        };

        this.props.onSave({ target });
    };

    render() {
        const { fieldName, title, formatValues, isEditing } = this.props;
        const { value } = this.state;
        const className = this.props.className ? this.props.className : null;

        const minPlaceholder = this.props.placeHolder[0]
            ? this.props.placeHolder[0]
            : null;

        const minValue = value[0] ? value[0] : "";
        const maxValue = value[1] ? value[1] : "";

        return (
            <dl className={className}>
                <style jsx>
                    {`
                        .btn-edit {
                            margin-left: 5px;
                            padding: 0;
                            font-size: 0.8rem;
                            color: ${BRAND_COLOURS.secondary};
                        }

                        .btn-edit:hover {
                            color: ${BRAND_COLOURS.primary};
                        }
                    `}
                </style>
                <dt>{title}</dt>
                {isEditing === false && (
                    <ViewPreferenceListOptions
                        fieldName={fieldName}
                        value={value}
                        formatValues={formatValues}
                    />
                )}
                {isEditing === true && (
                    <EditPreferenceRanges
                        fieldName={fieldName}
                        minValue={minValue}
                        maxValue={maxValue}
                        minFieldName={"min"}
                        maxFieldName={"max"}
                        minPlaceholder={minPlaceholder}
                        maxPlaceholder={minPlaceholder}
                        onChange={this.onChange}
                    />
                )}
            </dl>
        );
    }
}

export default PreferenceRange;
