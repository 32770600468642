import React from "react";

type Props = {
    children;
    justify?;
    align?;
};

const Row = ({ children, justify, align, ...props }: Props) => (
    <div {...props}>
        <style jsx>{`
             {
                display: flex;
                flex: 0 1 auto;
                flex-direction: row;
                flex-wrap: wrap;
                margin-right: -15px;
                margin-left: -15px;
                ${justify ? `justify-content: ${justify};` : ""}
                ${align ? `align-items: ${align};` : ""}
            }
        `}</style>
        {children}
    </div>
);

export default Row;
