/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import { ModalFooter as ModalFooterBootstrap } from "reactstrap";

const ModalCardFooter = ({ children }) => (
    <ModalFooterBootstrap>{children}</ModalFooterBootstrap>
);

ModalCardFooter.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string
};

export default ModalCardFooter;
