import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import AsyncSelect from "react-select/async";
import { AUTOCOMPLETE_HISTORICAL_INSTITUTION_QUERY } from "../../graphql/query/InstitutionQuery";

type Props = {
    // TS TODO TYPE STRICTER
    onChange: (o) => void;
    name: string;
    value: string;
    multiple: boolean;
};
class SearchInvestorFilter extends React.Component<WithApolloClient<Props>> {
    // TS CODE CHANGE. MOVED TO STATIC PROP TYPES
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.any,
        multiple: PropTypes.bool,
        onChange: PropTypes.func
    };

    getOptionDebounce: any;
    constructor(props) {
        super(props);

        this.getOptionDebounce = debounce(this.getOptions, 500, {
            leading: false,
            trailing: true
        });
    }

    onChange = (value) => {
        value = !value ? "" : value;
        this.props.onChange({
            target: {
                name: this.props.name,
                type: "multiselect",
                value
            }
        });
    };

    getOptions = (input, callback) => {
        const { client } = this.props;
        client
            .query({
                query: AUTOCOMPLETE_HISTORICAL_INSTITUTION_QUERY,
                variables: {
                    keyword: input
                }
            })
            .then((response) => {
                callback(
                    response.data && response.data.institutions
                        ? response.data.institutions
                        : []
                );
            });
    };

    render() {
        const { value } = this.props;
        let multiple = this.props.multiple ? this.props.multiple : false;

        return (
            <div className={"p-3"}>
                <strong>Search for the name of an investor:</strong>
                <AsyncSelect
                    isMulti={multiple}
                    value={value}
                    onChange={this.onChange}
                    defaultOptions
                    loadOptions={this.getOptionDebounce}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
        );
    }
}

const SearchInvestorFilterWithHocs = withApollo<Props>(SearchInvestorFilter);

export default SearchInvestorFilterWithHocs;
