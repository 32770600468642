/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import gql from "graphql-tag";

export const DOCUMENT_FRAGMENT = gql`
    fragment generalDocumentFields on Document {
        id
        status
        name
        file_size
        s3_key
        tags
    }
`;

// TS CODE CHANGE commented out
// not used in convergence-app-server
// export const DOWNLOAD_DOCUMENT = gql`
//     query DownloadDocument($document_id: UUID!) {
//         downloadDocument(document_id: $document_id)
//     }
// `;
