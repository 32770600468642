/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

/**
 * File source code is from:
 * https://github.com/zeit/next.js/blob/canary/examples/with-apollo-auth/lib/redirect.js
 */

export default (context, target) => {
    if (typeof context !== "undefined" && context.res) {
        // server
        // 303: "See other"
        try {
            context.res.writeHead(303, { Location: target });
            context.res.end();
            context.res.finished = true;
        } catch (e) {
            console.error(e);
            // ignore error
        }
    } else {
        // In the browser, we just pretend like this never even happened ;)
        if (typeof window !== "undefined") {
            window.location.href = target;
        }
    }
};
