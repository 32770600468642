import React from "react";
import debounce from "lodash.debounce";

type Props = {
    onChange;
    value;
};
class KeywordSearchFilter extends React.Component<Props> {
    handleOnChange: any;
    constructor(props) {
        super(props);

        this.handleOnChange = debounce(this.onChange, 500, {
            leading: false,
            trailing: true
        });
    }

    onChange = (event) => {
        this.props.onChange({
            target: {
                name: event.target.name,
                type: "input",
                value: event.target.value
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <style>
                    {`
                        .keyword-search-input{
                            width: 100%;
                            height: 36px;
                            line-height: 34px;
                            padding-left: 10px;
                            padding-right: 10px;
                            max-width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    `}
                </style>
                <div className={"p-3"}>
                    <strong>Keyword Search</strong>
                    <div>
                        <input
                            className="keyword-search-input"
                            type="text"
                            value={this.props.value}
                            name="keyword"
                            onChange={this.onChange}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default KeywordSearchFilter;
