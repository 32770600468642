/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { User } from "../../../types/mainTypes";
import UserPreferences from "../settings/UserPreferences";
import UpdateUserPreferencesForm from "./UpdateUserPreferencesForm";

type Props = {
    user: User;
};
type State = {};
class UpdateUserPreferences extends React.Component<
    WithApolloClient<Props>,
    State
> {
    render() {
        const { user } = this.props;

        return (
            // TS HACK: added mutate to satisfy requirement
            <UserPreferences user={user} mutate>
                {(updateUserPreferences, renderPreferences) => (
                    <UpdateUserPreferencesForm
                        updateUserPreferences={updateUserPreferences}
                        renderPreferences={renderPreferences}
                        user={user}
                    />
                )}
            </UserPreferences>
        );
    }
}

const UpdateUserPreferencesWithHOCs = withApollo<Props>(UpdateUserPreferences);

export default UpdateUserPreferencesWithHOCs;
