import React from "react";

import faLinkedin from "@fortawesome/fontawesome-free-brands/faLinkedin";
import faTwitter from "@fortawesome/fontawesome-free-brands/faTwitter";
import faYoutube from "@fortawesome/fontawesome-free-brands/faYoutube";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Container from "../Grid/Container";
import Row from "../Grid/Row";
import Col from "../Grid/Col";
import theme from "../theme";
import Link from "../Link";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Footer = () => (
    <div>
        <Container>
            <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                    <ul className="list-unstyled">
                        <li className="footer-title">
                            <a href="/">
                                <img
                                    src="/static/logo_emphasized_tag.svg"
                                    alt="Convergence"
                                    style={{ width: "80%" }}
                                />
                            </a>
                        </li>
                    </ul>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}>
                    <ul className="list-unstyled">
                        <li className="footer-title">Offerings</li>
                        <li>
                            <Link
                                routeKey={"membership"}
                                label={"Membership"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"membership"}
                                label={"Data and Intelligence"}
                                hash={"data-and-intelligence"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"membership"}
                                label={"Deal Flow and Networking"}
                                hash={"deal-flow-and-networking"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"membership"}
                                label={"Learning and Capacity"}
                                hash={"learning-and-capacity"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"design-funding"}
                                label={"Design Funding"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"ccfacility"}
                                label={"CCFacility"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"special-initiatives"}
                                label={"Special Initiatives"}
                            />
                        </li>
                        <li>
                            <Link routeKey={"knowledge"} label={"Resources"} />
                        </li>
                        <li>
                            <Link
                                routeKey={"blended-finance"}
                                label={"Blended Finance 101"}
                            />
                        </li>
                    </ul>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}>
                    <ul className="list-unstyled">
                        <li className="footer-title">Company</li>
                        <li>
                            <Link
                                routeKey={"about"}
                                title={"About"}
                                label={"About"}
                            />
                        </li>
                        <li>
                            <Link routeKey={"news-and-events"} label={"News"} />
                        </li>
                        <li>
                            <Link
                                routeKey={"event-calendar"}
                                label={"Events"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"press-releases"}
                                label={"Press Releases"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"in-the-media"}
                                label={"In the Media"}
                            />
                        </li>
                        <li>
                            <Link routeKey={"careers"} label={"Careers"} />
                        </li>
                        <li>
                            <Link routeKey={"contact"} label={"Contact"} />
                        </li>
                    </ul>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}>
                    <ul className="list-unstyled">
                        <li className="footer-title">Resources</li>
                        <li>
                            <Link
                                routeKey={"frequently-asked-questions"}
                                label={"FAQ"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"membership"}
                                label={"Pricing"}
                                hash={"pricing"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"about-data-methodology"}
                                label={"Data Methodology"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"securities-registration"}
                                label={"Securities Registration"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"securities-registration"}
                                label={"Jurisdictions"}
                                hash={"jurisdictions"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"terms-of-use"}
                                label={"Terms of Use"}
                            />
                        </li>
                        <li>
                            <Link
                                routeKey={"privacy-policy"}
                                label={"Policies"}
                            />
                        </li>
                    </ul>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}>
                    <ul className="list-unstyled">
                        <li className="footer-title">Connect</li>
                        <li>
                            <a
                                className="footer-icon"
                                href="https://twitter.com/ConvergenceBF"
                                title="@ConvergenceBF on Twitter"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="28px"
                                    viewBox="0 0 512 512"
                                    className="svg-inline--fa fa-w-18 fa-2x icon"
                                >
                                    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                                </svg>
                            </a>
                            <a
                                className="footer-icon"
                                href="https://www.linkedin.com/company/convergenceblendedfinance/"
                                title="Convergence on LinkedIn"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faLinkedin as IconProp}
                                    size="2x"
                                    width="28px"
                                />
                            </a>
                            <a
                                className="footer-icon"
                                href="https://www.youtube.com/channel/UCG1QV2Lpspj59AnYua2PihQ"
                                title="Convergence on Youtube"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faYoutube as IconProp}
                                    size="2x"
                                    width="28px"
                                />
                            </a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
        <style jsx>
            {`
                div {
                    padding-top: 1.5rem;
                    background-color: ${theme.colour.light};
                    -webkit-font-smoothing: antialiased;
                }

                .footer-title {
                    color: ${theme.colour.gray700};
                    font-weight: bold;
                }

                .list-unstyled {
                    padding-left: 0;
                    list-style: none;
                    font-size: 0.87em;
                }

                .list-unstyled li {
                    margin: 10px;
                }

                .list-unstyled li :global(a) {
                    color: ${theme.colour.gray500};
                    fill: ${theme.colour.gray500};
                }

                .list-unstyled li :global(a:hover) {
                    color: ${theme.colour.primary};
                    fill: ${theme.colour.primary};
                }

                .list-unstyled li .icon {
                    color: ${theme.colour.gray500};
                    fill: ${theme.colour.gray500};
                }

                .list-unstyled li .icon:hover {
                    color: ${theme.colour.primary};
                    fill: ${theme.colour.primary};
                }

                .footer-icon {
                    margin-right: 15px;
                }
            `}
        </style>
    </div>
);

export default Footer;
