import ReactDOMServer from "react-dom/server";

class Notification {
    notifications: any[];
    document: Document;
    element: any;
    container: any;
    constructor(container) {
        this.notifications = [];
        this.document = typeof document !== "undefined" ? document : null;
        if (this.document) {
            const style = this.document.createElement("style");
            style.innerHTML = `
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                }
                @keyframes fadeOut {
                    to {
                        opacity: 0;
                    }
                }
                .notification-close {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    width: 15px;
                    height: 15px;
                    opacity: 0.3;
                    background: none;
                    border: 0;
                }
                .notification-close:hover {
                    opacity: 1;
                }
                .notification-close:before, .notification-close:after {;
                    position: absolute;
                    content: ' ';
                    height: 16px;
                    width: 2px;
                    top: 0;
                    background-color: #333;
                }
                .notification-close:before {
                    transform: rotate(45deg);
                }
                .notification-close:after {
                    transform: rotate(-45deg);
                }
                .notification-container {
                    position: fixed;
                    margin: 10px 10px 0 10px;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    width: auto;
                }
                .notification-box {
                    position: relative;
                    padding: 0.8rem;
                    max-width: 400px;
                    border-radius: 4px;
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
                    margin-bottom: 1rem;
                    margin-left: auto;
                    margin-right: auto;

                    transform: translate3d(0px, 0px, 0px);
                    transition: transform 220ms cubic-bezier(0.2, 0, 0, 1) 0s;

                    animation: fadeIn .3s linear forwards;
                }
                .notification-box.success {
                    background-color: ${
                        this.getTypeColour("success").backgroundColor
                    };
                    color: ${this.getTypeColour("success").color};
                }
                .notification-box.error {
                    background-color: ${
                        this.getTypeColour("error").backgroundColor
                    };
                    color: ${this.getTypeColour("error").color};
                }
                .notification-box.warning {
                    background-color: ${
                        this.getTypeColour("warning").backgroundColor
                    };
                    color: ${this.getTypeColour("warning").color};
                }
                .notification-box.info {
                    background-color: ${
                        this.getTypeColour("info").backgroundColor
                    };
                    color: ${this.getTypeColour("info").color};
                }
            `;

            this.element = this.document.createElement("div");
            this.element.appendChild(style);
            this.element.classList.add("notification-container");
            this.container = container ? container : this.document.body;
        }
    }

    static newInstance(container?) {
        return new Notification(container).init();
    }

    success(message, options?) {
        return this.addNotification("success", message, options);
    }

    error(message, options?) {
        return this.addNotification("error", message, options);
    }

    info(message, options) {
        return this.addNotification("info", message, options);
    }

    warning(message, options) {
        return this.addNotification("warning", message, options);
    }

    addNotification(type, message, notificationOptions) {
        const options = {
            duration: 5000,
            ...notificationOptions
        };
        if (typeof message === "object") {
            message = ReactDOMServer.renderToStaticMarkup(message);
        }

        const element = this.createNotificationDiv(type, message);

        this.insertNotificationIntoDOM(element);

        this.notifications.push(element);

        const notification = setTimeout(
            () => this.deleteNotificationElement(element),
            options.duration
        );

        return notification;
    }

    getTypeColour(type) {
        switch (type) {
            case "success": {
                return {
                    color: "#0d6520",
                    backgroundColor: "#deffda"
                };
            }
            case "error": {
                return {
                    color: "rgb(191, 38, 0)",
                    backgroundColor: "rgb(255, 235, 230)"
                };
            }
            default:
                return {
                    color: "white",
                    backgroundColor: "black"
                };
        }
    }

    insertNotificationIntoDOM(element) {
        this.element.appendChild(element);
    }

    deleteNotificationElement(element) {
        let index = this.notifications.findIndex(
            (notification) => notification === element
        );
        if (index > -1) {
            this.notifications.splice(0, index);
            if (element && element.parentNode) {
                element.parentNode.removeChild(element);
            }
        }
    }

    createNotificationDiv(type, message) {
        const closeButton = this.document.createElement("button");
        closeButton.classList.add("notification-close");

        const notificationDiv = this.document.createElement("div");
        notificationDiv.classList.add("notification-box");
        notificationDiv.classList.add(type);
        notificationDiv.innerHTML = message;
        notificationDiv.appendChild(closeButton);

        closeButton.onclick = () =>
            this.deleteNotificationElement(notificationDiv);

        return notificationDiv;
    }

    init() {
        if (this.document && this.container) {
            this.container.appendChild(this.element);
        }

        return this;
    }
}

export default Notification.newInstance();
