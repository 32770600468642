import React from "react";
import faCaretDown from "@fortawesome/fontawesome-free-solid/faCaretDown";
import faUser from "@fortawesome/fontawesome-free-solid/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "../../theme";
import NavLink from "./NavLink";
import {
    PERMISSION_VIEW_ALL,
    PERMISSION_CREATE
} from "../../../../../constants";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ArrowDown, ChevronDown, User } from "lucide-react";

let NavUserButton = ({
    currentRouteKey,
    darkTheme,
    user,
    authorizationService
}) => (
    <ul className={"nav-user-button"} aria-haspopup="true">
        <li>
            <div className={"icon tw-flex"}>
                <User size={20} color={theme.colour.white} />
                <ChevronDown
                    size={20}
                    color={theme.colour.white}
                    className={"caret-next-user"}
                />
            </div>
            <ul className={"hover-menu"} aria-label="submenu">
                <li>
                    <div className={"header"}>
                        Welcome {user && user.first_name}!
                    </div>
                </li>
                <li>
                    <div className={"divider"} />
                </li>
                <li>
                    <NavLink
                        routeKey={"settings-profile"}
                        label="Profile"
                        currentRouteKey={currentRouteKey}
                        darkTheme={darkTheme}
                        hoverColour={theme.colour.white}
                        hoverBGColour={theme.colour.primary}
                        linkColour={theme.colour.gray}
                    />
                </li>
                {user?.institution?.tags?.includes("hide_my_institution") &&
                !user.roles.includes("ROLE_INSTITUTION_ADMIN") ? null : (
                    <li>
                        <NavLink
                            routeKey={"settings-institution"}
                            label="My Institution"
                            currentRouteKey={currentRouteKey}
                            darkTheme={darkTheme}
                            hoverColour={theme.colour.white}
                            hoverBGColour={theme.colour.primary}
                            linkColour={theme.colour.gray}
                        />
                    </li>
                )}
                {user &&
                    authorizationService.hasPermission(
                        "message",
                        PERMISSION_VIEW_ALL
                    ) && (
                        <li>
                            <NavLink
                                routeKey={"institution-messages"}
                                label="Messages"
                                currentRouteKey={currentRouteKey}
                                darkTheme={darkTheme}
                                hoverColour={theme.colour.white}
                                hoverBGColour={theme.colour.primary}
                                linkColour={theme.colour.gray}
                            />
                        </li>
                    )}
                {user &&
                    authorizationService.hasPermission(
                        "deal",
                        PERMISSION_VIEW_ALL
                    ) && (
                        <li>
                            <NavLink
                                routeKey={"deals-matched-deals"}
                                label="Matched Deals"
                                currentRouteKey={currentRouteKey}
                                darkTheme={darkTheme}
                                hoverColour={theme.colour.white}
                                hoverBGColour={theme.colour.primary}
                                linkColour={theme.colour.gray}
                            />
                        </li>
                    )}
                {user &&
                    authorizationService.hasPermission(
                        "deal",
                        PERMISSION_CREATE
                    ) && (
                        <li>
                            <NavLink
                                routeKey={"deals-my-deals"}
                                label="My Deals"
                                currentRouteKey={currentRouteKey}
                                darkTheme={darkTheme}
                                hoverColour={theme.colour.white}
                                hoverBGColour={theme.colour.primary}
                                linkColour={theme.colour.gray}
                            />
                        </li>
                    )}
                <li>
                    <div className={"divider"} />
                </li>
                <li>
                    <NavLink
                        routeKey={"logout"}
                        label="Sign Out"
                        currentRouteKey={currentRouteKey}
                        darkTheme={darkTheme}
                        hoverColour={theme.colour.white}
                        hoverBGColour={theme.colour.primary}
                        linkColour={theme.colour.gray}
                    />
                </li>
            </ul>
        </li>
        <style jsx>{`
            .nav-user-button {
                display: block;
                position: relative;
                list-style: none;
                margin: 0;
                padding: 0;
                border: 0;
                background: transparent;
                color: ${theme.colour.white};
                cursor: pointer;
            }
            .nav-user-button:hover {
                color: ${theme.colour.gray200};
            }
            .icon {
                font-size: 1.3rem;
            }
            .hover-menu {
                display: none;
                position: absolute;
                top: 100%;
                right: 0;
                flex-direction: column;
                z-index: 100;
                min-width: 12rem;
                text-align: left;
                color: ${theme.colour.gray};
                background-color: ${theme.colour.white};
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                border-radius: 2px;
                border: 0;
                margin: 0;
                padding: 0;
            }
            .hover-menu li {
                padding: 0;
                margin: 0;
                display: block;
            }
            .hover-menu > li > :global(a) {
                display: block;
                padding: 0.5rem 1rem;
            }
            .hover-menu .header {
                padding: 0.8rem 1rem 0 1rem;
                font-weight: bold;
            }
            .hover-menu > li .divider {
                width: 100%;
                margin: 0.5rem 0;
                border-top: 1px solid ${theme.colour.gray300};
            }
            .nav-user-button:focus .hover-menu,
            .nav-user-button:active .hover-menu,
            .nav-user-button:hover .hover-menu {
                display: flex;
            }
            .nav-user-button:focus-within .hover-menu {
                display: flex;
            }
        `}</style>
    </ul>
);

export default NavUserButton = React.memo(NavUserButton);
