/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import cloneDeep from "lodash.clonedeep";
import PropTypes from "prop-types";
import React from "react";
import { getStatesByCountryName } from "../../../../utils/CommonUtils";
import MillerColumnPanel from "./MillerColumnPanel";
import theme from "../../../theme/3.0/theme";

type Props = {
    // TS TODO TYPE STRICTER
    height;
    name;
    value;
    optionTitles;
    options;
    multiple;
    onChange;
    helpText?;
    validation?;
};

type State = {
    // TS TODO TYPE STRICTER
    name: string;
    value;
    selectedIndex;
    firstPanelSelectedOptions;
    secondPanelSelectedOptions;
    firstPanelValue;
    secondPanelValue;
    firstPanelActiveOption;
    secondPanelActiveOption;
    totalPanels: number;
};
class MillerColumn extends React.Component<Props, State> {
    maximumVisiblePanels: number;
    // TS CODE CHANGE. MOVED TO STATIC PROP TYPES
    static propTypes = {
        name: PropTypes.string.isRequired,
        optionTitles: PropTypes.array.isRequired,
        options: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
            .isRequired,
        value: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
            .isRequired,
        onChange: PropTypes.func.isRequired,
        multiple: PropTypes.bool,
        height: PropTypes.string
    };
    constructor(props) {
        super(props);
        //
        this.maximumVisiblePanels = 2;
        this.state = {
            name: this.props.name,
            value: cloneDeep(this.props.value),
            selectedIndex: 0,
            firstPanelSelectedOptions: null,
            secondPanelSelectedOptions: null,
            firstPanelValue: null,
            secondPanelValue: null,
            firstPanelActiveOption: null,
            secondPanelActiveOption: null,
            totalPanels: this.props.optionTitles
                ? this.props.optionTitles.length
                : 0
        };
    }

    static createEvent(name, value) {
        return {
            target: {
                name,
                type: "millercolumn",
                value
            }
        };
    }

    static getDerivedStateFromProps(props, state) {
        // Initial load
        let { value, options, optionTitles, name } = props;
        let firstPanelSelectedOptions = [];

        if (Array.isArray(options)) {
            firstPanelSelectedOptions = options;
        } else {
            firstPanelSelectedOptions = Object.keys(options);
        }

        let totalPanels = optionTitles ? optionTitles.length : 0;
        let firstPanelValue =
            totalPanels > 1 && value ? Object.keys(value) : value;

        if (
            firstPanelValue &&
            firstPanelValue.length <= 0 &&
            state.name === name
        ) {
            return {
                selectedIndex: 0,
                firstPanelValue: null,
                secondPanelValue: null,
                firstPanelActiveOption: null,
                secondPanelActiveOption: null
            };
        }

        if (state.name !== name) {
            return {
                value,
                totalPanels,
                firstPanelValue,
                firstPanelSelectedOptions,
                name,
                selectedIndex: 0,
                secondPanelValue: null,
                secondPanelSelectedOptions: null,
                firstPanelActiveOption: null,
                secondPanelActiveOption: null
            };
        }

        return null;
    }

    componentDidMount() {
        // Initial load
        let { value, totalPanels } = this.state;
        let firstPanelSelectedOptions = [];
        if (Array.isArray(this.props.options)) {
            firstPanelSelectedOptions = this.props.options;
        } else {
            firstPanelSelectedOptions = Object.keys(this.props.options);
        }

        let firstPanelValue =
            totalPanels > 1 && value ? Object.keys(value) : value;

        this.setState({
            firstPanelValue,
            firstPanelSelectedOptions
        });
    }

    onClickFirstPanel = (event) => {
        const choice = event.target.getAttribute("data-option");
        const { totalPanels } = this.state;
        const { multiple, options } = this.props;
        let value = this.props.value;
        let {
            firstPanelActiveOption,
            secondPanelValue,
            secondPanelSelectedOptions
        } = this.state;

        if (event.type === "keydown" && event.key !== "Enter") {
            return;
        }

        // Stop propagation
        event.stopPropagation();
        event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();

        if (choice !== null) {
            // Get our first panel values
            let firstPanelValue = totalPanels > 1 ? Object.keys(value) : value;

            // Has our `choice` been selected already?
            if (firstPanelValue.includes(choice)) {
                // Already selected
                // If we have two columns, we need to check to see if we are simply 'selecting' an option to view
                // it's child options (2nd panel) - rather than unselecting the option.
                if (totalPanels > 1) {
                    if (firstPanelActiveOption === choice) {
                        delete value[choice];
                        secondPanelValue = null;
                        secondPanelSelectedOptions = [];
                    } else {
                        secondPanelValue = value[choice];
                        secondPanelSelectedOptions = options[choice];
                    }
                } else {
                    let removeIndex = value.indexOf(choice);
                    if (removeIndex !== -1) {
                        value.splice(removeIndex, 1);
                    }
                }
            } else {
                // Not selected yet - let's select it!
                if (totalPanels > 1) {
                    if (multiple) {
                        value[choice] = [];
                    } else {
                        value = { [choice]: [] };
                    }
                    if (this.props.name === "domiciled_countries") {
                        let states = getStatesByCountryName(choice);
                        if (states) {
                            secondPanelSelectedOptions = Object.values(states);
                        }
                    } else {
                        secondPanelSelectedOptions = options[choice];
                    }
                } else {
                    if (multiple) {
                        value.push(choice);
                    } else {
                        value = [choice];
                    }
                }
            }

            // Set active value
            firstPanelActiveOption = choice;

            firstPanelValue = totalPanels > 1 ? Object.keys(value) : value;

            this.props.onChange(
                MillerColumn.createEvent(this.props.name, value)
            );

            this.setState({
                firstPanelValue,
                firstPanelActiveOption,
                secondPanelValue,
                secondPanelSelectedOptions
            });
        }
    };

    onClickSecondPanel = (event) => {
        const choice = event.target.getAttribute("data-option");
        const { multiple } = this.props;
        let value = this.props.value;
        let { firstPanelActiveOption, secondPanelActiveOption } = this.state;

        if (event.type === "keydown" && event.key !== "Enter") {
            return;
        }

        // Stop propagation
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        if (choice !== null) {
            // Get our first panel values
            let secondPanelValue = value[firstPanelActiveOption]
                ? value[firstPanelActiveOption]
                : [];

            // Has our `choice` been selected already?
            if (secondPanelValue.includes(choice)) {
                let removeIndex = secondPanelValue.indexOf(choice);
                if (removeIndex !== -1) {
                    secondPanelValue.splice(removeIndex, 1);
                }
            } else {
                if (multiple) {
                    secondPanelValue.push(choice);
                } else {
                    secondPanelValue = [choice];
                }
            }

            // Assumed that we have an object because this only occurs in the 2nd panel.
            value = { ...value, [firstPanelActiveOption]: secondPanelValue };

            // Sets the active choice
            secondPanelActiveOption = choice;

            this.props.onChange(
                MillerColumn.createEvent(this.props.name, value)
            );

            this.setState({
                secondPanelValue,
                secondPanelActiveOption
            });
        }
    };

    render() {
        const {
            firstPanelActiveOption,
            firstPanelSelectedOptions,
            firstPanelValue,
            secondPanelActiveOption,
            secondPanelSelectedOptions,
            secondPanelValue,
            selectedIndex,
            totalPanels
        } = this.state;

        const { helpText, validation } = this.props;

        let isValid =
            typeof validation !== "undefined" ? validation.isValid : null;
        let validationText =
            typeof validation !== "undefined" ? validation.message : null;

        let millerClass = "card-group";
        let validationTextClass = "help-text";

        if (isValid === true) {
            millerClass += " success";
            validationTextClass += " success";
        } else if (isValid === false) {
            millerClass += " error";
            validationTextClass += " error";
        }

        if (!firstPanelSelectedOptions) {
            return <div className="card-group">Loading...</div>;
        }

        let firstColumnTitle = this.props.optionTitles[selectedIndex]
            ? this.props.optionTitles[selectedIndex]
            : "";
        let secondColumnTitle =
            totalPanels >= this.maximumVisiblePanels &&
            this.props.optionTitles[selectedIndex + 1]
                ? this.props.optionTitles[selectedIndex + 1]
                : "";

        return (
            <React.Fragment>
                <div className={millerClass}>
                    <MillerColumnPanel
                        title={firstColumnTitle}
                        value={firstPanelValue}
                        activeOption={firstPanelActiveOption}
                        options={firstPanelSelectedOptions}
                        onClick={this.onClickFirstPanel}
                        height={this.props.height}
                    />
                    {totalPanels >= this.maximumVisiblePanels && (
                        <MillerColumnPanel
                            title={secondColumnTitle}
                            value={secondPanelValue}
                            activeOption={secondPanelActiveOption}
                            options={secondPanelSelectedOptions}
                            onClick={this.onClickSecondPanel}
                            height={this.props.height}
                        />
                    )}
                </div>
                {validationText && (
                    <p className={validationTextClass}>{validationText}</p>
                )}
                {helpText && (
                    <small className="form-text text-muted">{helpText}</small>
                )}
                <style jsx>{`
                    .card-group.error {
                        border: 1px solid ${theme.colour.danger};
                    }
                    .card-group.success {
                        border: 1px solid ${theme.colour.success};
                    }
                    .help-text {
                        margin-top: 5px;
                        font-size: 80%;
                    }
                    .help-text.error {
                        color: ${theme.colour.danger};
                    }
                    .help-text.success {
                        color: ${theme.colour.success};
                    }
                `}</style>
            </React.Fragment>
        );
    }
}

export default MillerColumn;
