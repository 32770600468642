import React from "react";
import theme from "../../../theme/3.0/theme";
import FilterOptionRow from "./FilterOptionRow";

type Props = {
    // TS TODO TYPE STRICTER
    availableFilters;
    activeFilter;
    filters;
    setActiveFilter;
};
class FilterOptions extends React.Component<Props> {
    render() {
        const {
            availableFilters,
            activeFilter,
            filters,
            setActiveFilter
        } = this.props;

        let groups = [];
        Object.keys(availableFilters).forEach((filterKey) => {
            if (typeof availableFilters[filterKey].group !== "undefined") {
                if (!groups.includes(availableFilters[filterKey].group)) {
                    groups.push(availableFilters[filterKey].group);
                }
            }
        });

        if (groups.length > 0) {
            return groups.map((group) => {
                const filterKeys = Object.keys(availableFilters).filter(
                    (filterKey) => availableFilters[filterKey].group === group
                );
                return (
                    <React.Fragment key={group}>
                        <li className={"group-header"}>{group}</li>
                        {filterKeys.map((filterKey) => (
                            <FilterOptionRow
                                availableFilter={availableFilters[filterKey]}
                                activeFilter={activeFilter}
                                filterKey={filterKey}
                                key={filterKey}
                                filters={filters}
                                setActiveFilter={setActiveFilter}
                            />
                        ))}
                        <style jsx>{`
                            .group-header {
                                display: block;
                                font-weight: bold;
                                padding: 0.5rem 1rem;
                                background-color: ${theme.colour.light};
                            }
                        `}</style>
                    </React.Fragment>
                );
            });
        } else {
            return Object.keys(availableFilters).map((filterKey) => (
                <FilterOptionRow
                    availableFilter={availableFilters[filterKey]}
                    activeFilter={activeFilter}
                    filterKey={filterKey}
                    key={filterKey}
                    filters={filters}
                    setActiveFilter={setActiveFilter}
                />
            ));
        }
    }
}

export default FilterOptions;
