/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import flatten from "lodash.flatten";
import React from "react";
import { withUpdatePreference } from "../../../__generated__/graphql";

import {
    createImpactThemesAndObjectivesObject,
    createInvestmentInstrumentsAndSubInstrumentsObject,
    createRegionsAndCountriesObject,
    createSectorsAndSubSectorsObject,
    getImpactThemesAndObjectives,
    getInvestmentInstrumentsAndSubInstruments,
    getRegionsAndCountries,
    getSectorsAndSubSectors
} from "../../../utils/CommonUtils";
import {
    DEAL_TYPES,
    SDGS,
    INSTITUTION_STATUS_APPROVED,
    USER_STATUS_ACTIVE
} from "../../../constants";
import PreferenceOptions from "./EditPreferences/PreferenceOptions";
import PreferenceRange from "./EditPreferences/PreferenceRange";
import Button from "../../layout/Form/Button";
import Notification from "../../Notification";
import { User } from "../../../types/mainTypes";

if (typeof window !== "undefined") {
    const FormData = require("formdata-polyfill"); // eslint-disable-line no-unused-vars
}

type Props = {
    user: User;
    updatePreferences?: any;
    children?: any;
    mutate;
};
type State = {
    form;
    startAutoSave: boolean;
    intervalId: string;
    isEditing: boolean;
};
class UserPreferences extends React.Component<Props, State> {
    regionsAndCountries: {};
    sectorsAndSubSectors: {};
    impactThemesAndObjectives: {};
    investmentInstrumentsAndSubInstruments: any;
    constructor(props) {
        super(props);
        this.state = {
            form: this.toForm(this.props.user.preferences),
            startAutoSave: this.props.user ? true : false,
            intervalId: null,
            isEditing: true
        };
        this.regionsAndCountries = getRegionsAndCountries();
        this.sectorsAndSubSectors = getSectorsAndSubSectors();
        this.impactThemesAndObjectives = getImpactThemesAndObjectives();
        this.investmentInstrumentsAndSubInstruments = getInvestmentInstrumentsAndSubInstruments();
    }
    componentDidMount = () => {
        const user = this.props.user;
        if (
            typeof user !== "undefined" &&
            user !== null &&
            user.institution !== null &&
            user.institution.status === INSTITUTION_STATUS_APPROVED &&
            user.status === USER_STATUS_ACTIVE
        ) {
            this.setState({ isEditing: false });
        }
    };

    updateUserPreferences = () => {
        if (!this.props.children && this.state.isEditing) {
            this.setState({ isEditing: false });
        }
        if (Object.keys(this.state.form).length > 0) {
            let input = this.toPreferences(this.state.form);
            return this.props
                .updatePreferences({
                    variables: {
                        input
                    }
                })
                .catch((error) => {
                    console.error(error);
                    Notification.error(
                        "Uh-oh! An error occurred and we were unable to save your changes. Try again."
                    );
                });
        }
    };

    toForm = (preferences) => {
        return {
            regions_and_countries: createRegionsAndCountriesObject(
                preferences.regions_v2,
                preferences.countries
            ),
            sectors_and_sub_sectors: createSectorsAndSubSectorsObject(
                preferences.sectors,
                preferences.sub_sectors
            ),
            impact_themes_and_objectives: createImpactThemesAndObjectivesObject(
                preferences.impact_themes,
                preferences.impact_objectives
            ),
            investment_instruments_and_sub_instruments: createInvestmentInstrumentsAndSubInstrumentsObject(
                preferences.investment_instruments,
                preferences.sub_investment_instruments
            ),
            deal_types: preferences.deal_types
                ? Array.from(new Set(preferences.deal_types))
                : [],
            regions: preferences.regions_v2
                ? Array.from(new Set(preferences.regions_v2))
                : [],
            countries: preferences.countries
                ? Array.from(new Set(preferences.countries))
                : [],
            sectors: preferences.sectors
                ? Array.from(new Set(preferences.sectors))
                : [],
            sub_sectors: preferences.sub_sectors
                ? Array.from(new Set(preferences.sub_sectors))
                : [],
            investment_instruments: preferences.investment_instruments
                ? Array.from(new Set(preferences.investment_instruments))
                : [],
            sdgs: preferences.sdgs ? Array.from(new Set(preferences.sdgs)) : [],
            deal_size:
                preferences.min_deal_size && preferences.max_deal_size
                    ? [preferences.min_deal_size, preferences.max_deal_size]
                    : [0, 0],
            return_rate:
                preferences.min_return_rate && preferences.max_return_rate
                    ? [preferences.min_return_rate, preferences.max_return_rate]
                    : [0, 0]
        };
    };

    toPreferences = (form) => {
        let preferences: Record<string, any> = {}; // TS HACK

        if (typeof form.deal_types !== "undefined") {
            preferences.deal_types = form.deal_types;
        }

        if (typeof form.regions !== "undefined") {
            preferences.regions_v2 = form.regions;
        }

        if (typeof form.countries !== "undefined") {
            preferences.countries = form.countries;
        }

        if (typeof form.sectors !== "undefined") {
            preferences.sectors = form.sectors;
        }

        if (typeof form.sub_sectors !== "undefined") {
            preferences.sub_sectors = form.sub_sectors;
        }

        if (typeof form.impact_themes !== "undefined") {
            preferences.impact_themes = form.impact_themes;
        }

        if (typeof form.impact_objectives !== "undefined") {
            preferences.impact_objectives = form.impact_objectives;
        }

        if (typeof form.investment_instruments !== "undefined") {
            preferences.investment_instruments = form.investment_instruments;
        }

        if (typeof form.sub_investment_instruments !== "undefined") {
            preferences.sub_investment_instruments =
                form.sub_investment_instruments;
        }

        if (typeof form.sdgs !== "undefined") {
            preferences.sdgs = form.sdgs;
        }

        if (typeof form.min_deal_size !== "undefined") {
            preferences.min_deal_size = parseInt(form.min_deal_size, 10);
        }

        if (typeof form.max_deal_size !== "undefined") {
            preferences.max_deal_size = parseInt(form.max_deal_size, 10);
        }

        if (typeof form.min_return_rate !== "undefined") {
            preferences.min_return_rate = parseInt(form.min_return_rate, 10);
        }

        if (typeof form.max_return_rate !== "undefined") {
            preferences.max_return_rate = parseInt(form.max_return_rate, 10);
        }

        return preferences;
    };

    onSubmitSavePreference = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        let form = {
            ...this.state.form,
            [fieldName]: fieldValue
        };

        if (fieldName === "deal_types") {
            form.deal_types.sort();
        }

        if (fieldName === "sdgs") {
            form.sdgs.sort();
        }

        if (fieldName === "regions_and_countries") {
            form.regions = Object.keys(fieldValue);
            form.regions.sort();
            form.countries = flatten(Object.values(fieldValue));
            form.countries.sort();
        }

        if (fieldName === "sectors_and_sub_sectors") {
            form.sectors = Object.keys(fieldValue);
            form.sectors.sort();
            form.sub_sectors = flatten(Object.values(fieldValue));
            form.sub_sectors.sort();
        }

        if (fieldName === "impact_themes_and_objectives") {
            form.impact_themes = Object.keys(fieldValue);
            form.impact_themes.sort();
            form.impact_objectives = flatten(Object.values(fieldValue));
            form.impact_objectives.sort();
        }

        if (fieldName === "investment_instruments_and_sub_instruments") {
            form.investment_instruments = Object.keys(fieldValue);
            form.investment_instruments.sort();
            form.sub_investment_instruments = flatten(
                Object.values(fieldValue)
            );
            form.sub_investment_instruments.sort();
        }

        if (fieldName === "deal_size") {
            form.min_deal_size = fieldValue[0] ? fieldValue[0] : null;
            form.max_deal_size = fieldValue[1] ? fieldValue[1] : null;
        }

        if (fieldName === "return_rate") {
            form.min_return_rate = fieldValue[0] ? fieldValue[0] : null;
            form.max_return_rate = fieldValue[1] ? fieldValue[1] : null;
        }
        this.setState({ form });
    };

    formatCurrency = (value) => "$" + value.toLocaleString();
    formatPercentage = (value) => value.toString() + "%";

    render() {
        if (this.props.children) {
            return this.props.children(
                this.updateUserPreferences,
                this.renderPreferences
            );
        } else {
            return this.renderPreferences();
        }
    }
    clickToEditInterests = () => {
        this.setState({ isEditing: true });
    };
    renderPreferences = () => {
        const { form, isEditing } = this.state;
        const user = this.props.user;

        return (
            <React.Fragment>
                <div className="mb-3">
                    {!this.props.children && !isEditing && (
                        <Button
                            label={"Edit Interests"}
                            fieldName={"edit-interests"}
                            onClick={this.clickToEditInterests}
                            className="float-right"
                        />
                    )}

                    {!this.props.children && isEditing && (
                        <Button
                            label={"Save Interests"}
                            fieldName={"save-interests"}
                            onClick={this.updateUserPreferences}
                            className="float-right"
                        />
                    )}
                    <h2 className="mb-3">Your Personal Interests</h2>

                    <p>
                        These interests are used to customize your personal user
                        experience on our platform (e.g. deal matching,
                        notifications, etc.).
                    </p>
                </div>
                <PreferenceOptions
                    fieldName={"deal_types"}
                    title={"Deal Type*"}
                    value={form.deal_types}
                    options={DEAL_TYPES}
                    optionTitles={["Deal Types"]}
                    onSave={this.onSubmitSavePreference}
                    required={true}
                    isEditing={isEditing}
                />
                <PreferenceOptions
                    fieldName={"regions_and_countries"}
                    title={"Regions & Countries*"}
                    value={form.regions_and_countries}
                    options={this.regionsAndCountries}
                    optionTitles={["Region", "Country"]}
                    onSave={this.onSubmitSavePreference}
                    required={true}
                    isEditing={isEditing}
                />
                <PreferenceOptions
                    fieldName={"sectors_and_sub_sectors"}
                    title={"Sectors & Sub-Sectors*"}
                    value={form.sectors_and_sub_sectors}
                    options={this.sectorsAndSubSectors}
                    optionTitles={["Sectors", "Sub-Sectors"]}
                    onSave={this.onSubmitSavePreference}
                    required={true}
                    isEditing={isEditing}
                />
                <PreferenceOptions
                    fieldName={"impact_themes_and_objectives"}
                    title={"Impact Themes & Objectives"}
                    value={form.impact_themes_and_objectives}
                    options={this.impactThemesAndObjectives}
                    optionTitles={["Impact Themes", "Impact Objectives"]}
                    onSave={this.onSubmitSavePreference}
                    isEditing={isEditing}
                />
                <PreferenceOptions
                    fieldName={"sdgs"}
                    title={"SDGs"}
                    value={form.sdgs}
                    options={SDGS}
                    optionTitles={["SDGs"]}
                    onSave={this.onSubmitSavePreference}
                    isEditing={isEditing}
                />
                <PreferenceOptions
                    fieldName={"investment_instruments_and_sub_instruments"}
                    title={"Investment Instruments*"}
                    helpText={
                        "Investment instruments that you are interested in."
                    }
                    value={form.investment_instruments_and_sub_instruments}
                    options={this.investmentInstrumentsAndSubInstruments}
                    optionTitles={["Investment Instruments", "Sub-Instruments"]}
                    onSave={this.onSubmitSavePreference}
                    required={true}
                    isEditing={isEditing}
                />
                <PreferenceRange
                    fieldName={"deal_size"}
                    title={"Deal Size"}
                    value={form.deal_size}
                    placeHolder={["e.g. 10000000", "e.g. 500000000"]}
                    onSave={this.onSubmitSavePreference}
                    formatValues={this.formatCurrency}
                    isEditing={isEditing}
                />
                {user.roles && user.roles.includes("ROLE_DEAL_SPONSOR") && (
                    <PreferenceRange
                        fieldName={"return_rate"}
                        title={"Return Rate"}
                        value={form.return_rate}
                        placeHolder={["e.g. 1.0", "e.g. 5.0"]}
                        onSave={this.onSubmitSavePreference}
                        formatValues={this.formatPercentage}
                        className={"mb-0"}
                        isEditing={isEditing}
                    />
                )}
                {!this.props.children && isEditing && (
                    <Button
                        label={"Save Interests"}
                        fieldName={"save-interests"}
                        onClick={this.updateUserPreferences}
                        className="float-right mt-3"
                    />
                )}
            </React.Fragment>
        );
    };
}

export default withUpdatePreference<Props>({
    name: "updatePreferences"
})(UserPreferences);

// original:
// export default graphql(UPDATE_USER_PREFERENCES, {
//     name: "updatePreferences",
//     props: ({ updatePreferences }) => ({
//         updatePreferences
//     })
// })(UserPreferences);

// TS FOUND ERROR: multiple `export defaults`
// TS CODE CHANGE Only the first export default is exported. (tested in TS build only)
// export default UserPreferences;
