/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import { FormFeedback, FormGroup, FormText, Label } from "reactstrap";

const BaseField = ({
    render,
    fieldName,
    label,
    validation,
    helpText,
    check,
    inline,
    disableFormGroup
}) => {
    let isValid = typeof validation !== "undefined" ? validation.isValid : null;
    let validationText =
        typeof validation !== "undefined" ? validation.message : null;
    disableFormGroup = typeof disableFormGroup !== "undefined";

    if (disableFormGroup) {
        return (
            <React.Fragment>
                {render(isValid)}
                {validationText && (
                    <FormFeedback>{validationText}</FormFeedback>
                )}
                {helpText && <FormText>{helpText}</FormText>}
            </React.Fragment>
        );
    }

    return (
        <FormGroup check={check} inline={inline}>
            {label && <Label for={fieldName}>{label}</Label>}
            {render(isValid)}
            {validationText && <FormFeedback>{validationText}</FormFeedback>}
            {helpText && <FormText>{helpText}</FormText>}
        </FormGroup>
    );
};

BaseField.propTypes = {
    render: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.node
    ]),
    validation: PropTypes.object,
    helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.element,
    check: PropTypes.bool,
    inline: PropTypes.bool,
    disableFormGroup: PropTypes.bool
};

export const fieldPropTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.node
    ]),
    validation: PropTypes.object,
    placeHolder: PropTypes.string,
    helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.string,
    check: PropTypes.bool,
    inline: PropTypes.bool,
    disableFormGroup: PropTypes.bool
};

export default BaseField;
