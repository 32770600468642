/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import { Button } from "reactstrap";

const SubmitButton = ({ fieldName, label, ...props }) => (
    <Button type="submit" name={fieldName} {...props}>
        {label}
    </Button>
);

SubmitButton.propTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default SubmitButton;
