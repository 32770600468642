/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import { Container as BaseContainer } from "reactstrap";

const Container = ({ children, ...props }) => (
    <BaseContainer {...props}>{children}</BaseContainer>
);

export default Container;
