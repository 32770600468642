import React from "react";

type Props = {
    hash?;
    email?;
    name?;
    userId?;
};
class CrispChat extends React.Component<Props> {
    componentDidMount() {
        const { hash, email } = this.props;

        if (typeof window !== "undefined") {
            // Include the Crisp code here, without the <script></script> tags
            // @ts-ignore POSSIBLE ERROR FOUND. non exist key
            window.$crisp = [];
            // @ts-ignore POSSIBLE ERROR FOUND. non exist key
            window.CRISP_WEBSITE_ID = "f82f39a0-b12c-4b4a-a021-3eac7b6fd9c5";

            (function() {
                var d = document;
                var s = d.createElement("script");
                // @ts-ignore POSSIBLE ERROR FOUND: number not assignable to bool
                s.async = 1;
                s.src = "https://client.crisp.chat/l.js";
                d.getElementsByTagName("head")[0].appendChild(s);
            })();

            if (
                typeof hash !== "undefined" &&
                hash != null &&
                typeof email !== "undefined" &&
                email !== null
            ) {
                // @ts-ignore POSSIBLE ERROR FOUND: Can't find $cripsp
                $crisp.push(["set", "user:email", [email, hash]]);
            }
        }
    }

    render() {
        return <React.Fragment />;
    }
}

export default CrispChat;
