import PropTypes from "prop-types";
import React from "react";
import { withScriptjs } from "react-google-maps";
import { compose, withProps } from "recompose";
import ValidationService from "../../service/ValidationService";
import { createEvent } from "../../utils/CommonUtils";
import TextField from "../layout/Form/TextField";
import config from "../../config/config";

type Props = {
    value;
    validation;
    label;
    fieldName;
    placeHolder;
    onChange;
};
class LocationAutoCompleteField extends React.Component<Props> {
    static propTypes = {
        fieldName: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,
        label: PropTypes.string,
        placeHolder: PropTypes.string,
        validation: PropTypes.object
    };

    setAutoComplete = (input) => {
        const { fieldName } = this.props;

        if (input) {
            let options = {
                types: ["(cities)"]
            };

            // @ts-ignore POSSIBLE ERROR FOUND google does not exist on window
            let autocomplete = new window.google.maps.places.Autocomplete(
                input,
                options
            );

            autocomplete.setFields(["address_components"]);
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();

                if (
                    place.address_components &&
                    place.address_components.length > 0
                ) {
                    let location = "";
                    place.address_components.map((address) => {
                        if (
                            address.types.includes("political") &&
                            address.types.includes("locality")
                        ) {
                            location += address.long_name;
                        }

                        if (
                            address.types.includes("political") &&
                            address.types.includes(
                                "administrative_area_level_1"
                            )
                        ) {
                            location += ", " + address.long_name;
                        }

                        if (
                            address.types.includes("political") &&
                            address.types.includes("country")
                        ) {
                            location += ", " + address.long_name;
                        }

                        return address;
                    });

                    if (location !== "") {
                        this.props.onChange(
                            createEvent(
                                fieldName,
                                "locationAutocomplete",
                                location
                            )
                        );
                    }
                }
            });
        }
    };

    render() {
        const { value, validation, label, fieldName, placeHolder } = this.props;

        return (
            <TextField
                fieldName={fieldName ? fieldName : "location"}
                label={label ? label : "City:"}
                placeHolder={placeHolder ? placeHolder : "e.g. Toronto, ON, CA"}
                innerRef={this.setAutoComplete}
                defaultValue={value ? value : ""}
                autoComplete={"new-password"}
                validation={ValidationService.getFieldValidation(
                    validation,
                    fieldName ? fieldName : "location"
                )}
                helpText={
                    "Selected city: " +
                    (value !== null && value !== "" ? value : "N/A")
                }
            />
        );
    }
}

export default compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${config.google_api_key}&v=3.exp&libraries=geometry,drawing,places&language=us&region=US`,
        loadingElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs
)(LocationAutoCompleteField);
