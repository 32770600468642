/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import { Modal as ModalBoostrap } from "reactstrap";

type Props = {
    children;
    size?;
    hasBackground;
    isActive;
    onDismiss?;
    toggle?;
    centered?;
};

const Modal = ({
    children,
    size,
    hasBackground,
    isActive,
    onDismiss,
    toggle,
    ...props
}: Props) => (
    <ModalBoostrap
        onClick={onDismiss}
        toggle={toggle}
        isOpen={isActive}
        backdrop={hasBackground}
        size={size}
        {...props}
    >
        {children}
    </ModalBoostrap>
);

Modal.propTypes = {
    children: PropTypes.any.isRequired,
    hasBackground: PropTypes.bool,
    onDismiss: PropTypes.func,
    isActive: PropTypes.bool,
    size: PropTypes.string
};

export default Modal;
