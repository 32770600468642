import React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { INSTITUTION_FRAGMENT } from "../../graphql/query/InstitutionQuery";

class ReactivateInstitutionButton extends React.Component {
    render() {
        return (
            <Mutation
                mutation={gql`
                    mutation ReactivateInstitution {
                        reactivateInstitution {
                            ...generalInstitutionFields
                        }
                    }
                    ${INSTITUTION_FRAGMENT}
                `}
            >
                {(reactivateInstitution) => (
                    <div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                reactivateInstitution().then(() =>
                                    typeof window !== "undefined"
                                        ? window.location.reload()
                                        : null
                                );
                            }}
                        >
                            <button type="submit" className={"btn btn-success"}>
                                Restore Access
                            </button>
                        </form>
                    </div>
                )}
            </Mutation>
        );
    }
}

export default ReactivateInstitutionButton;
