/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import gql from "graphql-tag";
import { USER_PREFERENCE_FRAGMENT } from "../query/UserQuery";

export const CANCEL_CHANGE_EMAIL_VERIFICATION_MUTATION = gql`
    mutation cancelChangeEmail {
        cancelChangeEmail
    }
`;

export const ACTIVATE_TRIAL_USER_MUTATION = gql`
    mutation activateTrial($id: UUID!) {
        activateTrial(id: $id) {
            token
            refreshToken
            user {
                id
                status
                institution {
                    id
                    name
                }
                institution_id
                full_name
                first_name
                last_name
                title
                location
                purpose_for_using_convergence
                purpose_for_using_convergence_v2
                email_address
                confirm_email_address
                roles
                preferences {
                    ...generalUserPreferenceFields
                }
                created_at
            }
        }
    }
    ${USER_PREFERENCE_FRAGMENT}
`;

export const ACTIVATE_USER_MUTATION = gql`
    mutation activation($activation_key: String!) {
        activate(activation_key: $activation_key) {
            token
            refreshToken
            user {
                id
                status
                institution {
                    id
                    name
                }
                institution_id
                full_name
                first_name
                last_name
                title
                location
                purpose_for_using_convergence
                purpose_for_using_convergence_v2
                email_address
                confirm_email_address
                roles
                preferences {
                    ...generalUserPreferenceFields
                }
                created_at
            }
        }
    }
    ${USER_PREFERENCE_FRAGMENT}
`;

export const COMPLETE_PREFERENCES_MUTATION = gql`
    mutation CompletePreferences {
        completePreferences {
            id
            status
            institution {
                id
                name
            }
            institution_id
            full_name
            first_name
            last_name
            title
            email_address
            confirm_email_address
            roles
            preferences {
                ...generalUserPreferenceFields
            }
            created_at
        }
    }
    ${USER_PREFERENCE_FRAGMENT}
`;

export const UPDATE_USER_MUTATION = gql`
    mutation updateUser($input: UserSelfInput!) {
        updateSelf(input: $input) {
            id
            status
            institution {
                id
                name
            }
            institution_id
            full_name
            first_name
            last_name
            title
            location
            purpose_for_using_convergence
            purpose_for_using_convergence_v2
            email_address
            confirm_email_address
            roles
            preferences {
                ...generalUserPreferenceFields
            }
            created_at
        }
    }
    ${USER_PREFERENCE_FRAGMENT}
`;

export const UPDATE_USER_PREFERENCES = gql(`
    mutation updatePreference($input: UserSelfPreferenceInput!) {
        updateSelfPreferences(input: $input) {
            id
            preferences {
                ...generalUserPreferenceFields
            }
        }
    }
`);

export const UPDATE_USER_CONFIRM_EMAIL = gql`
    fragment updateUserConfirmEmail on User {
        confirm_email_address
        __typename
    }
`;

export const UPDATE_USER_EMAIL_AND_CONFIRM_EMAIL = gql`
    fragment updateUserEmailAndConfirmEmail on User {
        email_address
        confirm_email_address
        __typename
    }
`;

export const UPDATE_USER_INSTITUTION_AND_STATUS_FRAGMENT = gql`
    fragment updateUserInstitutionAndStatusFragment on User {
        status
        institution {
            id
            name
        }
        institution_id
        __typename
    }
`;

export const UPDATE_USER_PENDING_APPROVAL = gql`
    mutation UpdateUserPendingApproval($user_id: UUID!, $status: Int!) {
        updateUserPendingApproval(user_id: $user_id, status: $status) {
            id
            approval_status
            user {
                id
                title
                email_address
                first_name
                last_name
            }
        }
    }
`;
