/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import { PERMISSIONS } from "../constants";

/**
 * Authorization Service
 */
export class AuthorizationService {
    permissions: { institution: string[]; deals: string[] };
    user: any;
    roles: any;
    /**
     * Constructor
     */
    constructor(user?, roles?) {
        this.permissions = PERMISSIONS;
        this.user = user ? user : { roles: [] };
        this.roles = roles ? roles : [];
    }

    /**
     * Updates the user's and roles.
     * @param user
     * @param roles
     */
    updateUserAndRoles = (user, roles) => {
        this.user = user ? user : { roles: [] };
        this.roles = roles ? roles : [];
    };

    /**
     * Converts roles & permissions from the database to a structured object
     * {
     *    ROLE: {
     *        inherit_roles: Array,
     *        permissions: {
     *            resource: {
     *                id: String
     *                permission: int
     *            }
     *        }
     *    }
     * }
     * @param rows
     * @returns {{}}
     */
    static convertRolesToObject(rows) {
        let roles = {};
        for (let row of rows) {
            if (typeof roles[row.name] === "undefined") {
                roles[row.name] = {
                    inherit_roles:
                        row.inherit_roles !== null ? row.inherit_roles : [],
                    permissions: {}
                };
            }
            for (let permission of row.permissions) {
                let clonedPermission = { ...permission };
                delete clonedPermission.resource;
                roles[row.name].permissions[
                    permission.resource
                ] = clonedPermission;
            }
        }

        return roles;
    }

    /**
     * Converts a role (if it doesn't exist) to an empty structured object
     * @param role
     * @returns {{inherit_roles: Array, permissions: {}}}
     */
    static createRoleObjectStructure(role) {
        return typeof role !== "undefined"
            ? role
            : {
                  inherit_roles: [],
                  permissions: {}
              };
    }

    /**
     * Flattens a role hierarchy and gets the final permissions for each role.
     * So that the following occurs, given:
     * {
     *    ROLE_SUPER_ADMIN: [ ROLE_ADMIN ]
     *    ROLE_ADMIN: [ ROLE_USER, ROLE_TEST ]
     *    ROLE_USER: []
     * }
     * We will produce:
     * {
     *    ROLE_SUPER_ADMIN: [ ROLE_ADMIN, ROLE_USER, ROLE_TEST ]
     *    ROLE_ADMIN: [ ROLE_USER, ROLE_TEST ]
     *    ROLE_USER: []
     * }
     * @param roles
     * @returns {{}}
     */
    static flattenRoles(roles) {
        let map = {};
        for (let main in roles) {
            if (Object.prototype.hasOwnProperty.call(roles, main)) {
                map[main] = AuthorizationService.createRoleObjectStructure(
                    roles[main]
                );
                if (
                    map[main].inherit_roles !== null &&
                    map[main].inherit_roles.length > 0
                ) {
                    for (let inheritedRole of map[main].inherit_roles) {
                        map[
                            inheritedRole
                        ] = AuthorizationService.createRoleObjectStructure(
                            roles[inheritedRole]
                        );
                        // If there are any inherited roles that are already mapped, let's add their inherited roles as well
                        if (
                            map[main].inherit_roles.includes(inheritedRole) &&
                            typeof map[inheritedRole] !== "undefined"
                        ) {
                            // The funky [ ...new Set(Array) ] returns a list of UNIQUE values only
                            let roles = new Set(
                                map[inheritedRole].inherit_roles.concat(
                                    map[main].inherit_roles
                                )
                            );

                            map[main].inherit_roles = Array.from(
                                new Set(roles)
                            );
                            map[
                                main
                            ].permissions = AuthorizationService.mergePermissions(
                                map[main].permissions,
                                map[inheritedRole].permissions
                            );
                        }
                    }
                }

                // We check our existed mapped roles's "inherited roles"
                // If they include the current loop's role - we'll now update their
                // inherited role to include all of our current loop's inherited roles.
                for (let checkRole in map) {
                    if (Object.prototype.hasOwnProperty.call(map, checkRole)) {
                        if (map[checkRole].inherit_roles.includes(main)) {
                            map[checkRole].inherit_roles = Array.from(
                                new Set(
                                    map[checkRole].inherit_roles.concat(
                                        map[main].inherit_roles
                                    )
                                )
                            );
                            map[
                                checkRole
                            ].permissions = AuthorizationService.mergePermissions(
                                map[checkRole].permissions,
                                map[main].permissions
                            );
                        }
                    }
                }
            }
        }

        return map;
    }

    /**
     * "Deep" merges, but only for the role's permissions
     * @param currentPermissions
     * @param newPermissions
     * @returns {{}}
     */
    static mergePermissions(currentPermissions, newPermissions) {
        const keys = Object.keys(currentPermissions).concat(
            Object.keys(newPermissions)
        );

        let merged = {};

        for (let key of keys) {
            merged[key] = typeof merged[key] === "undefined" ? {} : merged[key];
            if (Object.prototype.hasOwnProperty.call(currentPermissions, key)) {
                merged[key].id = currentPermissions[key].id;
                merged[key].permission =
                    typeof newPermissions[key] !== "undefined"
                        ? Array.from(
                              new Set(
                                  currentPermissions[key].permission.concat(
                                      newPermissions[key].permission
                                  )
                              )
                          )
                        : currentPermissions[key].permission;
            } else if (
                Object.prototype.hasOwnProperty.call(newPermissions, key)
            ) {
                merged[key].id = newPermissions[key].id;
                merged[key].permission =
                    typeof currentPermissions[key] !== "undefined"
                        ? Array.from(
                              new Set(
                                  newPermissions[key].permission.concat(
                                      currentPermissions[key].permission
                                  )
                              )
                          )
                        : newPermissions[key].permission;
            }
        }

        return merged;
    }

    /**
     * Returns all of our defined roles.
     * @returns {*}
     */
    getRoles() {
        return AuthorizationService.flattenRoles(
            AuthorizationService.convertRolesToObject(this.roles)
        );
    }

    /**
     * Get's a user's combined permissions
     * @returns {Promise<{}>}
     */
    getUserPermissions() {
        let permissions = {};
        const userRoles = this.user.roles;
        const roles = this.getRoles();

        if (roles) {
            for (let role of userRoles) {
                if (roles[role]) {
                    permissions = AuthorizationService.mergePermissions(
                        roles[role].permissions,
                        permissions
                    );
                }
            }
        }

        return permissions;
    }

    /**
     * Returns true/false if a user has a role (even if inherited)
     * @param checkRole
     */
    async hasRole(checkRole) {
        let hasRole = false;

        const userRoles = this.user.roles;
        const roles = await this.getRoles();

        for (let role of userRoles) {
            if (
                role === checkRole ||
                roles[role].inherit_roles.includes(checkRole)
            ) {
                hasRole = true;
                break;
            }
        }

        return hasRole;
    }

    /**
     * Check to see if a given bitmask contains the appropriate permissions.
     * @param resource
     * @param checkPermissions
     * @returns {boolean}
     */
    hasPermission(resource, checkPermissions) {
        const entityPermissions = this.getUserPermissions();

        if (typeof entityPermissions[resource] === "undefined") {
            return false;
        }

        if (!Array.isArray(checkPermissions)) {
            checkPermissions = [checkPermissions];
        }

        const resourcePermission = entityPermissions[resource];

        if (typeof resourcePermission !== "undefined") {
            return checkPermissions.some((currentValue) => {
                return resourcePermission.permission.includes(currentValue);
            });
        } else {
            return false;
        }
    }
}

let load;
/**
 * Load an instance for quick access.
 * @returns {AuthorizationService}
 */
export default function() {
    if (!load) {
        load = new AuthorizationService();
    }
    return load;
}
