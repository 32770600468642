/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import EditPreferenceListOptions from "./EditPreferenceListOptions";
import ViewPreferenceListOptions from "./ViewPreferenceListOptions";
import { BRAND_COLOURS } from "../../../../constants";

type Props = {
    value: Record<string, any>; // TS HACK
    options;
    fieldName;
    title;
    optionTitles;
    helpText;
    isEditing;
    onSave;
};

type State = {
    value: Record<string, any>; // TS HACK
    options;
};
class PreferenceOptions extends React.Component<Props, State> {
    static propTypes: {
        title: PropTypes.Validator<string>;
        fieldName: PropTypes.Validator<string>;
        required: PropTypes.Requireable<boolean>;
        value: PropTypes.Requireable<object>;
        options: PropTypes.Requireable<object>;
        optionTitles: PropTypes.Requireable<any[]>;
        onSave: PropTypes.Requireable<(...args: any[]) => any>;
        disableEditing: PropTypes.Requireable<boolean>;
        helpText: PropTypes.Requireable<string>;
    };
    constructor(props) {
        super(props);

        const value = Array.isArray(this.props.value)
            ? Array.from(new Set(this.props.value))
            : { ...this.props.value };

        this.state = {
            options: this.props.options,
            value: value
        };
    }

    onChange = (event) => {
        const fieldChecked = event.target.checked;
        let fieldValue = event.target.value;

        if (event.target.type === "checkbox") {
            let oldValue = this.state.value;
            if (fieldChecked) {
                if (oldValue) {
                    oldValue.push(fieldValue);
                } else {
                    oldValue = [fieldValue];
                }
            } else {
                if (oldValue) {
                    let index = oldValue.indexOf(fieldValue);
                    oldValue.splice(index, 1);
                }
            }
            fieldValue = oldValue;
        }

        this.setState({ value: fieldValue }, () => {
            let target = {
                name: this.props.fieldName,
                value: this.state.value
            };

            this.props.onSave({ target });
        });
    };

    render() {
        const {
            fieldName,
            title,
            optionTitles,
            value,
            helpText,
            isEditing
        } = this.props;
        const { options } = this.state;

        return (
            <dl>
                <style jsx>
                    {`
                        .btn-edit {
                            margin-left: 5px;
                            padding: 0;
                            font-size: 0.8rem;
                            color: ${BRAND_COLOURS.secondary};
                            background: none;
                        }

                        .btn-edit:hover {
                            color: ${BRAND_COLOURS.primary};
                        }
                    `}
                </style>
                <dt>{title} </dt>
                {helpText && (
                    <dd className={"mb-0 text-muted"}>
                        <small>{helpText}</small>
                    </dd>
                )}
                {isEditing === false && (
                    <ViewPreferenceListOptions
                        fieldName={fieldName}
                        value={value}
                    />
                )}
                {isEditing === true && (
                    <EditPreferenceListOptions
                        fieldName={fieldName}
                        value={value}
                        options={options}
                        optionTitles={optionTitles}
                        onChange={this.onChange}
                    />
                )}
            </dl>
        );
    }
}

PreferenceOptions.propTypes = {
    title: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    optionTitles: PropTypes.array,
    onSave: PropTypes.func,
    disableEditing: PropTypes.bool,
    helpText: PropTypes.string
};

export default PreferenceOptions;
