/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import ApolloClient from "apollo-client";
import cookie from "cookie";
import gql from "graphql-tag";
import jwt_decode from "jwt-decode";
import { Context } from "react-apollo";
import { INITIALIZE_USER_QUERY } from "../graphql/query/AuthQuery";
import redirect from "./Redirect";

/**
 * Requests a new auth token from the API using the refresh token
 * @param context
 * @param apollo
 * @param dispatch
 */
export const refreshToken = (context, apollo) => {
    const cookieData = parseCookies(context);
    if (cookieData.refreshToken !== null) {
        return apollo
            .mutate({
                mutation: gql`
                    mutation($refreshToken: String!) {
                        refreshToken(refreshToken: $refreshToken) {
                            token
                            wpToken
                        }
                    }
                `,
                variables: {
                    refreshToken: cookieData.refreshToken
                }
            })
            .then(
                ({
                    data: {
                        refreshToken: { token }
                    }
                }) => {
                    createCookie(
                        {
                            token,
                            refreshToken: cookieData.refreshToken
                        },
                        context
                    );
                    return token;
                }
            );
    } else {
        return Promise.reject("No cookie data to parse.");
    }
};

/**
 * Authenticate a logged in user. If not redirect.
 * @param apollo
 * @param context
 * @param redirectTo
 * @param disableInstitutionCheck
 * @returns {*}
 */
export function authenticateUser(
    apollo: ApolloClient<any>, // TS HACK any
    context,
    redirectTo?
): Promise<{ user; roles }> {
    const currentPath =
        context && context.req
            ? context.req.originalUrl
            : window.location.pathname;

    const token = parseCookies(context).token;
    if (!token) {
        if (typeof redirectTo !== "undefined") {
            redirect(context, redirectTo + "?redirect=" + currentPath);
        }
        return Promise.resolve({ user: null, roles: null });
    }

    return apollo
        .query({
            query: INITIALIZE_USER_QUERY,
            fetchPolicy: "cache-first"
        })
        .then(({ data: { self, roles } }) => {
            return { user: self, roles };
        })
        .catch((error) => {
            let hasAccessDeniedError = false;

            if (error.networkError && error.networkError.statusCode === 401) {
                // 401 errors, we'll force log out the user and redirect them to the login page
                apollo.resetStore().then(() => removeCookie(context));
            }

            if (error.graphQLErrors) {
                for (let graphQLError of error.graphQLErrors) {
                    if (
                        graphQLError.message ===
                        "Access Denied. You do not have access to this resource."
                    ) {
                        hasAccessDeniedError = true;
                        break;
                    }
                }
            }

            if (typeof redirectTo !== "undefined") {
                if (hasAccessDeniedError) {
                    apollo.resetStore().then(() => {
                        removeCookie(context);
                        redirect(
                            context,
                            redirectTo + "?redirect=" + currentPath
                        );
                    });
                } else {
                    redirect(context, redirectTo + "?redirect=" + currentPath);
                }
            }

            return { user: null, roles: null };
        });
}

export function matchesPathAndStatus(currentPath, redirectPath, statusCheck) {
    if (statusCheck === true) {
        const regex = new RegExp("/^" + redirectPath + "/");
        if (!currentPath.match(regex)) {
            return redirectPath;
        }
    }

    return false;
}

/**
 * Parse a cookie from a server or client.
 * @param context
 * @param options
 * @param key
 * @param defaults
 * @returns {*}
 */
export function parseCookies(
    context: Context = {},
    options = {},
    key = "convergence_auth",
    defaults = { token: null, refreshToken: null }
) {
    try {
        const parsedCookie = cookie.parse(
            Object.keys(context).length > 0 &&
                context.req &&
                context.req.headers.cookie
                ? context.req.headers.cookie
                : typeof document !== "undefined"
                ? document.cookie
                : "",
            options
        );

        if (parsedCookie[key]) {
            return JSON.parse(parsedCookie[key]);
        } else {
            return defaults;
        }
    } catch (e) {
        console.error(e);
        return defaults;
    }
}

/**
 * Parse a cookie from a server or client.
 * @param context
 * @param options
 * @param key
 * @param defaults
 * @returns {*}
 */
export function parseDataCookies(
    context: Context = {},
    options = {},
    key = "convergence_auth",
    defaults = false
) {
    try {
        const parsedCookie = cookie.parse(
            Object.keys(context).length > 0 &&
                context.req &&
                context.req.headers.cookie
                ? context.req.headers.cookie
                : typeof document !== "undefined"
                ? document.cookie
                : "",
            options
        );

        if (parsedCookie[key]) {
            return JSON.parse(parsedCookie[key]);
        } else {
            return defaults;
        }
    } catch (e) {
        console.error(e);
        return defaults;
    }
}

export function getTokenData(
    context = {},
    options = {},
    defaults = { token: null, refreshToken: null }
) {
    const cookieData = parseCookies(
        context,
        options,
        "convergence_auth",
        defaults
    );

    if (cookieData.token !== null) {
        return jwt_decode(cookieData.token, { complete: true });
    } else {
        return null;
    }
}

/**
 * Create a cookie on the client
 * @param values = {token, refreshToken}
 */
export function createCookie(
    values,
    context = null,
    cookieName = "convergence_auth",
    expireAt = 30 * 24 * 60 * 60
) {
    // Store the token in cookie
    const cookieOptions = {
        expires: new Date(Date.now() + expireAt),
        maxAge: expireAt, // 30 days,
        path: "/"
    };
    const serializedCookie = cookie.serialize(
        cookieName,
        JSON.stringify(values),
        cookieOptions
    );
    if (
        typeof context !== "undefined" &&
        context !== null &&
        Object.keys(context).length > 0 &&
        typeof context.res !== "undefined"
    ) {
        context.res.setHeader("Set-Cookie", serializedCookie);
    } else if (typeof document !== "undefined") {
        document.cookie = serializedCookie;
    }
}

/**
 * Create a cookie on the client
 * @param values = {token, refreshToken}
 */
export function createSpecialCookie(
    values,
    context = null,
    cookieName = "convergence_",
    expireAt = 30 * 24 * 60 * 60,
    cookieOptions = {
        expires: new Date(Date.now() + expireAt),
        maxAge: expireAt, // 30 days,
        path: "/",
        domain: ".convergence.finance"
    }
) {
    // Store the token in cookie
    const serializedCookie = cookie.serialize(
        cookieName,
        JSON.stringify(values),
        cookieOptions
    );
    if (
        typeof context !== "undefined" &&
        context !== null &&
        Object.keys(context).length > 0 &&
        typeof context.res !== "undefined"
    ) {
        context.res.setHeader("Set-Cookie", serializedCookie);
    } else if (typeof document !== "undefined") {
        document.cookie = serializedCookie;
    }
}
/**
 * Remove a cookie via the server or client
 * @param context
 */
export function removeCookie(
    context: Context = {},
    cookieName = "convergence_auth"
) {
    if (Object.keys(context).length > 0 && typeof context.res !== "undefined") {
        try {
            context.res.clearCookie(cookieName);
        } catch (e) {
            // ignore
            console.error(e);
        }
    } else {
        const expiredCookie = cookie.serialize(cookieName, "", {
            maxAge: -1,
            path: "/"
        });

        if (typeof document !== "undefined") {
            document.cookie = expiredCookie;
        }
    }
}
