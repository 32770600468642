import PropTypes from "prop-types";
import React from "react";
import {
    INSTITUTION_STATUS_COMPLIANCE_REVIEW,
    INSTITUTION_STATUS_PENDING_APPROVAL,
    INSTITUTION_STATUS_SUSPENDED,
    USER_STATUS_NOT_JOINED_INSTITUTION,
    INSTITUTION_STATUS_REACTIVATED,
    INSTITUTION_STATUS_DRAFT
} from "../../constants";
import ReactivateInstitutionButton from "./ReactivateInstitutionButton";
import Link from "../theme/3.0/Link";

const AccountStatusNotification = ({ user }) => {
    const isInstitutionStatusPending =
        typeof user !== "undefined" &&
        typeof user.institution !== "undefined" &&
        typeof user.institution.status !== "undefined" &&
        (user.institution.status === INSTITUTION_STATUS_PENDING_APPROVAL ||
            user.institution.status === INSTITUTION_STATUS_COMPLIANCE_REVIEW);

    const isUserStatusPending =
        typeof user !== "undefined" &&
        typeof user.status !== "undefined" &&
        user.status === USER_STATUS_NOT_JOINED_INSTITUTION;

    const institutionDraft =
        typeof user.institution !== "undefined" &&
        typeof user.institution.status !== "undefined" &&
        user.institution.status === INSTITUTION_STATUS_DRAFT;

    const institutionSuspended =
        typeof user.institution !== "undefined" &&
        typeof user.institution.status !== "undefined" &&
        user.institution.status === INSTITUTION_STATUS_SUSPENDED;

    const institutionReactivated =
        typeof user.institution !== "undefined" &&
        typeof user.institution.status !== "undefined" &&
        user.institution.status === INSTITUTION_STATUS_REACTIVATED;

    return (
        <div className="alert alert-warning" style={{ margin: "0" }}>
            <div className="container">
                {institutionDraft === true && (
                    <React.Fragment>
                        <strong>Complete your institution registration</strong>
                        <br />
                        Complete your institution registration in order to gain
                        access to member-only features.
                        <br />
                        <br />
                        <Link
                            routeKey={"institution-register"}
                            label={"Complete Institution Registration"}
                            colour={"success"}
                            isButton
                        />
                    </React.Fragment>
                )}
                {isInstitutionStatusPending === true && (
                    <React.Fragment>
                        <strong>Your institution is pending activation</strong>
                        <br />
                        Your access to the Convergence Platform is limited while
                        your institution is pending approval.
                    </React.Fragment>
                )}
                {isUserStatusPending === true &&
                    isInstitutionStatusPending !== true && (
                        <React.Fragment>
                            <strong>Pending Institution Approval</strong>
                            <br />
                            Your access to the Convergence Platform is limited
                            while your application to join your institution is
                            awaiting approval.
                        </React.Fragment>
                    )}
                {institutionSuspended === true && (
                    <React.Fragment>
                        <strong>Platform Access Notification</strong>
                        <br />
                        Your access is restricted. If you would like to access
                        the platform, please reach out to the Convergence team
                        at{" "}
                        <a href="mailto:support@convergence.finance">
                            support@convergence.finance
                        </a>{" "}
                        to discuss pricing options with our team.
                        <br />
                        <br />
                        <ReactivateInstitutionButton />
                    </React.Fragment>
                )}
                {institutionReactivated === true && (
                    <React.Fragment>
                        <strong>Platform Access Notification</strong>
                        <br />
                        Your institution has been marked to be re-activated.
                        Please reach out to{" "}
                        <a href="mailto:support@convergence.finance">
                            support@convergence.finance
                        </a>{" "}
                        to discuss pricing options with our team.
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

AccountStatusNotification.propTypes = {
    user: PropTypes.object
};

export default AccountStatusNotification;
