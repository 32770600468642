/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import gql from "graphql-tag";
import { INSTITUTION_FRAGMENT } from "./InstitutionQuery";

export const USER_PREFERENCE_FRAGMENT = gql`
    fragment generalUserPreferenceFields on UserPreference {
        deal_types
        regions
        regions_v2
        countries
        sectors
        sub_sectors
        impact_themes
        impact_objectives
        sdgs
        beneficiaries
        investment_instruments
        sub_investment_instruments
        min_deal_size
        max_deal_size
        min_return_rate
        max_return_rate
    }
`;

export const USER_FRAGMENT = gql`
    fragment generalUserFields on User {
        id
        intercom_hash
        status
        sub_status
        institution {
            ...generalInstitutionFields
        }
        institution_id
        full_name
        first_name
        last_name
        title
        location
        purpose_for_using_convergence
        purpose_for_using_convergence_v2
        email_address
        confirm_email_address
        roles
        preferences {
            ...generalUserPreferenceFields
        }
        created_at
        is_trial
        expire_at
    }
    ${INSTITUTION_FRAGMENT}
    ${USER_PREFERENCE_FRAGMENT}
`;

// TS CODE CHANGE: added LoggedInUserQuery
export const LOGGED_IN_USER_QUERY = gql`
    query LoggedInUserQuery {
        user: self {
            ...generalUserFields
        }
        roles {
            name
            inherit_roles
            permissions {
                id
                resource
                permission
            }
        }
    }
    ${USER_FRAGMENT}
`;

export const GET_USERS_WITH_APPROVAL_STATUS_QUERY = gql`
    query InstitutionUsersWithApprovalStatus {
        users: getInstitutionUsersWithApprovalStatus {
            id
            approval_status
            user {
                id
                title
                email_address
                first_name
                last_name
                roles
            }
        }
    }
`;

export const GET_MY_INSTITUTION_USERS_QUERY = gql`
    query MyInstitutionUsers($institution_id: UUID!) {
        users: getInstitutionUsers(institution_id: $institution_id) {
            id
            title
            email_address
            first_name
            last_name
            preferences {
                deal_types
                regions
                regions_v2
                countries
                sectors
                sub_sectors
                impact_themes
                impact_objectives
                sdgs
            }
        }
    }
`;

export const GET_INSTITUTION_USERS_QUERY = gql`
    query InstitutionUsers($institution_id: UUID!) {
        users: getInstitutionUsers(institution_id: $institution_id) {
            id
            title
            preferences {
                deal_types
                regions
                regions_v2
                countries
                sectors
                sub_sectors
                impact_themes
                impact_objectives
                sdgs
            }
        }
    }
`;
