/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import faClock from "@fortawesome/fontawesome-free-solid/faClock";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import Card from "../../layout/Card/Card";
import CardContent from "../../layout/Card/CardContent";

const ComplianceReview = ({ institution }) => (
    <React.Fragment>
        <Card>
            <CardContent>
                <h2 className="card-title">
                    <span className="text-info">
                        <FontAwesomeIcon icon={faClock as IconProp} />
                    </span>{" "}
                    Awaiting Compliance Review
                </h2>
                <p>
                    Your institution, <strong>{institution.name}</strong>, is
                    currently awaiting compliance review.
                    <br />
                    For more information, please contact us at{" "}
                    <a href="mailto:support@convergence.finance">
                        support@convergence.finance
                    </a>
                    .
                </p>
            </CardContent>
        </Card>
    </React.Fragment>
);

ComplianceReview.propTypes = {
    institution: PropTypes.object.isRequired
};

export default ComplianceReview;
