import React from "react";

const SkipToMainContent = () => (
    <a href={"#content"} className={"skip-to-main"}>
        Skip to main content
        <style jsx>{`
            .skip-to-main {
                position: absolute;
                display: block;
                z-index: 50000;
                background-color: white;
                padding: 0.5rem;
                margin: 10px;
                top: -4rem;
            }

            .skip-to-main:focus {
                top: 0;
            }
        `}</style>
    </a>
);

export default SkipToMainContent;
