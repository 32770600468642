import React from "react";
import theme from "../theme";

type Props = {
    children;
    xl?;
    lg?;
    md?;
    sm?;
    xs?;
    sizes?;
    justify?;
    alignSelf?;
    css?;
    className?;
};

const Col = ({
    children,
    xl,
    lg,
    md,
    sm,
    xs,
    sizes,
    justify,
    alignSelf,
    css,
    ...props
}: Props) => {
    const columnSizes =
        typeof sizes !== "undefined"
            ? sizes
            : {
                  xl:
                      typeof xl !== "undefined" && typeof xl === "number"
                          ? `${(xl / theme.grid.columns) * 100}%`
                          : typeof xl !== "undefined"
                          ? xl
                          : typeof lg !== "undefined" && typeof lg === "number"
                          ? `${(lg / theme.grid.columns) * 100}%`
                          : typeof lg !== "undefined"
                          ? lg
                          : "100%",
                  lg:
                      typeof lg !== "undefined" && typeof lg === "number"
                          ? `${(lg / theme.grid.columns) * 100}%`
                          : typeof lg !== "undefined"
                          ? lg
                          : "100%",
                  md:
                      typeof md !== "undefined" && typeof md === "number"
                          ? `${(md / theme.grid.columns) * 100}%`
                          : typeof md !== "undefined"
                          ? md
                          : "100%",
                  sm:
                      typeof sm !== "undefined" && typeof sm === "number"
                          ? `${(sm / theme.grid.columns) * 100}%`
                          : typeof sm !== "undefined"
                          ? sm
                          : "100%",
                  xs:
                      typeof xs !== "undefined" && typeof xs === "number"
                          ? `${(xs / theme.grid.columns) * 100}%`
                          : typeof xs !== "undefined"
                          ? xs
                          : "100%"
              };

    return (
        <div {...props}>
            <style jsx>{`
                 {
                    position: relative;
                    width: 100%;
                    flex: 0 0 auto;
                    flex-basis: ${columnSizes.xs};
                    box-sizing: border-box;
                    padding-right: 15px;
                    padding-left: 15px;
                    max-width: ${columnSizes.xs};

                    ${alignSelf ? `align-self: ${alignSelf};` : ""}
                    ${justify ? `justify-content: ${justify};` : ""}

                    ${css ? css : ""}
                }

                @media (min-width: ${theme.breakpoint.sm}) {
                     {
                        flex-basis: ${columnSizes.sm};
                        max-width: ${columnSizes.sm};
                    }
                }

                @media (min-width: ${theme.breakpoint.md}) {
                     {
                        flex-basis: ${columnSizes.md};
                        max-width: ${columnSizes.md};
                    }
                }

                @media (min-width: ${theme.breakpoint.lg}) {
                     {
                        flex-basis: ${columnSizes.lg};
                        max-width: ${columnSizes.lg};
                    }
                }

                @media (min-width: ${theme.breakpoint.xl}) {
                     {
                        flex-basis: ${columnSizes.xl};
                        max-width: ${columnSizes.xl};
                    }
                }
            `}</style>
            {children}
        </div>
    );
};

export default Col;
