import fuzzysearch from "fuzzysearch";
import theme from "../theme/3.0/theme";

// turn into own util function / module
const highlight = (query, text) => {
    if (query.length === 0) {
        return text;
    }

    const offset = text.toLowerCase().indexOf(query[0].toLowerCase());
    if (offset === -1) {
        return null;
    }
    let last = 0;
    for (let i = 1; i < query.length; i++) {
        if (text[offset + i] !== query[i]) {
            break;
        }

        last = i;
    }

    const before = text.slice(0, offset);
    const match = text.slice(offset, offset + last + 1);
    const after = highlight(
        query.slice(last + 1),
        text.slice(offset + last + 1)
    );

    const returnValue =
        after === null ? null : (
            <>
                {before}
                <span className="font-weight-bold">{match}</span>
                {after}
            </>
        );
    // console.log({ returnValue });
    return returnValue;
};

const Divider = ({ index }) => (
    <>
        <li key={`${index}-divider`}>
            <div className="divider" />
            <style jsx>
                {`
                    .divider {
                        width: 100%;
                        margin: 0.5rem 0;
                        border-top: 1px solid ${theme.colour.gray300};
                    }
                `}
            </style>
        </li>
    </>
);

const SearchHistoryDropdown = ({ keyword, history, onSelect, onClear }) => {
    if (typeof keyword === "undefined" || !(typeof keyword === "string"))
        keyword = "";
    const trimmedKeyword = keyword.trim();
    let formattedHistory = [];
    if (!history || !Array.isArray(history)) {
        formattedHistory = [];
    } else if (trimmedKeyword.length > 0) {
        const filteredHistory = history.filter(
            (query) =>
                !keyword
                    .toLocaleLowerCase()
                    .includes(query.toLocaleLowerCase()) &&
                fuzzysearch(trimmedKeyword.toLowerCase(), query.toLowerCase())
        );
        formattedHistory = filteredHistory.slice(0, 7).map((query) => ({
            originalQ: query,
            formattedQ: highlight(keyword, query)
        }));
    } else {
        formattedHistory = history.slice(0, 7).map((query) => ({
            originalQ: query,
            formattedQ: query
        }));
    }
    const historyItems = formattedHistory.map((formattedHistoryItem, index) => (
        <>
            <Divider index={index} />
            <li
                key={index.toString()}
                onMouseDown={(event) => {
                    // OnMouseDown Fires before onBlur. onClick doesn't work here
                    //   (fires after onBlur. too late, since parent will have
                    //    removed this component before the event gets handled)
                    event.preventDefault();
                    onSelect(formattedHistoryItem.originalQ);
                }}
            >
                {formattedHistoryItem.formattedQ}
                <style jsx>
                    {`
                        li {
                            padding: 10px 5px 5px 10px;
                            margin: 0;
                            display: block;
                            cursor: pointer;
                        }
                    `}
                </style>
            </li>
        </>
    ));

    if (historyItems.length === 0) return null;
    return (
        <ul className="search-history-dropdown">
            <li
                key={"header"}
                className="header"
                onMouseDown={(event) => {
                    event.preventDefault();
                    onClear();
                }}
            >
                Clear Search History
            </li>

            {historyItems}
            <style jsx>
                {`
                    .search-history-dropdown {
                        list-style: none;
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        top: 100%;
                        // right: 0;
                        z-index: 100;
                        // min-width: 50rem;
                        width: 100%;
                        text-align: left;
                        color: ${theme.colour.gray};
                        background-color: ${theme.colour.white};
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                        border-radius: 2px;
                        margin: 0;
                        padding: 0;
                    }
                    .search-history-dropdown li.header {
                        text-align: center;
                        padding: 0.8rem 1rem 0 1rem;
                        font-weight: bold;
                        cursor: pointer
                    }
                    .divider {
                        width: 100%;
                        margin: 0.5rem 0;
                        border-top: 1px solid ${theme.colour.gray300};
                    }
                `}
            </style>
        </ul>
    );
};

export default SearchHistoryDropdown;
