/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import { BRAND_COLOURS } from "../../../../constants";
import theme from "../../../theme/3.0/theme";

const ViewPreferenceListOptions = ({ fieldName, value, formatValues }) => (
    <React.Fragment>
        <style jsx>
            {`
                .preference-options {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    box-sizing: content-box;
                    margin-left: -5px;
                    padding-left: 5px;
                    padding-right: 5px;
                    border: 1px dashed transparent;
                }
                .preference-option {
                    flex: 1;
                    vertical-align: top;
                    font-size: 0.9rem;
                    padding: 5px 10px;
                    margin-right: 10px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }

                @media (min-width: ${theme.breakpoint.md}) {
                    .preference-option {
                        max-width: 30%;
                        min-width: 30%;
                    }
                }

                @media (min-width: ${theme.breakpoint.lg}) {
                    .preference-option {
                        max-width: 30%;
                        min-width: 30%;
                    }
                }

                @media (min-width: ${theme.breakpoint.xl}) {
                    .preference-option {
                        max-width: 30%;
                        min-width: 30%;
                    }
                }

                .preference-edit {
                    font-size: 0.8rem;
                    margin: 0;
                    padding: 0;
                    color: ${BRAND_COLOURS.secondary};
                }
                .preference-options:hover .preference-edit {
                    color: ${BRAND_COLOURS.primary};
                }
                .preference-selection {
                    border: 1px solid rgba(0, 0, 0, 0.125);
                    background-color: ${BRAND_COLOURS.light};
                }

                .preference-sub-items {
                    font-size: 0.8rem;
                }
            `}
        </style>
        <div className="preference-options" data-preference-type={fieldName}>
            {value &&
                Array.isArray(value) &&
                value.length > 0 &&
                value.map((item) => (
                    <div
                        className="preference-option preference-selection"
                        key={item}
                    >
                        {typeof formatValues !== "undefined"
                            ? formatValues(item)
                            : item}
                    </div>
                ))}
            {value &&
                !Array.isArray(value) &&
                Object.keys(value).length > 0 &&
                Object.keys(value).map((item) => {
                    const subItems = value[item];
                    return (
                        <div
                            className="preference-option preference-selection"
                            key={item}
                        >
                            <span className="preference-item-name">
                                {typeof formatValues !== "undefined"
                                    ? formatValues(item)
                                    : item}
                            </span>
                            {subItems.length > 0 ? (
                                <React.Fragment> &#8212; </React.Fragment>
                            ) : (
                                ""
                            )}
                            <span className="preference-sub-items">
                                {subItems.join(", ")}
                            </span>
                        </div>
                    );
                })}
            {((value &&
                ((Array.isArray(value) && value.length === 0) ||
                    (!Array.isArray(value) &&
                        Object.keys(value).length === 0))) ||
                !value) && (
                <em className="preference-option text-muted">
                    &#8212; Empty &#8212;
                </em>
            )}
        </div>
    </React.Fragment>
);

ViewPreferenceListOptions.propTypes = {
    fieldName: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    formatValues: PropTypes.func
};

export default ViewPreferenceListOptions;
