/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import { Input } from "reactstrap";
import BaseField, { fieldPropTypes } from "./BaseField";

type Props = {
    fieldName;
    label?;
    placeHolder;
    validation?;
    helpText?;
    disableFormGroup?;
    value?;
    onChange?;
};

const NumberField = ({
    fieldName,
    label,
    placeHolder,
    validation,
    helpText,
    disableFormGroup,
    ...props
}: Props) => (
    <BaseField
        {...props}
        fieldName={fieldName}
        label={label}
        validation={validation}
        helpText={helpText}
        disableFormGroup={disableFormGroup}
        render={(isValid) => (
            <Input
                type="number"
                valid={isValid}
                invalid={isValid === false ? true : null}
                name={fieldName}
                id={fieldName}
                placeholder={placeHolder}
                {...props}
            />
        )}
    />
);

NumberField.propTypes = fieldPropTypes;

export default NumberField;
