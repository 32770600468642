/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import faChevronDown from "@fortawesome/fontawesome-free-solid/faChevronDown";
import faChevronUp from "@fortawesome/fontawesome-free-solid/faChevronUp";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
type Props = {
    direction;
    onClick;
    label?;
    className?;
};
const SortButton = ({ direction, onClick, label, ...props }: Props) => (
    <button onClick={onClick} {...props}>
        {direction === "DESC" ? (
            <React.Fragment>
                <FontAwesomeIcon icon={faChevronDown as IconProp} />{" "}
                {typeof label === "undefined" ? "Newest" : label[0]}
            </React.Fragment>
        ) : (
            <React.Fragment>
                <FontAwesomeIcon icon={faChevronUp as IconProp} />
                {typeof label === "undefined" ? "Newest" : label[1]}
            </React.Fragment>
        )}
    </button>
);

SortButton.propTypes = {
    direction: PropTypes.oneOf(["DESC", "ASC"]).isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.array
};

export default SortButton;
